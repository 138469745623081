<script setup>
    import { ref, onMounted } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import {useUserStore} from "../../../stores/user";
    import LectureRegCompleteModal from "../../../components/modal/lecture/LectureRegCompleteModal.vue";
    import lectureUserService from "../../../services/lecture/lectureUserService";
    import LectureResumeModal from "../../../components/modal/lecture/LectureResumeModal.vue";
    import ApplyOfflineLectureModal from "../../../components/modal/lecture/ApplyOfflineLectureModal.vue";
    import _ from 'lodash';
    import moment from 'moment';
    import {useNavigationStore} from "../../../../../stores/navigation";
    import {useMyPageLectDetailStore} from "../../../stores/mypage/mypageLectDetailStore";
    import {useSiteMenuStore} from "../../../../../stores/sitemenu";

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const navigationStore = useNavigationStore()
    const lectureStore = useMyPageLectDetailStore()
    const { user, isAuthenticated } = useUserStore()

    onMounted(()=>{
        const gId = route.params.id || route.params.uuid;
        const isUuid = route.meta.apiType === 'uuid';
        if(!gId) {
            router.push({name: 'LectureOfflineList'})
        } else {
            fetchLNBData();
            init(gId, isUuid);
        }
    })

    const lectDetail = ref({})
    const lectIndexList = ref([])

    const tabOptions = ref([
        {label:'과정소개', value:0},
        {label:'학습목차', value:1}
    ]);
    const tabIndex = ref(0)

    const modal = ref(null)
    const lectureRegCompleteModal = ref(null)
    const lectureResumeModal = ref(null)
    const applyOfflineLectureModal = ref(null)
    const modalContent = ref([]);
    const siteMenuStore = useSiteMenuStore();

    const fetchLNBData = async () => {
      try {
        //
        await fetch('../../../../../stores/sitemenu');
        siteMenuStore.getSiteMenu();
      } catch (error) {
        console.log(error);
      }
    }

    const init = ( gId, isUuid ) => {
        loadingStore.loading = true;
        const p1 = retrieveLectureDetail(gId, isUuid)
        const p2 = retrieveLectureAllIndex(gId, isUuid)
        Promise.all([p1, p2])
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res[0])
                // console.log(res[1])
                if(res[0].data.code.toLowerCase() === 'success') {
                    const result = res[0].data.result;
                    lectDetail.value = result;
                } else {
                    console.log(res[0].response.data.msg);
                    modalContent.value = [res[0].response.data.msg];
                    const mod = await modal.value.show();
                    if( mod ) {
                      router.go(-1);
                    }
                }
                if(res[1].data.code.toLowerCase() === 'success') {
                    const result = res[1].data.result;
                    lectIndexList.value = result;

                    if(lectIndexList.value.length > 0) {
                        const teacherNames = _.uniq(_.map(lectIndexList.value, (lectIndex) => {
                            if(lectIndex.teacher && lectIndex.teacher.userName) {
                                return lectIndex.teacher.userName;
                            } else {
                                return false;
                            }
                        }));

                        if(teacherNames.length > 0) {
                            lectDetail.value.teacherNameText = teacherNames[0];
                            if(teacherNames.length > 1) {
                                lectDetail.value.teacherNameText += ` 외 ${teacherNames.length-1}명`;
                            }
                        }

                    }
                }
            })
            .catch( async (err) => {
                loadingStore.loading = false;
                if (err.response && err.response.data && err.response.data.msg) {
                  console.log(err.response.data.msg);
                  modalContent.value = [err.response.data.msg];
                  const mod = await modal.value.show();
                  if( mod ) {
                    router.go(-1);
                  }
                } else {
                  console.log(err);
                }
            })
    }

    const retrieveLectureDetail = async (gId, isUuid) => {
        return isUuid
            ? await lectureUserService.retrieveLectureDetailByUuid(gId)
            : await lectureUserService.retrieveLectureDetail(gId);
    }

    const retrieveLectureDetailWithoutLoading = () => {
        lectureUserService.retrieveLectureDetail(lectDetail.value.lectId)
            .then((res) => {
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    lectDetail.value = result;
                }
            })
            .catch( (res) =>  {
            });
    }

    const retrieveLectureAllIndex = async (gId, isUuid) => {
        return isUuid
            ? await lectureUserService.retrieveLectureAllIndexByUuid(gId)
            : await lectureUserService.retrieveLectureAllIndex(gId);
    }

    const onClickReg = async () => {
        if(!isAuthenticated) { // 로그인 x
            // console.log(route);
            navigationStore.lastAccessPage = route.fullPath;
            router.push({name: 'Signin'});
        } else {
            const regStartYmd = moment(lectDetail.value.regStartYmd.replaceAll('.', '')).format('YYYYMMDD');
            const regEndYmd = moment(lectDetail.value.regEndYmd.replaceAll('.', '')).format('YYYYMMDD');
            const nowYmd = moment().format('YYYYMMDD');
            // 신청기간 체크
            if(lectDetail.regTermGb !== '01' && (Number(nowYmd) > Number(regEndYmd) || (Number(nowYmd) < Number(regStartYmd)))) {
                modalContent.value = ["신청기간이 아닙니다."];
                modal.value.show();
            } else {
                const { cancelYn, checkYn, regDt, userId } = await checkUserLectureSel();
                if(cancelYn === 'Y') {  // 사용자가 취소를 한 경우,
                    modalContent.value = ["해당 강의의 재 수강 신청이 불가능합니다."];
                    modal.value.show();
                } else if(checkYn === 'Y') { // 이미 신청한 강의일 경우, 내 강의실로 이동
                    // modalContent.value = ["이미 신청한 강의입니다."];
                    // modal.value.show();
                    const newLecture = _.cloneDeep(lectDetail.value);
                    newLecture.regDt = regDt;
                    newLecture.userId = userId;
                    lectureStore.setLecture(newLecture);
                    router.push({name: 'MyPageLectureDetail', params:{id: lectDetail.value.lectId}})
                } else if(checkYn === 'N'){
                    const { limitMemYn, limitMemNum, userLectureSelCount, lectTrgt, ticketOfferGb, resumeRequired } = lectDetail.value;
                    // userLectureCount 신청인원 / userLectureSelCount 선발인원 / limitMemNum 제한인원
                    if(limitMemYn === 'Y' && limitMemNum <= userLectureSelCount) { // 정원 초과시
                        modalContent.value = ["수강 정원이 초과되어 수강신청이 마감되었습니다."];
                        modal.value.show();
                    } else {
                        if((lectTrgt !== 'ALL' && lectTrgt !== user.userGb) || (lectTrgt === 'ALL' && !(user.userGb === 'STUDENT' || user.userGb === 'COMPANY'))) {
                            modalContent.value = ["해당 강의의 수강 대상자가 아닙니다."];
                            modal.value.show();
                        } else {
                            if(ticketOfferGb === '01') {  // 선발기준 01 선착순
                                requestUserLectureSel();
                            } else { // 선발기준 ticketOfferGb 02 서류전형 03 서류+면접전형
                                applyOfflineLectureModal.value.init( ticketOfferGb, resumeRequired, lectDetail )
                                await applyOfflineLectureModal.value.show();
                            }
                        }
                    }
                } else {
                    modalContent.value = ["오류가 발생했습니다."];
                    modal.value.show();
                }
            }
        }
    }

    const checkUserLectureSel = () => { // 수강신청 체크
        loadingStore.isLoading = true;
        return new Promise((resolve, reject) => {
            lectureUserService.checkUserLectureSelByLectId(lectDetail.value.lectId)
                .then( (res) => {
                    const result = res.data.result;
                    // console.log(result)
                    resolve(result);
                })
                .catch( (res) =>  {
                    loadingStore.loading = false;
                    // console.log(res);
                    modalContent.value = ["오류가 발생했습니다."];
                    modal.value.show();
                    reject();
                });
        });
    }

    const requestUserLectureSel = () => {
        loadingStore.loading = true;
        lectureUserService.requestUserLectureSelByLectId(lectDetail.value.lectId)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    const regDt = result.regDt;
                    // console.log(result)
                    lectureRegCompleteModal.value.init(regDt.value);
                    lectureRegCompleteModal.value.show();
                    retrieveLectureDetailWithoutLoading();
                }
            })
            .catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                modalContent.value = [res.response.data.result.message];
                modal.value.show();
            });
    }

    const onChangeTab = (option) => {
        tabIndex.value = option.value;
    }

</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="title page-title">
                <h3><span class="badge">오프라인 강의</span>{{ lectDetail.lectName }}</h3>
            </div>
            <div class="lecture-wrap">
                <div class="lecture-summary">
                    <div class="left-area">
                        <KSAImage :src="lectDetail.lectThombDir"/>
                    </div>
                    <div class="right-area">
                        <KSAPrimaryButton text="수강신청 하기" @click="onClickReg" />
                        <p>
                            <span>신청 기간</span>
                            <span v-if="lectDetail.regTermGb === '01'">상시</span>
                            <span v-else>{{ $yyyymmdd(lectDetail.regStartYmd) }} ~ {{ $yyyymmdd(lectDetail.regEndYmd) }}</span>
                        </p>
                        <p v-if="lectDetail.ticketOfferGb === '03'">
                            <span>면접 기간</span>
                            <span v-if="lectDetail.intvTermGb === '01'">상시</span>
                            <span v-else>{{ $yyyymmdd(lectDetail.intvStartYmd) }} ~ {{ $yyyymmdd(lectDetail.intvEndYmd) }}</span>
                        </p>
                        <p>
                            <span>교육 기간</span>
                            <span v-if="lectDetail.lectTermGb === '01'">신청일로부터 {{ lectDetail.lectTerm }}일</span>
                            <span v-else>{{ $yyyymmdd(lectDetail.lectStartYmd) }} ~ {{ $yyyymmdd(lectDetail.lectEndYmd) }}</span>
                        </p>
                        <p>
                            <span>교육 시간</span>
                            <span v-if="lectDetail.lectHour || lectDetail.lectMin">
                                {{ lectDetail.lectHour ? `${lectDetail.lectHour}시간` : '' }}{{ lectDetail.lectMin ? ` ${lectDetail.lectMin}분` : '' }}
                            </span>
                            <span v-else>-</span>
                        </p>
                        <p>
                            <span>교육 장소</span>
                            <span>{{ lectDetail.lectPlace }}</span>
                        </p>
                        <p>
                            <span>교육 대상자</span>
                            <span>{{ $userGbKR(lectDetail.lectTrgt) }}</span>
                        </p>
                        <p>
                            <span>강사명</span>
                            <span v-if="lectIndexList.length === 0 || !lectDetail.teacherNameText ">-</span>
                            <span v-else>{{ lectDetail.teacherNameText }}</span>
                        </p>
                        <p>
                            <span>수료 기준</span>
                            <span v-if="lectDetail.finCriteriaGb === '01'">없음</span>
                            <span v-else-if="lectDetail.finCriteriaGb === '02'">교육기간 내 진도율 {{ lectDetail.finishCriteria }}% 이상
                                <small>* 차시 전체를 학습 완료 시 진도율에 반영됩니다.<br/>(재생 중 강의 종료 시 진도율에 반영되지 않으며, 수강 내역이 저장되지 않습니다.)</small>
                            </span>
                            <span v-else-if="lectDetail.finCriteriaGb === '03'">Pass/Fail</span>
                            <span v-else>-</span>
                        </p>
                        <p v-if="lectDetail.limitMemOpenYn === 'Y'">
                            <span>교육 인원</span>
                            <span v-if="lectDetail.limitMemYn === 'Y'">{{ lectDetail.limitMemNum }}명</span>
                            <span v-else>무제한</span>
                        </p>
                        <p>
                            <span>선발 기준</span>
                            <span v-if="lectDetail.ticketOfferGb === '01'">선착순</span>
                            <span v-if="lectDetail.ticketOfferGb === '02'">서류전형</span>
                            <span v-if="lectDetail.ticketOfferGb === '03'">서류+면접전형</span>
                        </p>
                        <p v-if="lectDetail.regMemOpenYn === 'Y'">
                            <span>신청 인원</span>
                            <span>{{ lectDetail.userLectureCount || 0 }}명</span>
                        </p>
                        <p v-if="lectDetail.selMemOpenYn === 'Y'">
                            <span>선발 인원</span>
                            <span>{{ lectDetail.userLectureSelCount || 0 }}명</span>
                        </p>
                        <p v-if="lectDetail.note">
                            <small>*{{ lectDetail.note }}</small>
                        </p>
                    </div>
                </div>
                <div class="lecture-detail">
                    <KSATab class="tab-style_border" :options="tabOptions" @change="onChangeTab"/>
                    <div v-if="tabIndex === 0">
                        <div class="editor-wrap" v-html="lectDetail.lectIntro"></div>
                    </div>
                    <!-- s : 과정목차 -->
                    <div v-else class="table-form">
                        <table class="horizontal content-offline" v-if="lectIndexList.length > 0">
                          <thead>
                            <tr>
                                <th>주차</th>
                                <th>일시</th>
                                <th>요일</th>
                                <th>시간</th>
                                <th>강사명</th>
                                <th>실습 여부</th>
                                <th>교육명</th>
                                <th>필요 기자재</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(lectIndex, idx) in lectIndexList">
                                <td>
                                    {{ lectIndex.idxNum }}차시
                                </td>
                                <td>
                                    <span v-if="lectIndex.idxYmdYn === 'Y'">자유</span>
                                    <span v-else>{{ $yyyymmdd(lectIndex.idxYmd) }}</span>
                                </td>
                                <td>
                                    <span v-if="lectIndex.idxYmdYn === 'Y'">자유</span>
                                    <span v-else>{{ $dateToWeekDay(lectIndex.idxYmd) }}</span>
                                </td>
                                <td>
                                    {{ lectIndex.idxHms }}시간
                                </td>
                                <td>
                                    <span v-if="!lectIndex.teacher">-</span>
                                    <span v-else>{{ lectIndex.teacher.userName }}</span>
                                </td>
                                <td>
                                    {{ lectIndex.practiceYn === 'Y' ? 'O' : 'X' }}
                                </td>
                                <td class="text-left">{{ lectIndex.idxName }}</td>
                                <td>{{ lectIndex.supplies }}</td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                    <!-- e : 과정목차 -->
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent"/>
    <LectureRegCompleteModal ref="lectureRegCompleteModal" :lecture="lectDetail"/>
    <LectureResumeModal ref="lectureResumeModal"/>
    <ApplyOfflineLectureModal ref="applyOfflineLectureModal"/>
</template>

<style lang="scss" scoped>
    .title {
        span {
            &.badge {
                position: relative;
                top: -4px;
            }
        }
    }
</style>