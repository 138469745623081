<script setup>
    import { ref, watch, onMounted, getCurrentInstance, inject } from 'vue'
    import { useRoute, useRouter, RouterLink } from 'vue-router'
    import { usePageLoadingStore } from "../../../../../stores/pageLoading";
    import _ from "lodash";
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import surveyService from "../../../services/lecture/surveyService";

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const { $surveyQType, $surveyQGb, $toast, $pageListBoardNo, $gb, $gbNameKR } = getCurrentInstance().appContext.config.globalProperties;

    const getSystemCodeValues = inject('systemCodeValues');

    const surveyQType = ref($surveyQType);
    const surveyQGb = ref($surveyQGb);

    onMounted(()=>{
        retrieveSurveyQuestionList();
    })

    let disabledWatchIsSelectedAll = false; // 개별 checkbox 클릭시 watch disabled
    let disabledWatchList = false;          // all checkbox 클릭시 watch disabled

    const isSelectedAll = ref(false);
    watch(isSelectedAll, (newValue)=>{
        if(!disabledWatchIsSelectedAll) {
            const newList = _.cloneDeep(list.value);
            _.each(newList, (el)=>{
                el.checked = newValue;
            });
            disabledWatchList = true;
            list.value = newList;
        } else {
            disabledWatchIsSelectedAll = false;
        }
    })

    const list = ref([])
    watch(list, (newValue)=>{
        if(!disabledWatchList) {
            const checkedList = _.filter(newValue, { checked: true});
            if(checkedList.length === newValue.length) {
                disabledWatchIsSelectedAll = true;
                isSelectedAll.value = true;
            } else {
                if(isSelectedAll.value) {
                    disabledWatchIsSelectedAll = true;
                    isSelectedAll.value = false;
                }
            }
        } else {
            disabledWatchList = false;
        }
    }, { deep: true})

    const searchOpen = ref(true)

    const searchInput = ref({
        useYn: 'A',
        surveyQGbList: ["01", "02", "03", "04", "05"],
        surveyQTypeList: ["02", "03", "04"],
        surveyQTitle: '',
    })

    const currentPage = ref(1);
    const pageSize = ref(30);
    const totalPages = ref(0);
    const totalCount = ref(0);

    const useYnGroup = ref([
        { label:"전체", value: "A" },
        { label:"사용", value: "Y" },
        { label:"미사용", value: "N" },
    ]);

    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ])

    const retrieveSurveyQuestionList = async () => {

        let paramObj = {
            useYn: searchInput.value.useYn,
            surveyQGbList: searchInput.value.surveyQGbList,
            surveyQTypeList: searchInput.value.surveyQTypeList,
            surveyQTitle: searchInput.value.surveyQTitle,
            pageNo: currentPage.value,
            size: pageSize.value,
        }

        loadingStore.loading = true;
        await getSystemCodeValues();
        surveyService.retrieveAllSurveQuestion(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
                    _.each(list.value, surveyQuestion => {
                        surveyQuestion.checked = false;
                    })
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const clearSearchInput = () => {
        searchInput.value = {
            useYn: 'A',
            surveyQGbList: ["01", "02", "04", "03", "05"],
            surveyQTypeList: ["02", "03", "04"],
            surveyQTitle: '',
        }
    }

    const onClickSearch = () => {
        currentPage.value = 1;
        retrieveSurveyQuestionList();
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveSurveyQuestionList();
    }

    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;
    }

    const onClickUseYn = (index) => {
        const checkedList =_.filter(list.value, { checked: true});
        const surveyQIds = _.map(checkedList, 'surveyQId');
        // console.log(surveyQIds);
        if(surveyQIds.length > 0) {
            const useYn = index === 0 ? 'Y' : 'N';
            loadingStore.loading = true;
            surveyService.updateSurveyQuestionUseYn(surveyQIds, useYn)
                .then( async (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        $toast('', `${useYn === 'Y' ? '사용' : '미사용'}으로 변경하였습니다.`);

                        _.each(checkedList, surveyQuestion => {
                            surveyQuestion.useYn = useYn
                            surveyQuestion.checked = false;
                        });
                    } else {
                        $toast('error', '사용 상태 변경을 실패하였습니다.');
                    }


                }).catch( (res) =>  {
                // console.log(res);
                loadingStore.loading = false;
                $toast('error', '사용 상태 변경을 실패하였습니다.');

            });
        } else {
            modalContent.value = ['사용 상태를 변경할 문항을 선택하세요.'];
            modal.value.show();
        }
    }

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>문항 관리</h3>
                </div>
                <div class="filter-wrap" :class="{'open': searchOpen}">
                    <p @click="searchOpen = !searchOpen">검색</p>
                    <div class="filter">
                        <dl>
                            <dt>사용 여부</dt>
                            <dd>
                                <template v-for="group in useYnGroup">
                                    <KSARadioButton class="mr-md" :label="group.label" v-model="searchInput.useYn" :value="group.value" />
                                </template>
                            </dd>
                        </dl>
                        <dl>
                            <dt>문항 구분</dt>
                            <dd>
                                <KSACheckBoxGroup
                                    className="mr-md"
                                    name="surveyQGbList"
                                    v-model="searchInput.surveyQGbList"
                                    :options="surveyQGb" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>문항 유형</dt>
                            <dd>
                                <KSACheckBoxGroup
                                    className="mr-md"
                                    name="surveyQTypeList"
                                    v-model="searchInput.surveyQTypeList"
                                    :options="surveyQType" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>문항</dt>
                            <dd><KSAInput class="text-box" v-model="searchInput.surveyQTitle" placeholder="문항을 입력해주세요." /></dd>
                        </dl>
                        <div class="btn-wrap">
                            <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                            <KSAPrimaryButton text="검색" @click="onClickSearch" />
                        </div>
                    </div>
                </div>
                <div class="list-top">
                    <div class="grid">
                        <KSASecondaryButton class="gray_button" text="문항 등록하기" @click="router.push({name:'LectureSurveyQuestionCreate', params:{id: 0}})" />
                        <KSADropdownButton class="gray_button ml-xs" text="사용 변경" :options="['사용', '미사용']" @click="onClickUseYn" />
                    </div>
                </div>
                <div class="list-top">
                    <p>총 게시물 <span>{{ totalCount }}</span>개</p>
                    <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th><KSACheckBox v-model="isSelectedAll"/></th>
                            <th>NO</th>
                            <th>사용 여부</th>
                            <th>문항 구분</th>
                            <th>문항 유형</th>
                            <th>문항</th>
                            <th>등록일</th>
                        </tr>
                        <tr v-for="surveyQuestion in list" >
                            <td><KSACheckBox v-model="surveyQuestion.checked"/></td>
                            <td>{{ surveyQuestion.boardNo }}</td>
                            <td>{{ surveyQuestion.useYn === 'Y' ? '사용' : '미사용' }}</td>
                            <td>{{ $gbNameKR('SURVEY_Q_GB', surveyQuestion.surveyQGb) }}</td>
                            <td>{{ $gbNameKR('SURVEY_Q_TYPE', surveyQuestion.surveyQType) }}</td>
                            <td class="link text-left"><RouterLink :to="{name: 'LectureSurveyQuestionDetail', params:{id: surveyQuestion.surveyQId }}"><p class="text-over">{{ surveyQuestion.surveyQTitle }}</p></RouterLink></td>
                            <td>{{ $yyyymmddOrHHmm(surveyQuestion.createDate) }}</td>
                        </tr>
                    </table>
                    <div class="result-none" v-if="list.length === 0">
                        검색 결과가 없습니다.
                    </div>
                </div>
                <Paginate
                    v-if="list.length > 0"
                    v-model="currentPage"
                    :page-count="totalPages"
                    :click-handler="onSelectPage"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";

.textarea-box {
  height: 100px;
}
</style>