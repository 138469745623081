<script setup>
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import { ref, defineProps, defineExpose, toRefs, onMounted } from "vue";
    import { useRouter } from 'vue-router'
    import _ from "lodash";

    import Loading from 'vue3-loading-overlay';
    import mypageProposalService from "../../../services/mypage/mypageProposalService";

    const router = useRouter();

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const modalContent = ref([])

    const loaded = ref(false)
    const content = ref(null);

    const show = () => {
        baseModal.value.open();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value(false);
    };

    const retrieveProposalContent = (propId) => {
        loaded.value = false;

        mypageProposalService.retrieveMatchingProposal(propId)
            .then( (res) => {
                loaded.value = true;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result.content;
                    // console.log(result);
                    content.value = result;
                } else {
                    const resultMsg = res.data.msg;
                    console.log(resultMsg);
                    content.value = resultMsg;
                }
            }).catch( (error) =>  {
            loaded.value = true;
            console.log(error);
            content.value = resultMsg;
        });
    }

    defineExpose({
        show,
        retrieveProposalContent,
    })
</script>

<template>
    <Modal ref="baseModal" class="vld-parent">
        <loading
            :active="!loaded"
            loader="dots"
            color="#3843AF"
            opacity="0.5"
            width="35"
            :is-full-page="false"></loading>
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-body" v-show="loaded">
                <div class="text img-container" v-html="content"></div>
            </div>
        </div>
    </Modal>
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";
.modal-inner {
    margin-top: 50px;
    .close {
        top: -20px !important;
    }
    .modal-footer {
        display: block;
        padding: 0 30px;
    }
}
</style>