import http from '../../../../api/http'


function retrieveAllSurveQuestion(paramObj) { // 만족도 문항 목록 조회
    return http.post('/lecture/survey/surveyQuestion/retrieveAllPaging', {
        surveyQGbList: paramObj.surveyQGbList,
        surveyQTypeList: paramObj.surveyQTypeList,
        surveyQTitle: paramObj.surveyQTitle || "",
        useYn: paramObj.useYn || "Y",
        pageNumber: paramObj.pageNo || 1,
        size: paramObj.size || 30,
        orderProperty: paramObj.orderProperty || '',
        orderDirection: paramObj.orderDirection || '',
    })
}

function retrieveSurveyQuestion(surveyQId) { // 만족도 문항 상세 조회
    return http.get(`/lecture/survey/surveyQuestion/retrieve?surveyQId=${surveyQId}`)
}

function createSurveyQuestion(paramObj) { // 만족도 문항 생성
    return http.post('/lecture/survey/surveyQuestion/create', paramObj)
}

function updateSurveyQuestion(paramObj) { // 만족도 문항 수정
    return http.post('/lecture/survey/surveyQuestion/update', paramObj)
}

function deleteSurveyQuestion(surveyQId) { // 만족도 문항 삭제
    return http.post('/lecture/survey/surveyQuestion/delete', {
        surveyQId
    })
}

function updateSurveyQuestionUseYn(surveyQIds, useYn) { // 만족도 문항 사용여부 수정
    return http.post(`/lecture/survey/surveyQuestion/updateUseYn${useYn}`, surveyQIds)
}

function retrieveSurveyQuestionDeletable(surveyQId) { // 만족도 문항 상세 조회
    return http.get(`/lecture/survey/surveyQuestion/retrieveDeletable?surveyQId=${surveyQId}`)
}



function retrieveAllSurveyPaper(paramObj) { // 만족도 평가 목록 조회
    return http.post('/lecture/survey/retrieveAllPaging', {
        title: paramObj.title || '',
        searchDurationGb: paramObj.searchDurationGb || 'C',
        startYmd: paramObj.startYmd || "",
        endYmd: paramObj.endYmd || "",
        pageNumber: paramObj.pageNo || 1,
        size: paramObj.size || 30,
    })
}

function retrieveSurveyPaper(surveyId, lectId) { // 만족도 평가 상세 조회
    return http.get(`/lecture/survey/retrieve?surveyId=${surveyId}&lectId=${lectId}`)
}

function createSurveyPaper(paramObj) { // 만족도 평가 생성
    return http.post('/lecture/survey/create', paramObj)
}

function updateSurveyPaper(paramObj) { // 만족도 평가 수정
    return http.post('/lecture/survey/update', paramObj)
}

function deleteSurveyPaper(surveyId) { // 만족도 평가 삭제
    return http.post('/lecture/survey/delete', {
        surveyId
    })
}

function retrievePaperQuestion(surveyId) { // 만족도 평가 상세 조회
    return http.get(`/lecture/survey/retrievePaperQuestion?surveyId=${surveyId}`)
}

function retrieveLectureBySurveyId(surveyId) { // 만족도 평가 연결된 강의 조회
    return http.get(`/lecture/survey/retrieveLectureBySurveyId?surveyId=${surveyId}`)
}

function retrieveUserSubmitAllPaging(paramObj) { // 만족도 평가 설문결과 조회
    return http.post('/lecture/survey/retrieveUserSubmitAllPaging', paramObj)
}

function retrieveSurveyPaperDeletable(surveyId) { // 만족도 평가 상세 조회
    return http.get(`/lecture/survey/retrieveSurveyPaperDeletable?surveyId=${surveyId}`)
}

function retrieveUserSurveyAnswerByUserLecture(userId, lectId, regDt) { // 만족도 평가 답변 상세 조회
    return http.get(`/lecture/survey/retrieveUserSurveyAnswerByUserLecture?userId=${userId}&lectId=${lectId}&regDt=${regDt}`)
}

function saveUserSurveyAnswer(paramObj) { // 만족도 평가 답변 저장
    return http.post('/lecture/survey/saveUserSurveyAnswer', paramObj)
}

function createUserSurvey(paramObj) { // 만족도 평가 제출 저장
    return http.post('/lecture/survey/createUserSurvey', paramObj)
}

function downloadSurveyAnswers(surveyId) { // 만족도 평가 답변 리스트 다운로드
    return http.post('/lecture/survey/downloadUserSurveyAnswerList', {
        surveyId
    })
}

export default {retrieveAllSurveQuestion,
    retrieveSurveyQuestion,
    createSurveyQuestion,
    updateSurveyQuestion,
    deleteSurveyQuestion,
    updateSurveyQuestionUseYn,
    retrieveSurveyQuestionDeletable,
    retrieveAllSurveyPaper,
    retrieveSurveyPaper,
    createSurveyPaper,
    updateSurveyPaper,
    deleteSurveyPaper,
    retrievePaperQuestion,
    retrieveLectureBySurveyId,
    retrieveUserSubmitAllPaging,
    retrieveSurveyPaperDeletable,
    retrieveUserSurveyAnswerByUserLecture,
    saveUserSurveyAnswer,
    createUserSurvey,
    downloadSurveyAnswers
}