<script setup>
    import { ref, onMounted, getCurrentInstance, provide } from "vue";
    import DefaultLayout from '../layouts/DefaultLayout.vue'
    import systemService from '../services/system/systemCodeService'
    import _ from "lodash";
    import MatchingCorporationUpdateModal from "./user/components/modal/matching/MatchingCorporationUpdateModal.vue";
    import MatchingUserUpdateModal from "./user/components/modal/matching/MatchingUserUpdateModal.vue";
    import UserGbHistoryApproveModal from "./user/components/modal/userGb/UserGbHistoryApproveModal.vue";
    import UserGbHistoryRejectModal from "./user/components/modal/userGb/UserGbHistoryRejectModal.vue";

    const globals = getCurrentInstance().appContext.config.globalProperties;
    globals.$gb = {};

    // 기업 채용 모달 상태를 리액티브 변수로 정의
    const showMatchingCorporationUpdateModal = ref(false);

    // 구직자 채용 모달 상태를 리액티브 변수로 정의
    const showMatchingUserUpdateModal = ref(false);

    // 회원 유형 변경 안내(승인) 모달
    const userGbHistoryApproveModal = ref(null)

    // 회원 유형 변경 안내(거절) 모달
    const userGbHistoryRejectModal = ref(null)

    // 기업 로그인 성공시, 기업 모달 표시
    const handleCorpLoginSuccess = () => {
      const currentURL = window.location.href;
      // 채용 제안 현황이 아닐 경우, true
      const isPropURL = !currentURL.includes("/mypage/proposal");
      // 입사 지원 현황이 아닐 경우, true
      const isResURL = !currentURL.includes("/mypage/userResume");
      // 오프라인 강의 수강신청하기가 아닐 경우, true
      const isOfflineURL = !currentURL.includes("/lecture/offline/detail/");
      // 온라인 강의 수강신청하기가 아닐 경우, true
      const isOnlineURL = !currentURL.includes("/lecture/online/detail/");

      if(isPropURL && isResURL && isOfflineURL && isOnlineURL) {
        showMatchingCorporationUpdateModal.value = true;
      }
    };
    provide('onCorpLoginSuccess', handleCorpLoginSuccess)

    // 구직자 로그인 성공시, 구직자 모달 표시
    const handleUserLoginSuccess = () => {
      const currentURL = window.location.href;
      // 채용 제안 현황이 아닐 경우, true
      const isPropURL = !currentURL.includes("/mypage/proposal");
      // 입사 지원 현황이 아닐 경우, true
      const isResURL = !currentURL.includes("/mypage/userResume");
      // 오프라인 강의 수강신청하기가 아닐 경우, true
      const isOfflineURL = !currentURL.includes("/lecture/offline/detail/");
      // 온라인 강의 수강신청하기가 아닐 경우, true
      const isOnlineURL = !currentURL.includes("/lecture/online/detail/");
      if(isPropURL && isResURL && isOfflineURL && isOnlineURL) {
        showMatchingUserUpdateModal.value = true;
      }
    };
    provide('onUserLoginSuccess', handleUserLoginSuccess)

    // 사용자 회원 유형 변경 "승인" 모달을 띄워야 하는 경우, 모달 표시
    const handleUserGbHistoryApproveModal = async (seq, beforeUserGb, afterUserGb) => {
      userGbHistoryApproveModal.value.init(seq, beforeUserGb, afterUserGb);
      await userGbHistoryApproveModal.value.show();
    }
    provide('onUserGbHistoryApproveModal', handleUserGbHistoryApproveModal)

    // 사용자 회원 유형 변경 "거절" 모달을 띄워야 하는 경우, 모달 표시
    const handleUserGbHistoryRejectModal = async (seq, beforeUserGb, afterUserGb) => {
      userGbHistoryRejectModal.value.init(seq, beforeUserGb, afterUserGb);
      await userGbHistoryRejectModal.value.show();
    }
    provide('onUserGbHistoryRejectModal', handleUserGbHistoryRejectModal)

    // 모달 컴포넌트 import
    const components = {
      MatchingCorporationUpdateModal,
      MatchingUserUpdateModal,
      UserGbHistoryApproveModal,
      UserGbHistoryRejectModal,
    };

    // 로그 아웃시, 채용 모달 초기화
    const handleLogoutModal = () => {
      showMatchingCorporationUpdateModal.value = false;
      showMatchingUserUpdateModal.value = false;
    }
    provide('onLogoutModal', handleLogoutModal)


    const CODE_NAMES = [
        "USER_GB",
        "SEX",
        "EMPLOYEE_NUM_GB",
        "INDUSTRY_GB",
        "EDU_HIST_GB",
        "EDU_TENURE_GB",
        "POFOL_GB",
        "DEGREE_GB",
        "LANG_LEVEL_GB",
        "DISABLE_GRADE_GB",
        "MIL_SERVICE_GB",
        "MAJOR_GB",
        "FULL_SCORE_GB",
        "SCHOOL_GB",
        "LANG_GB",
        "SUBMAJOR_TYPE_GB",
        "BOARD_GB",
        "BOARD_GROUP_FAQ",
        "BOARD_GROUP_QNA",
        "MENU_AREA_GB",
        "BIZ_STATE_GB",
        "TAX_TYPE_GB",
        "LECT_GB",
        "REG_TERM_GB",
        "LECT_TERM_GB",
        "FIN_CRITERIA_GB",
        "TICKET_OFFER_GB",
        "INTV_TERM_GB",
        "BANNER_GB",
        "MSG_GB",
        "TEMPATE_TYPE_EMAIL",
        "TEMPATE_TYPE_SMS",
        "SURVEY_Q_GB",
        "SURVEY_Q_TYPE",
        "MATCHING_JOB_STATE_CORP_GB",
        "MATCHING_JOB_STATE_USER_GB",
        "MATCHING_JOB_STATE_CORP_GB2",
        "MATCHING_JOB_STATE_USER_GB2",
        "USER_GB_STATE_GB",
        "EVAL_STATE_GB1",
        "EVAL_STATE_GB2",
        "LECT_STATE_GB1",
        "LECT_STATE_GB2",
    ];

    onMounted(()=>{
        getSystemCodeValues();
    });

    const getSystemCodeValues = async () => {
        await systemService.getSystemCodeValues(CODE_NAMES)
            .then( (res) => {
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    _.each(result, async codeObj => {
                      const {codeValues} = codeObj;
                      const temp = await _.map(codeValues, codeVal => {
                        return {label: codeVal.codeValueName, value: codeVal.codeValue};
                      }) || [];

                      switch (codeObj.codeName) {
                        case 'MAJOR_GB':
                          temp.unshift({label: '전공 계열 선택', value: '',});
                          break;
                        case 'SCHOOL_GB':
                          temp.unshift({label: '학교 구분 선택', value: '',});
                          break;
                        case 'LANG_GB':
                          temp.unshift({label: '언어 선택', value: '',});
                          break;
                        case 'EDU_TENURE_GB':
                          temp.unshift({label: '상태', value: '',});
                          break;
                        case 'EDU_HIST_GB':
                          temp.unshift({label: '학력 선택', value: '',});
                          break;
                        default:
                          break;
                      }
                      globals.$gb[codeObj.codeName] = temp;
                    });
                }
            }).catch( (error) =>  {
                console.log(error);
            });
    }
    provide('systemCodeValues', getSystemCodeValues)
</script>

<template>
    <DefaultLayout>
        <RouterView/>
    </DefaultLayout>
    <MatchingCorporationUpdateModal v-if="showMatchingCorporationUpdateModal" />
    <MatchingUserUpdateModal v-if="showMatchingUserUpdateModal" />
    <UserGbHistoryApproveModal ref="userGbHistoryApproveModal"/>
    <UserGbHistoryRejectModal ref="userGbHistoryRejectModal"/>
</template>
