import http from '../../../../api/http';

// 채용제안서 리스트 조회
function retrieveProposalList(paramObj) {
    return http.post('/mypage/matching/retrieveProposalList', paramObj)
}

// 채용제안서 보낼 인재 리스트 조회
function retrieveProposalSearchUserList(paramObj) {
    return http.post('/mypage/matching/retrieveProposalSearchUserList', paramObj)
}

// 채용제안서 보기
function retrieveMatchingProposal(propId) {
    return http.post('/mypage/matching/retrieveMatchingProposal?propId='+propId)
}

// 채용제안서 보낸 인재 이력서 다운로드
function downloadProposalUserList(paramObj) {
    return http.post('/mypage/matching/downloadProposalUserList', paramObj)
}

// 채용제안서 보낸 인재리스트
function retrieveProposalUserList(paramObj) {
    return http.post('/mypage/matching/retrieveProposalUserList', paramObj)
}


// 채용제안서&채용제안서매칭 생성
function createProposalUser(paramObj) {
    return http.post('/mypage/matching/createProposalUser', paramObj)
}

// 채용제안서 기반 채용 상태 변경
function changeProposalUserState(paramObj) {
    return http.post('/mypage/matching/changeProposalUserState', paramObj)
}

// 인재 채용 현황
function retrieveResumeCorporationList(paramObj) {
    return http.post('/mypage/matching/retrieveResumeCorporationList', paramObj)
}

// 이력서 기반 채용 상태 변경
function changeResumeCorporationState(paramObj) {
    return http.post('mypage/matching/changeResumeCorporationState', paramObj)
}

// 인재검색 리스트
function retrieveSearchResumeList(paramObj) {
    return http.post('/mypage/matching/retrieveSearchResumeList', paramObj)
}

// 채용제안 하기
function createResumeCorporationByResumeId(paramObj) {
    return http.post('/mypage/matching/createResumeCorporation', paramObj)
}

// 관심인재 리스트
function retrieveResumeLikeList(paramObj) {
    return http.post('/mypage/matching/retrieveResumeLikeList', paramObj)
}

// 관심인재 상태 변경
function changeResumeLikeByResumeId(paramObj) {
    return http.post('/mypage/matching/changeResumeLike', paramObj)
}

export default {
    retrieveProposalList,
    retrieveProposalSearchUserList,
    retrieveMatchingProposal,
    downloadProposalUserList,
    retrieveProposalUserList,
    createProposalUser,
    changeProposalUserState, // 채용 상태 변경(제용제안서)
    retrieveResumeCorporationList, // 인재 채용 현황
    changeResumeCorporationState, // 채용 상태 변경(이력서기반)
    retrieveSearchResumeList, // 인재검색 리스트
    createResumeCorporationByResumeId, // 채용제안 하기
    retrieveResumeLikeList, // 관심인재 리스트
    changeResumeLikeByResumeId, // 관심인재 상태 변경
};