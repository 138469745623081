<script setup>
import { ref, onMounted, getCurrentInstance, inject } from 'vue'
import { useRoute, useRouter, RouterLink } from 'vue-router'
import {usePageLoadingStore} from "../../../../../stores/pageLoading";
import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
import surveyService from "../../../services/lecture/surveyService";
import LectureSurveyPreviewModal from "../../../components/modal/lecture/LectureSurveyPreviewModal.vue";
import LectureSurveyModal from "../../../../user/components/modal/mypage/LectureSurveyModal.vue";
import KSAPrimaryButton from "../../../../../components/ui-components/button/KSAPrimaryButton.vue";

const route = useRoute()
const router = useRouter()
const loadingStore = usePageLoadingStore()
const { $toast, $pageListBoardNo } = getCurrentInstance().appContext.config.globalProperties;

const getSystemCodeValues = inject('systemCodeValues');

onMounted(()=>{
    const { id } = route.params;
    if(!id) {
        router.push({name: 'LectureSurveyPaperList'});
    } else {
        retrieveSurveyPaperDetail(id);
        retrieveSurveyPaperQuestionDetail(id);
        retrieveSurveyPaperDeletable(id);
    }
})

const modal = ref(null)
const confirmModal = ref(null)
const modalContent = ref([])
const okButtonText = ref("메일발송")
const lectureSurveyModal = ref(null)
const surveyPreviewModal = ref(null)
const surveyPaperRelatedCount = ref({});
const deletable = ref(true);

const tabIndex = ref(0)

const input = ref({
  title: '',
})

// 만족도 평가 기본정보
const surveyPaperDetail = ref({
    surveyId: route.params.id || '',
    title: '',
    useYn: 'Y',
    totalLecture: '',
    totalSubmit: '',
    recentSurveyDate: '',
    createDate: '',
    modifyDate: '',
});
const surveyPaperQuestionList = ref([]); // 만족도 평가 기본정보 평가문항
const surveyPaperLectureList = ref([]);  // 만족도 평가 연결된 강의
const surveyPaperUserList = ref([]);  // 만족도 평가 설문결과

// 설문결과 조회조건
const _searchInput = ref({
    searchType: 'all', // all 전체, lectName 강의명, userName 회원이름, userLoginId 회원로그인아이디
    searchInput: '',
})

const totalLectureCount = ref(0); // 연결된 강의 수
const totalCount = ref(0);  // 설문결과 수
const currentPage = ref(1); // 설문결과 현재 페이지
const pageSize = ref(30);   // 설문결과 페이지별 크기
const totalPages = ref(0);  // 설문결과 총 페이지

const searchTypeGroup = [
    { label:'전체', value:'all', },
    { label:'강의명', value:'lectName', },
    { label:'참여자', value:'userName', },
    { label:'참여자 아이디', value:'userLoginId', },
]

// 만족도 평가 기본정보 > 기본정보 조회
const retrieveSurveyPaperDetail = async (id) => {
    loadingStore.loading = true;
    await getSystemCodeValues();
    surveyService.retrieveSurveyPaper(id, 0)
        .then( (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                surveyPaperDetail.value = result;
                input.value.title = result.title;
            }
        }).catch( (error) =>  {
        loadingStore.loading = false;
        console.log(error);
    });
}

// 만족도 평가 기본정보 > 평가문항 조회
const retrieveSurveyPaperQuestionDetail = (id) => {
    loadingStore.loading = true;
    surveyService.retrievePaperQuestion(id)
        .then( (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                surveyPaperQuestionList.value = result;
            }
        }).catch( (error) =>  {
        loadingStore.loading = false;
        console.log(error);
    });
}

// 연결된 강의 조회
const retrieveSurveyPaperLecture = (id) => {
    loadingStore.loading = true;
    surveyService.retrieveLectureBySurveyId(id)
        .then( (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                totalLectureCount.value = result.totalElements;
                surveyPaperLectureList.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
            }
            console.log("surveyPaperLectureList.userLectureSelCount : " + surveyPaperLectureList.value[0].userLectureSelCount);
        }).catch( (error) =>  {
        loadingStore.loading = false;
        console.log(error);
    });
}

// 설문결과 조회 (페이징)
const retrieveSurveyUserSubmit = (id) => {
    let paramObj = {
        surveyId: id,
        pageNo: currentPage.value,
        size: pageSize.value,
        searchType: _searchInput.value.searchType,
        searchInput: _searchInput.value.searchInput,
    }

    loadingStore.loading = true;
    surveyService.retrieveUserSubmitAllPaging(paramObj)
        .then( (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                totalPages.value = result.totalPages;
                totalCount.value = result.totalElements;
                surveyPaperUserList.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
            }
        }).catch( (error) =>  {
        loadingStore.loading = false;
        console.log(error);
    });
}

const retrieveSurveyPaperDeletable = (id) => {
    loadingStore.loading = true;
    surveyService.retrieveSurveyPaperDeletable(id)
        .then( (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                surveyPaperRelatedCount.value = result;
                if ( surveyPaperRelatedCount.value.lectureCount > 0 ) {
                    deletable.value = false;
                } else {
                    deletable.value = true;
                }
            }
        }).catch( (error) =>  {
        loadingStore.loading = false;
        console.log(error);
    });
}

// 목록 버튼
const goBack = () => {
    router.push({name:'LectureSurveyPaperList'})
}

// 미리보기 버튼
const onClickPreviewModal = () => {
    lectureSurveyModal.value.init(0, 0, 0, surveyPaperDetail.value.surveyId)
    lectureSurveyModal.value.show();
}

// 만족도 평가 복사
const copySurveyPaper = () => {
    router.push( { name:'LectureSurveyPaperCreate', params:{id: surveyPaperDetail.value.surveyId} } )
}

// 삭제 버튼
const onDeleteSurveyPaper = async () => {
    if ( surveyPaperRelatedCount.value.lectureCount > 0 ) {
        modalContent.value = ["연결된 강의가 있는 만족도 평가는 삭제할 수 없습니다."];
        modal.value.show()
        return false;
    } else {
        modalContent.value = ["만족도 평가를 삭제할까요?", "삭제 시 만족도 평가 설문 결과 등 모든 정보가", "삭제되며 되돌릴 수 없습니다."];
        okButtonText.value = "삭제";
        const ok = await confirmModal.value.show()
        if(ok){
            _deleteSurveyPaper()
        }
    }
}

// 만족도 평가 삭제 처리
function _deleteSurveyPaper() {
    loadingStore.loading = true;
    surveyService.deleteSurveyPaper(surveyPaperDetail.value.surveyId)
        .then( (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                goBack();
            } else {
                modalContent.value = ["삭제를 실패하였습니다."];
                modal.value.show();
            }
        }).catch( (res) =>  {
        loadingStore.loading = false;
        console.log(res);
        modalContent.value = ["삭제를 실패하였습니다."];
        modal.value.show();
    });
}

// 탭 선택
const onSelectTab = (option) => {
    tabIndex.value = option.value;
    //currentPage.value = 1;
    if(tabIndex.value === 0) { // 기본 정보
        retrieveSurveyPaperDetail(surveyPaperDetail.value.surveyId);
        retrieveSurveyPaperQuestionDetail(surveyPaperDetail.value.surveyId);
    } else if(tabIndex.value === 1) { // 연결된 강의
        retrieveSurveyPaperLecture(surveyPaperDetail.value.surveyId);
    } else if(tabIndex.value === 2) { // 설문 결과
        retrieveSurveyUserSubmit(surveyPaperDetail.value.surveyId);
    }
    //initValuesByTab();
}

// 연결된 강의 > 강의명 클릭(새창)
const onClickLectId = (lectId) => {
    window.open(`/admin/lecture/manage/detail/${lectId}`, '_blank');
}

// 설문결과 > row 클릭(새창)
const onClickSurveyPaperUser = (surveyPaperUser) => {
    surveyPreviewModal.value.init(surveyPaperUser.userId, surveyPaperUser.lectId, surveyPaperUser.regDt, surveyPaperDetail.value.surveyId)
    surveyPreviewModal.value.show();
}

// 설문결과 > 검색범위 셀렉트박스 변경
const onSelectSearchTypeGroup = (option) => {
    _searchInput.value.searchType = option.value;
}

// 설문결과 > 검색버튼 클릭
const onClickSearch = (value) => {
    _searchInput.value.searchInput = value;
    currentPage.value = 1;
    retrieveSurveyUserSubmit();
}

const changeform = () => {
  originTitle.style.display = "none";
  newTitle.style.display = "block";
}

const updateSurveyTitle = () => {
  // const { surveyId, title, userYn } = input.value;
  const surveyId = surveyPaperDetail.value.surveyId;
  const title = input.value.title;
  const useYn = surveyPaperDetail.value.useYn
  if(!title) {
    modalContent.value = ["만족도 평가 제목을 작성해 주세요."];
    modal.value.show();
  } else {
    let paramObj = {
      surveyId: surveyId,
      title: title,
      useYn: useYn,
    }
    loadingStore.loading = true;
    surveyService.updateSurveyPaper(paramObj)
        .then( (res) => {
          loadingStore.loading = false;
          if(res.data.code.toLowerCase() === 'success') {
            originTitle.style.display = "block";
            newTitle.style.display = "none";
            retrieveSurveyPaperDetail(surveyId);
          }
        }).catch( (error) =>  {
      loadingStore.loading = false;
      console.log(error);
    });
  }
}

// 만족도 평가 설문 결과 다운로드
const onClickExcelDownload = async () => { // 엑셀 다운로드
  modalContent.value = ["만족도 조사 결과를 이메일로 발송할까요?"];
  okButtonText.value = "메일발송";
  const ok = await confirmModal.value.show();
  if(ok){
    downloadSurveyAnswers();
  }
}

const downloadSurveyAnswers = () => {
  surveyService.downloadSurveyAnswers(surveyPaperDetail.value.surveyId)
      .then( (res) => {
        $toast('', `이메일로 발송되었습니다.`);
      }).catch( (res) =>  {
    //$toast('error', '오류가 발생하였습니다.');
  });
}

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>만족도 평가 상세</h3>
                </div>
                <div class="btn-wrap space-between mb-xs">
                    <div>
                      <KSASecondaryButton class="gray_button" text="목록" @click="goBack" />
                      <KSASecondaryButton class="gray_button" text="미리보기" @click="onClickPreviewModal" />
                    </div>
                    <div>
                        <KSASecondaryButton class="gray_button" text="만족도 평가 복사" @click="copySurveyPaper" />
                        <KSASecondaryButton class="red_button" text="삭제" @click="onDeleteSurveyPaper" :disabled="!deletable" />
                    </div>
                </div>
                <KSATab style="grid-template-columns: repeat(3, 1fr);" class="mb-lg"
                        :options="[
                            {label:'기본 정보', value:0},
                            {label:`연결된 강의${surveyPaperDetail.totalLecture > 0 ? ('(' + surveyPaperDetail.totalLecture + ')') : ''}`, value:1},
                            {label:`설문 결과${surveyPaperDetail.totalSubmit > 0 ? ('(' + surveyPaperDetail.totalSubmit + ')') : ''}`, value:2},
                        ]"
                        @change="onSelectTab"
                />

                <!-- s: 기본 정보 -->
                <div v-show="tabIndex === 0">
                    <div class="content-inner mb-lg">
                        <div class="content-title">
                            <h4>기본 정보</h4>
                        </div>
                        <div class="content-item">
                            <div class="table-form">
                                <table class="vertical">
                                    <colgroup>
                                        <col width="200px" />
                                        <col />
                                        <col width="200px" />
                                        <col />
                                    </colgroup>
                                    <tr>
                                        <th>사용 여부</th>
                                        <td colspan="3">{{ surveyPaperDetail.useYn === 'Y' ? '사용' : '미사용'}}</td>
                                    </tr>
                                    <tr>
                                        <th>만족도 평가 제목</th>
                                        <td id="originTitle"><span style="float:left; width: 80%;">{{ surveyPaperDetail.title }}</span><span style="float:right;"><KSAPrimaryButton text="제목 수정" @click="changeform" /></span></td>
                                        <td id="newTitle" style="display: none;" ><span style="float:left; width: 80%;"><KSAInput class="text-box" v-model="input.title" /></span><span style="float:right;"><KSAPrimaryButton text="저장" @click="updateSurveyTitle" /></span></td>
                                        <th>참여 인원</th>
                                        <td>{{ $numberZero(surveyPaperDetail.totalSubmit) }} 명</td>
                                    </tr>
                                    <tr>
                                        <th>등록일</th>
                                        <td>{{ $yyyymmddHHmm(surveyPaperDetail.createDate) }}</td>
                                        <th>최근 설문일</th>
                                        <td>{{ $yyyymmddHHmm(surveyPaperDetail.recentSurveyDate) }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="content-inner">
                        <div class="content-title">
                            <h4>평가 문항({{ surveyPaperQuestionList.length }})</h4>
                        </div>
                        <div class="content-item">
                            <div class="table-form">
                                <table class="horizontal">
                                    <tr>
                                        <th></th>
                                        <th>문항 구분</th>
                                        <th>문항 유형</th>
                                        <th>문항</th>
                                        <th>다중응답형 보기</th>
                                    </tr>
                                    <tr v-for="(surveyPaperQuestion, index) in surveyPaperQuestionList">
                                        <td>{{ index+1 }}</td>
                                        <td>{{ $gbNameKR('SURVEY_Q_TYPE', surveyPaperQuestion.surveyQType) }}</td>
                                        <td>{{ $gbNameKR('SURVEY_Q_GB', surveyPaperQuestion.surveyQGb) }}</td>
                                        <td class="text-left">{{ surveyPaperQuestion.surveyQTitle }}</td>
                                        <td v-if="surveyPaperQuestion.choiceList !== null">
                                            <span v-for="(choice, index) in surveyPaperQuestion.choiceList">
                                                {{ index+1 }}. {{ choice.surveyChoiceContent }}
                                            <br /></span>
                                            <span v-if="surveyPaperQuestion.etcYn === 'Y'">
                                                {{ surveyPaperQuestion.choiceList.length+1 }}. 기타
                                            </span>
                                        </td>
                                        <td v-if="surveyPaperQuestion.pointOptions !== null">
                                            <span v-for="(point, index) in surveyPaperQuestion.pointOptions">
                                                {{ index+1 }}. {{ point.surveyChoiceContent }}
                                            <br /></span>
                                        </td>
                                    </tr>
                                </table>
                                <div class="result-none" v-if="surveyPaperQuestionList.length === 0">
                                    검색 결과가 없습니다.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- e: 기본 정보 -->

                <!-- s: 연결된 강의 -->
                <div v-show="tabIndex === 1">
                    <div class="list-top">
                        <p>총 <span v-if="totalLectureCount === null">0</span> <span v-else>{{totalLectureCount}}</span> 개</p>
                    </div>
                    <div class="table-form">
                        <table class="horizontal">
                            <tr>
                                <th>구분</th>
                                <th>강의명</th>
                                <th>교육 기간</th>
                                <th>설문 제출자/수강생</th>
                                <th>설문 결과</th>
                                <th>수강 관리</th>
                            </tr>
                            <tr v-for="(surveyPaperLecture, index) in surveyPaperLectureList">
                                <td>{{ surveyPaperLecture.lectGb === '01' ? '온라인' : '오프라인'}}</td>
                                <td class="text-left"><KSATextButton :text="surveyPaperLecture.lectName" @click="onClickLectId(surveyPaperLecture.lectId)" /></td>
                                <td>
                                    <span v-if="surveyPaperLecture.lectTermGb === '01'">신청일로부터 {{ surveyPaperLecture.lectTerm }}일</span>
                                    <span v-else>{{ $yyyymmdd(surveyPaperLecture.lectStartYmd) }} ~ {{ $yyyymmdd(surveyPaperLecture.lectEndYmd) }}</span>
                                </td>
                                <td>
                                    <span v-if="surveyPaperLecture.userLectureSelCount === null || surveyPaperLecture.userLectureSelCount === '' ">-</span>
                                    <span v-else>{{ $numberZero(surveyPaperLecture.totalSubmit) }}/{{ surveyPaperLecture.userLectureSelCount }}</span>
                                </td>
                                <td>
                                    <span v-if="surveyPaperLecture.avgResult === null || surveyPaperLecture.avgResult === '' ">-</span>
                                    <span v-else>{{ surveyPaperLecture.avgResult }}</span>
                                </td>
                                <td class="link text-center">
                                    <RouterLink :to="{name: 'LectureCourseDetail', params: {id: 18 }}">수강 관리</RouterLink>
                                </td>
                            </tr>
                        </table>
                        <div class="result-none" v-if="surveyPaperLectureList.length === 0">
                            연결된 강의가 없습니다.
                        </div>
                    </div>
                </div>
                <!-- e: 연결된 강의 -->

                <!-- s: 설문 결과 -->
                <div v-show="tabIndex === 2">
                    <div class="list-top">
                      <div class="grid">
                        <KSASecondaryButton class="gray_button" text="엑셀 다운로드" @click="onClickExcelDownload" />
                      </div>
                    </div>
                    <div class="list-top">
                        <p>총 <span v-if="totalCount === null">0</span><span v-else>{{totalCount}}</span> 개</p>
                        <div class="search-box">
                            <KSASelect ref="searchTypeRef" :options="searchTypeGroup" class="search-select" @selectOption="onSelectSearchTypeGroup"/>
                            <KSASearchInputBox placeholder="검색어를 입력하세요." class="search-input" @search="onClickSearch" />
                        </div>
                    </div>
                    <div class="table-form">
                        <table class="horizontal">
                            <tr>
                                <th>번호</th>
                                <th>구분</th>
                                <th>강의명</th>
                                <th>참여자</th>
                                <th>참여자 아이디</th>
                                <th>설문 결과</th>
                                <th>설문일</th>
                            </tr>
                            <tr v-for="(surveyPaperUser, index) in surveyPaperUserList" @click="onClickSurveyPaperUser(surveyPaperUser)">
                                <td>{{ surveyPaperUser.boardNo }}</td>
                                <td>{{ surveyPaperUser.lectGb === '01' ? '온라인' : '오프라인'}}</td>
                                <td>{{ surveyPaperUser.lectName }}</td>
                                <td>{{ surveyPaperUser.userName }}</td>
                                <td>{{ surveyPaperUser.userLoginId }}</td>
                                <td>{{ $number(surveyPaperUser.result) }}</td>
                                <td>{{ $yyyymmddOrHHmm(surveyPaperUser.surveyDt) }}</td>
                            </tr>
                        </table>
                        <div class="result-none" v-if="surveyPaperUserList.length === 0">
                            설문 결과가 없습니다.
                        </div>
                    </div>
                </div>
                <!-- e: 설문 결과 -->
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText" cancelButtonText="취소" />
    <LectureSurveyModal ref="lectureSurveyModal" />
    <LectureSurveyPreviewModal ref="surveyPreviewModal"/>
</template>

<style lang="scss" scoped>
@import "../../../../../assets/scss/theme";

.table-form {
  table {
    &.vertical {
      td {
        .horizontal {
          th {
            text-align: center;
          }
        }
      }
    }
    &.horizontal {
      tr:hover {
        background: transparent;
      }
    }
  }
}
</style>