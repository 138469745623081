<script setup>
    import { ref, computed, onMounted, getCurrentInstance } from 'vue'
    import { useRouter, RouterLink } from 'vue-router'
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import _ from "lodash";
    import insightsQNAService from "../../../../user/services/insights/insightsQNAService";

    const router = useRouter();
    const loadingStore = usePageLoadingStore()
    const { $pageListBoardNo } = getCurrentInstance().appContext.config.globalProperties;

    onMounted(() => {
        retrieveList();
    })

    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = ref(30);
    const totalPages = ref(1);
    const totalCount = ref(0);

    const searchOpen = ref(true)

    const answerStateGb = ref([
        { label: '답변 대기', value: 'N'},
        { label: '답변 완료', value: 'Y'},
    ]);

    const searchTypeGroup = ref([
        { label:"작성자", value: "name", placeholder: "작성자명을 입력해주세요."  },
        { label:"아이디", value: "id", placeholder: "아이디를 입력해주세요."  },
        { label:"제목", value: "title", placeholder: "제목을 입력해주세요."  },
    ]);

    const answerStateRef = ref(null)
    const searchTypeRef = ref(null)

    const searchInputPlaceholder = computed(()=>{
        const option = _.find(searchTypeGroup.value, { value: searchInputs.value.searchType });
        if(option) {
            return option.placeholder;
        } else {
            return '';
        }
    })

    const searchDurationTypeGroup = ref([
        { label:"모든 기간", value: "all" },
        { label:"기간 검색", value: "term" },
    ])

    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ])

    const searchInputs = ref({
        answerStateList: ["N","Y"],
        searchType: 'name',
        searchInput: '',
        searchDurationType: 'all',
        startYmd: '',
        endYmd: '',
    })

    const retrieveList = () => {
        let paramObj = {
            boardGroup: '', // searchInputs.value.boardGroup,
            pageNo: currentPage.value,
            size: pageSize.value,
            searchType: searchInputs.value.searchType,
            searchInput: searchInputs.value.searchInput,
            startYmd: searchInputs.value.searchDurationType === 'all' ? '' : searchInputs.value.startYmd,
            endYmd: searchInputs.value.searchDurationType === 'all' ? '' : searchInputs.value.endYmd,
            answerStateList: searchInputs.value.answerStateList,
        };

        loadingStore.loading = true;
        insightsQNAService.retrieveAllPaging(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalCount.value = result.totalElements;
                    totalPages.value = result.totalPages;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }


    const onSelectSearchTypeGroup = (option) => {
        searchInputs.value.searchType = option.value;
    }

    const clearSearchInput = () => {
        searchInputs.value.searchType = 'name';
        searchInputs.value.searchInput = '';
        searchInputs.value.searchDurationType = 'all';
        searchInputs.value.answerState = ["N","Y"];

        answerStateRef.value.initOptions();
        searchTypeRef.value.initSelectedOption(searchInputs.value.searchType)
    }

    const onClickSearch = () => {
        currentPage.value = 1;
        retrieveList();
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }

    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;
        retrieveList();
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>Q&amp;A</h3>
                </div>
                <div class="filter-wrap open">
                    <p>검색</p>
                    <div class="filter">
                        <dl>
                            <dt>상태</dt>
                            <dd>
                                <KSACheckBoxGroup
                                    ref="answerStateRef"
                                    className="mr-md"
                                    name="boardGroup"
                                    v-model="searchInputs.answerStateList"
                                    :options="answerStateGb" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <KSASelect ref="searchTypeRef" :options="searchTypeGroup" class="select" @selectOption="onSelectSearchTypeGroup"/>
                            </dt>
                            <dd>
                                <KSAInput class="text-box" v-model="searchInputs.searchInput" :placeholder="searchInputPlaceholder" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                작성일
                            </dt>
                            <dd class="text-box">
                                <div class="grid">
                                    <template v-for="group in searchDurationTypeGroup">
                                        <KSARadioButton class="mr-md" name="searchDurationGb" :label="group.label" v-model="searchInputs.searchDurationType" :value="group.value" />
                                    </template>
                                    <div style="display: inline-block;" v-show="searchInputs.searchDurationType !== 'all'">
                                        <KSADatepickerTerms
                                            :startDate="searchInputs.startYmd"
                                            :endDate="searchInputs.endYmd"
                                            @update:startDate="(value)=> searchInputs.startYmd = value"
                                            @update:endDate="(value)=> searchInputs.endYmd = value"
                                        />
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <div class="btn-wrap">
                            <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                            <KSAPrimaryButton text="검색" @click="onClickSearch" />
                        </div>
                    </div>
                </div>
                <div class="list-top">
                    <p>총 게시물 <span>{{ $number(totalCount) }}</span>개</p>
                    <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th>No</th>
                            <th>상태</th>
                            <th>제목</th>
                            <th>회원코드</th>
                            <th>이름</th>
                            <th>아이디</th>
                            <th>관리자</th>
                            <th>관리자 아이디</th>
                            <th>작성일</th>
                        </tr>
                        <tr v-for="board in list">
                            <td>{{ board.boardNo }}</td>
                            <td :class="{'wait': !board.answer, 'complete': board.answer}">답변 {{ board.answer ? '완료' : '대기' }}</td>
                            <td class="link text-left">
                                <RouterLink :to="{name: 'CSQNADetail', params:{id: board.boardId}}">
                                    {{ board.title }}
                                    <img v-if="board.secretYn === 'Y'" src="/src/assets/images/icon-lock.png" />
                                </RouterLink>
                            </td>
                            <td>
                                <span v-if="board.creator && board.creator.userCd">{{ board.creator.userCd}}</span>
                                <span v-else>-</span>
                            </td>
                            <td>{{ board.createUserName || '작성자' }}</td>
                            <td>
                                <span v-if="board.creator && board.creator.userLoginId">{{ board.creator.userLoginId}}</span>
                                <span v-else>-</span>
                            </td>
                            <td>
                                <span v-if="board.answererUserName">{{ board.answererUserName }}</span>
                                <span v-else>-</span>
                            </td>
                            <td>
                                <span v-if="board.answererUserLoginId">{{ board.answererUserLoginId }}</span>
                                <span v-else>-</span>
                            </td>
                            <td>{{ $yyyymmddOrHHmm(board.createDate) }}</td>
                        </tr>
                        <tr v-if="list.length === 0">
                            <td colspan="9">검색 결과가 없습니다.</td>
                        </tr>
                    </table>
                </div>
                <Paginate
                    v-if="list.length > 0"
                    v-model="currentPage"
                    :page-count="totalPages"
                    :click-handler="onSelectPage"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

</style>