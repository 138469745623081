import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { globalCookiesConfig } from "vue3-cookies";
// import ToastPlugin from 'vue-toast-notification';
// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

import App from './App.vue'
import router from '../router'

import '../assets/fonts/fonts.css'
import '../assets/main.css'
import {useUserStore} from "./user/stores/user";


import KSACheckBox from '../components/ui-components/KSACheckBox.vue'
import KSACheckBoxGroup from '../components/ui-components/KSACheckBoxGroup.vue'
import KSADatepicker from '../components/ui-components/KSADatepicker.vue'
import KSADatepickerTerms from '../components/ui-components/KSADatepickerTerms.vue'
import KSAFormEmail from '../components/ui-components/KSAFormEmail.vue'
import KSAImage from '../components/ui-components/KSAImage.vue'
import KSARadioButton from '../components/ui-components/KSARadioButton.vue'
import KSASearchInputBox from '../components/ui-components/KSASearchInputBox.vue'
import KSATab from '../components/ui-components/KSATab.vue'
import KSATextArea from '../components/ui-components/KSATextArea.vue'
import AlertModal from '../components/ui-components/modal/AlertModal.vue'
import ConfirmModal from '../components/ui-components/modal/ConfirmModal.vue'
import Paginate from '../components/ui-components/pagination/Paginate.vue'
import KSASelect  from '../components/ui-components/select/KSASelect.vue'
import KSASelectEmail  from '../components/ui-components/select/KSASelectEmail.vue'
import KSASelectResidenceArea  from '../components/ui-components/select/KSASelectResidenceArea.vue'
import KSATextButton  from '../components/ui-components/button/KSATextButton.vue'
import KSAPrimaryButton  from '../components/ui-components/button/KSAPrimaryButton.vue'
import KSASecondaryButton  from '../components/ui-components/button/KSASecondaryButton.vue'
import KSADropdownButton  from '../components/ui-components/button/KSADropdownButton.vue'
import KSAInput  from '../components/ui-components/input/KSAInput.vue'
import KSAInputCompNum  from '../components/ui-components/input/KSAInputCompNum.vue'
import KSAInputPhoneNum  from '../components/ui-components/input/KSAInputPhoneNum.vue'
import KSAInputSchoolSelect  from '../components/ui-components/input/KSAInputSchoolSelect.vue'
import KSAInputTeacherSelect  from '../components/ui-components/input/KSAInputTeacherSelect.vue'
import KSAInputVerifyCheck  from '../components/ui-components/input/KSAInputVerifyCheck.vue'
import KSAInputDateYYYYMM  from '../components/ui-components/input/KSAInputDateYYYYMM.vue'
import KSAInputScore  from '../components/ui-components/input/KSAInputScore.vue'
import KSACKEditor from "../components/ckeditor/KSACKEditor.vue";

import common from '../plugins/common'
import themecolor from '../plugins/themecolor'
import toast from '../plugins/toast'

const app = createApp(App)


app.use(router)
app.use(createPinia())
// app.use(ToastPlugin, {
//     position: 'bottom',
// });
app.use(common);
app.use(themecolor);
app.use(toast);

app.config.globalProperties.$user = useUserStore().user;

app.config.globalProperties.$regex = {
    id: /^(?=.*[0-9a-z])[0-9a-z]{6,20}$/, // 아이디 정규식
    pw: /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, // 비밀번호 정규식
    email: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i, // 이메일 정규식
    compNum: /^[0-9]{3}-[0-9]{2}-[0-9]{5}$/, // 사업자등록번호 정규식
    corpNum: /^[0-9]{6}-[0-9]{7}$/, // 법인등록번호 정규식
    phoneNum: /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/, // 핸드폰번호 정규식
}

app.config.globalProperties.$searchMonths = [
    { label:'최근 1개월', value: 1, },
    { label:'최근 2개월', value: 2, },
    { label:'최근 3개월', value: 3, },
    { label:'전체 기간', value: 0, },
];

app.config.globalProperties.$interests = [
    { label: '설계', value: '설계',},
    { label: '장비', value: '장비',},
    { label: '소재', value: '소재',},
    { label: '제조공정', value: '제조공정',},
    { label: '패키지', value: '패키지',},
    { label: '테스트', value: '테스트',},
    { label: 'SW', value: 'SW',},
    { label: '기타', value: '기타',},
];

app.config.globalProperties.$desiredJob = [
    { label: '회로/시스템 설계', value: '회로/시스템 설계',},
    { label: '장비 운영/유지보수/개선', value: '장비 운영/유지보수/개선',},
    { label: '장비/부품 개발', value: '장비/부품 개발',},
    { label: '소재 개발 및 평가', value: '소재 개발 및 평가',},
    { label: '공정 개발 및 평가', value: '공정 개발 및 평가',},
    { label: '패키지 개발', value: '패키지 개발',},
    { label: '제품 평가 및 분석', value: '제품 평가 및 분석',},
    { label: '반도체 SW 개발', value: '반도체 SW 개발',},
    { label: '기타', value: '기타',},
];
app.config.globalProperties.$corpState = [
    { label: '매칭중', value: '01',},
    { label: '제안취소', value: '02',},
    { label: '매칭완료', value: '03',},
    { label: '매칭중단', value: '04',},
    { label: '전형진행', value: '05',},
    { label: '전형중단', value: '06',},
    { label: '합격', value: '07',},
    { label: '불합격', value: '08',},
];

app.config.globalProperties.$userState = [
    { label: '매칭중', value: '01',},
    { label: '매칭완료', value: '03',},
    { label: '매칭중단', value: '04',},
    { label: '전형진행', value: '05',},
    { label: '전형중단', value: '06',},
    { label: '합격', value: '07',},
    { label: '불합격', value: '08',},
];

app.config.globalProperties.$surveyQType = [
    { label: '5점 척도', value: '04',},
    { label: '주관식', value: '02',},
    { label: '다중응답형', value: '03',},
];

app.config.globalProperties.$surveyQGb = [
    { label: '교육 전후 역량 변화', value: '01',},
    { label: '교육 만족도', value: '02',},
    { label: '현업 활용도', value: '03',},
    { label: '수요 조사', value: '04',},
    { label: '교육과정 안내 경로 선택', value: '05',},
];

app.config.globalProperties.$lectStateGb2 = [ // 149
    { label: '신청 완료', value: '01',},
    { label: '신청 심사(서류)', value: '02',},
    { label: '신청 반려(서류)', value: '03',},
    { label: '신청 심사(면접)', value: '04',},
    { label: '신청 반려(면접)', value: '05',},
    { label: '수강 대기(확정)', value: '06',},
    { label: '수강중', value: '07',},
    { label: '수강 종료', value: '08',},
    { label: '수강 신청 취소(사용자)', value: '09',},
    { label: '수강 취소(Admin)', value: '10',},
];

app.config.globalProperties.$evalStateGb2 = [ // 147
    { label: '서류 접수', value: '01',},
    { label: '서류 심사중', value: '02',},
    { label: '서류 탈락', value: '03',},
    { label: '서류 합격(면접 심사중)', value: '04',},
    { label: '면접 탈락', value: '05',},
    { label: '최종 합격(수강대기)', value: '06',},
    { label: '최종 합격(수강중)', value: '07',},
    { label: '최종 합격(수강 종료)', value: '08',},
    { label: '수강 신청 취소(사용자)', value: '09',},
    { label: '수강 취소(Admin)', value: '10',},
];

// globalCookiesConfig({
//     expireTimes: "7d",
    // path: "/",
    // domain: "",
    // secure: true,
    // sameSite: "None",
// });

app
    .component('KSACheckBox', KSACheckBox)
    .component('KSACheckBoxGroup', KSACheckBoxGroup)
    .component('KSADatepicker', KSADatepicker)
    .component('KSADatepickerTerms', KSADatepickerTerms)
    .component('KSAFormEmail', KSAFormEmail)
    .component('KSAImage', KSAImage)
    .component('KSARadioButton', KSARadioButton)
    .component('KSASearchInputBox', KSASearchInputBox)
    .component('KSATab', KSATab)
    .component('KSATextArea', KSATextArea)
    .component('AlertModal', AlertModal)
    .component('ConfirmModal', ConfirmModal)
    .component('Paginate', Paginate)
    .component('KSASelect', KSASelect)
    .component('KSASelectEmail', KSASelectEmail)
    .component('KSASelectResidenceArea', KSASelectResidenceArea)
    .component('KSATextButton', KSATextButton)
    .component('KSAPrimaryButton', KSAPrimaryButton)
    .component('KSASecondaryButton', KSASecondaryButton)
    .component('KSADropdownButton', KSADropdownButton)
    .component('KSAInput', KSAInput)
    .component('KSAInputCompNum', KSAInputCompNum)
    .component('KSAInputPhoneNum', KSAInputPhoneNum)
    .component('KSAInputSchoolSelect', KSAInputSchoolSelect)
    .component('KSAInputTeacherSelect', KSAInputTeacherSelect)
    .component('KSAInputVerifyCheck', KSAInputVerifyCheck)
    .component('KSAInputDateYYYYMM', KSAInputDateYYYYMM)
    .component('KSAInputScore', KSAInputScore)
    .component('KSACKEditor', KSACKEditor)

app.mount('#app')
