<script setup>
    import { ref, onMounted, computed, getCurrentInstance } from 'vue'
    import { useRouter } from 'vue-router'
    import SNB from '../../../../../layouts/nav/SNB.vue'
    import mypageProposalService from "../../../services/mypage/mypageProposalService";
    import { usePageLoadingStore } from "../../../../../stores/pageLoading";
    import MypageProposalContentModal from "./MypageProposalContentModal.vue";
    import _ from 'lodash'
    import KSATextButton from "../../../../../components/ui-components/button/KSATextButton.vue";
    import MatchingProposalModal from "../../../components/modal/matching/MatchingProposalModal.vue";
    import mypageMatchingService from "../../../services/mypage/mypageMatchingService";
    import {useMatchingUpdateStore} from "../../../stores/matching/matchingUpdateStore";

    const globals = getCurrentInstance().appContext.config.globalProperties;

    const data_interests = ref(globals.$interests)

    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const matchingUpdateStore = useMatchingUpdateStore()

    const contentModal = ref(null)

    const interestGbListRef = ref(null)
    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = 10;
    const totalPages = ref(1);
    const totalCount = ref(0);
    const searchOpen = ref(true);
    const modal = ref(null);
    const modalContent = ref([]);
    const matchingProposalModal = ref(null);

    const snbKey = ref(0);

    let letUserGb = ''
    let corpMasterYn = ''

    const shouldRender = ref(true);
    const getUserInfo = async () => {
      const userInStorage = sessionStorage.getItem('user');
      if(userInStorage) {
        const { userGb, userInfo } = JSON.parse(userInStorage)
        letUserGb = userGb
        corpMasterYn = userInfo.corpMasterYn;
      }
    }

    const isModal = localStorage.getItem('isModalVisible')

    const _searchInput = ref({
        searchInterests: ''
    });

    onMounted( async () => {
      retrieveList();

      await getUserInfo()
      if(letUserGb === 'COMPANY' && corpMasterYn === 'Y') {
        if(isModal === 'true'){
          matchingProposalModal.value.show();
        }
      } else {
        shouldRender.value = false;
        modalContent.value = ["재직자/기업 마스터 회원만 제안 가능합니다."];
        await modal.value.show();
        router.go(-2);
      }
    })

    const isInitView = computed(()=> {
        return currentPage.value === 1;
    });

    const retrieveList = () => {

        const interests = _.map(_searchInput.value.searchInterests).join(',');

        let paramObj = {
          pageNumber: currentPage.value,
          size: pageSize,
          searchInterests: interests
        }
        // console.log(paramObj);
        loadingStore.loading = true;
        mypageProposalService.retrieveProposalList(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = result.content;
                    // 매칭 상태 값 업데이트
                    retrieveMatchingUpdate();
                }
            }).catch( (error) =>  {
                loadingStore.loading = false;
                console.log(error);
            });
    }

    const onSelectedPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }

    const clearSearchInput = () => {
        _searchInput.value.searchInterests = ['설계','장비','소재','제조공정','패키지','테스트','SW','기타'];
        interestGbListRef.value.initOptions();
    }

    const showContent = async (propId) => {
        contentModal.value.retrieveProposalContent(propId);
        await contentModal.value.show();
    };

    // 매칭 상태값 업데이트 (matchingUpdateStore 업데이트)
    const retrieveMatchingUpdate = async () => {
      await mypageMatchingService.retrieveMatchingUpdate()
          .then( async (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              matchingUpdateStore.matchingUpdate = result;
              await matchingUpdateStore.setMatchingUpdate( result );
              matchingUpdateStore.value = await useMatchingUpdateStore();
              snbKey.value += 1;
            }
          }).catch( (error) => {
            loadingStore.loading = false;
            console.log(error);
          });
    }


</script>

<template>
    <div v-if="shouldRender" class="container">
        <div class="inner">
            <div class="flex-wrap admin">
                <SNB :key="snbKey" class="snb" />
                <div class="content">
                    <div class="sub-title">
                        <h3>채용 제안 현황</h3>
                    </div>
                  <div class="process-wrap">
                    <h4>채용 제안 절차</h4>
                    <div class="process-layout">
                      <div class="process-steps-scroll">
                        <div class="process-steps">
                          <div class="step"><div>채용 제안서<br>작성</div></div>
                          <div class="step-arrow"><div></div></div>
                          <div class="step"><div>인재 정보<br>검색/제안</div></div>
                          <div class="step-arrow"><div></div></div>
                          <div class="step"><div><span class="tip"></span>채용 제안<br>확인/수락</div></div>
                          <div class="step-arrow"><div></div></div>
                          <div class="step"><div>매칭 완료</div></div>
                          <div class="step-arrow active"><div></div></div>
                          <div class="step large active"><div>기업별<br>채용  전형 진행</div></div>
                        </div>
                      </div>
                      <div class="button-with-info">
                        <p>채용 제안을 통해 <br><strong><em>맞춤형 인재정보를 확인</em></strong>해보세요.</p>
                        <div class="button-with-info-cta" @click="router.push({name: 'MypageProposalCreateView'})">채용 제안서 작성하기<span class="icon-button-right"></span></div>
                      </div>
                    </div>
                  </div>
                    <div class="filter-wrap" :class="{'open': searchOpen}">
                        <p @click="searchOpen = !searchOpen">검색</p>
                        <div class="filter">
                            <dl>
                                <dt>관심 카테고리</dt>
                                <dd>
                                    <KSACheckBoxGroup
                                        ref="interestGbListRef"
                                        className="mr-md"
                                        name="searchInterests"
                                        v-model="_searchInput.searchInterests"
                                        :options="data_interests" />
                                </dd>
                            </dl>
                            <div class="btn-wrap">
                                <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                                <KSAPrimaryButton text="검색" @click="retrieveList" />
                            </div>
                        </div>
                    </div>
                    <div class="list-top">
                        <p>제안 <span>{{ $number(totalCount) }}</span>건</p>
                    </div>
                    <div class="table-form board-mobile-vertical">
                        <table>
                            <colgroup>
                                <col />
                                <col />
                              <col />
                              <col />
                            </colgroup>
                            <tr>
                                <th>제목</th>
                                <th>제안 인원</th>
                                <th>관심 카테고리</th>
                                <th>전송일</th>
                            </tr>
                            <tr v-for="(proposal) in list">
                                <td v-if="proposal.title === ''" data-title="채용 제안서 : "><KSATextButton class="clickable proposal" text="채용제안서 보기" @click="showContent(proposal.propId)"/></td>
                                <td v-else data-title="채용 제안서 : "><KSATextButton class="clickable proposal" :text=proposal.title @click="showContent(proposal.propId)"/></td>
                                <td data-title="제안 인재 : "><span v-if="proposal.stateCnt > 0" class="red-dot"></span><span class="clickable user" @click="router.push({name: 'MypageProposalUserListView', params:{ id: proposal.propId}});">{{ proposal.studentCnt }}명(제안 인재 보기)</span></td>
                                <td data-title="관심 카테고리 : ">{{ proposal.interests }}</td>
                                <td data-title="전송일 : ">{{ $yyyymmddOrHHmm(proposal.createDate)}}</td>
                            </tr>
                        </table>
                        <div class="result-none" v-if="list.length === 0">
                            전송한 채용 제안서가 없습니다.
                        </div>
                    </div>
                    <Paginate
                        v-if="list.length > 0"
                        v-model="currentPage"
                        :page-count="totalPages"
                        :click-handler="onSelectedPage"
                    />
                </div>
            </div>
        </div>
    </div>
    <div v-else class="white-background"></div>
    <MatchingProposalModal v-if="isModal === 'true'" ref="matchingProposalModal" />
    <MypageProposalContentModal ref="contentModal"/>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .clickable {
        cursor: pointer;
        color: #3843AF;
        &.user {
            &:hover {
                font-weight: 700;
                // text-decoration: underline;
            }
        }
        &.proposal {
            color: #3C72FF;
            float: left;
            &:hover {
                font-weight: 700;
                text-decoration: underline;
            }
        }

    }

    .substr {
      display:block;
      overflow: hidden;
      white-space: nowrap;
      max-width: 300px;
      text-overflow: ellipsis;
    }

    .white-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
    }

</style>