import http from '../../../../api/http'

function retrieveList(paramObj) { // 강의 목록 조회
    return http.post('/lecture/a/retrieveAllPaging', {
        lectGb: paramObj.lectGb || "00",
        lectOpenYn: paramObj.lectOpenYn || "A",
        repreLectYn: paramObj.repreLectYn || "N",
        lectName: paramObj.lectName || "",
        searchDurationGb: paramObj.searchDurationGb || "C",
        startYmd: paramObj.startYmd || "",
        endYmd: paramObj.endYmd || "",
        pageNumber: paramObj.pageNo || 1,
        size: paramObj.size || 30
    })
}

function retrieveLectureDetail(lectId) { // 강의 상세 조회
    return http.get(`/lecture/a/retrieveByLectId?lectId=${lectId}`)
}

function createLecture(paramObj) { // 강의 생성
    return http.post('/lecture/a/create', paramObj)
}


function updateLecture(paramObj) { // 강의 수정
    return http.post('/lecture/a/update', paramObj)
}

function deleteLecture(lectId) { // 강의 삭제
    return http.post('/lecture/a/delete', {
        lectId,
    })
}

function updateLectOpenYn(lectIds, lectOpenYn) { // 강의 오픈 상태 수정
    return http.post(`/lecture/a/updateLectOpenYn${lectOpenYn}`, lectIds)
}


function updateIndex({ lectId, lectureIndexList }) { // 차시 수정
    return http.post('/lecture/a/updateIndex', {
        lectId,
        lectureIndexList,
    })
}

function deleteIndex(lectId, lecIdx) { // 차시 삭제
    return http.post('/lecture/a/deleteIndex', {
        lectId,
        lecIdx,
    })
}

function retrieveAllIndexByLectId(lectId) { // 차시 목록 조회
    return http.get(`/lecture/a/retrieveAllIndexByLectId?lectId=${lectId}`)
}

function retrieveAllUserLectureByLectId(paramObj) { // 강의신청 목록 조회
    return http.post('/lecture/a/retrieveAllUserLectureByLectId', {
        pageNumber: paramObj.pageNo || 0,
        size: paramObj.size || 30,
        lectId: paramObj.lectId,
        lectStateGbList: paramObj.lectStateGbList, // ["string"],
        evalStateGbList: paramObj.evalStateGbList, // ["string"],
        userGbList: paramObj.userGbList, // ["STUDENT"],
        progRate: paramObj.progRate, // 0,
        finYn: paramObj.finYn, // ["string"],
        searchType: paramObj.searchType, // "string",
        searchInput: paramObj.searchInput, // "string",
        searchDurationGb: paramObj.searchDurationGb, // "R",
        startYmd: paramObj.startYmd, // "string",
        endYmd: paramObj.endYmd, // "string"
    })
}

function retrieveAllUserLectureSelByLectId(paramObj) { // 수강생 목록 조회
    return http.post('/lecture/a/retrieveAllUserLectureSelByLectId', {
        pageNumber: paramObj.pageNo || 0,
        size: paramObj.size || 30,
        lectId: paramObj.lectId,
        selYnList: paramObj.selYnList, // ["string"],
        userGbList: paramObj.userGbList, // ["STUDENT"],
        progRate: paramObj.progRate, // 0,
        finYnList: paramObj.finYnList, // ["Y","N", "D"]
        searchType: paramObj.searchType, // "string",
        searchInput: paramObj.searchInput, // "string",
        searchDurationGb: paramObj.searchDurationGb, // "R",
        startYmd: paramObj.startYmd, // "string",
        endYmd: paramObj.endYmd, // "string"
    })
}

function updateUserLectureStateGbByLectId(paramObj) { // 강의신청 수락/취소
    return http.post('/lecture/a/updateUserLectureStateGbByLectId', {
        lectId: paramObj.lectId, // 0,
        userLectureIds: paramObj.userLectureIds, //,
        lectStateGb: paramObj.lectStateGb, //
    })
}

function updateUserLectureEvalStateGbByLectId(paramObj) { // 강의신청 > 오프라인 심사상태 저장
    return http.post('/lecture/a/updateUserLectureEvalStateGbByLectId', {
        lectId: paramObj.lectId, // 0,
        userLectureIds: paramObj.userLectureIds, //,
        evalStateGb: paramObj.evalStateGb, //
    })
}

function updateUserLectureFinYnByLectId(paramObj) { // 수강생 수료
    return http.post('/lecture/a/updateUserLectureFinYnByLectId', {
        lectId: paramObj.lectId, // 0,
        userIds: paramObj.userIds, // [0],
        finYn: paramObj.finYn, // "s"
    })
}

function updateRepreLectYnY(lectIds) { // 대표강의로 설정
    return http.post('/lecture/a/updateRepreLectYnY', lectIds || [])
}



function downloadLectureList(paramObj) { // 강의 목록 다운로드
    return http.post('/lecture/a/downloadLectureList', {
        lectGb: paramObj.lectGb || "00",
        lectOpenYn: paramObj.lectOpenYn || "A",
        repreLectYn: paramObj.repreLectYn || "N",
        lectName: paramObj.lectName || "",
        searchDurationGb: paramObj.searchDurationGb || "C",
        startYmd: paramObj.startYmd || "",
        endYmd: paramObj.endYmd || "",
        pageNumber: paramObj.pageNo || 1,
        size: paramObj.size || 30
    }, {
        responseType: 'blob',
    })
}

function downloadUserLectureList(paramObj) { // 강의신청 > 회원정보/이력서 다운로드
    return http.post('/lecture/a/downloadUserLectureList', {
        pageNumber: paramObj.pageNo || 0,
        size: paramObj.size || 30,
        lectId: paramObj.lectId,
        lectStateGbList: paramObj.lectStateGbList, // ["string"],
        evalStateGbList: paramObj.evalStateGbList, // ["string"],
        userGbList: paramObj.userGbList, // ["STUDENT"],
        progRate: paramObj.progRate, // 0,
        finYn: paramObj.finYn, // ["string"],
        searchType: paramObj.searchType, // "string",
        searchInput: paramObj.searchInput, // "string",
        searchDurationGb: paramObj.searchDurationGb, // "R",
        startYmd: paramObj.startYmd, // "string",
        endYmd: paramObj.endYmd, // "string"
    }, {
        responseType: 'blob',
    })
}

function sendMessageOfEvalStateGb(paramObj) { // 합격 메시지 전송
    return http.post('/lecture/a/sendMessageOfEvalStateGb', {
        lectId: paramObj.lectId, // 0,
        userLectureIds: paramObj.userLectureIds, //,
        evalStateGb: paramObj.evalStateGb, //
    })
}

export default {
    retrieveList,
    retrieveLectureDetail,
    createLecture,
    updateLecture,
    deleteLecture,
    updateLectOpenYn,
    updateIndex,
    deleteIndex,
    retrieveAllIndexByLectId,
    retrieveAllUserLectureByLectId,
    retrieveAllUserLectureSelByLectId,
    updateUserLectureStateGbByLectId,
    updateUserLectureEvalStateGbByLectId,
    updateUserLectureFinYnByLectId,
    updateRepreLectYnY,
    downloadLectureList,
    downloadUserLectureList,
    sendMessageOfEvalStateGb
}