    <script setup>
    import { ref, watch, onMounted,  computed, defineExpose, getCurrentInstance } from "vue";
    import { RouterLink } from 'vue-router'
    import Modal from "../../../../../components/ui-components/modal/Modal.vue";
    import mypageUserResumeService from "../../../services/mypage/mypageUserResumeService";
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import _ from 'lodash'
    import MyPageResumeModal from "../mypage/MyPageResumeModal.vue";
    const loadingStore = usePageLoadingStore()
    const { $pageListBoardNo, $toast } = getCurrentInstance().appContext.config.globalProperties;

    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = 5;
    const totalPages = ref(1);
    const totalCount = ref(0);

    const baseModal = ref(null);
    const resolvePromise = ref(null);

    const alertModal = ref(null)
    const resumeModal = ref(null)
    const modalContent = ref([])

    onMounted(()=>{
        setTimeout(()=>{
            baseModal.value.isAdminWrap = true;
        }, 500);

        retrieveList();
    })

    const input = ref({    // 과제 생성 정보
        lectId: 0,     // 강의아이디
        title: "",     // 제목
        fullMarks: 0,  // 배점
        attachList: [  // 첨부파일
            {
                attachSeq: 0,
                fileName: "",
                fileSize: "",
                fileDir: "",
                deleteYn: "N"
            }
        ],
    })

    // 이력서 리스트 조회
    const retrieveList = () => {

        let paramObj = {
            pageNumber: currentPage.value,
            size: pageSize,
            userId: 11
        }

        loadingStore.loading = true;
        mypageUserResumeService.retrieveLectureResumeList(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize, currentPage.value);
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    // 수강신청 이력서 보기
    const onClickUserLectureResumeDetail = (userId, lectId, regDt) => {
        resumeModal.value.init(true);
        resumeModal.value.retrieveUserLectureRecordDetail(userId, lectId, regDt);
        resumeModal.value.show();
    }

    // 내 이력서 보기
    const onClickMypageResumeDetail = () => {
        resumeModal.value.init(true);
        resumeModal.value.retrieveRecordDetail();
        resumeModal.value.show();
    }

    const show = () => {
        baseModal.value.open();
        return new Promise((resolve, _) => {
            resolvePromise.value = resolve;
        });
    };

    const confirm = () => {
        baseModal.value.close();
        resolvePromise.value(true);
    };

    const cancel = () => {
        baseModal.value.close();
        resolvePromise.value(false);
    };

    const init = () => {
        // 기본값 저장
        //input.value.lectId = lectId;
    }

    defineExpose({
        show,
        init
    })
</script>

<template>
    <Modal ref="baseModal">
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h3>이력서 선택</h3>
                <p>
                    수강신청 시 작성했던 이력서 중에서 기업에게 전송할 이력서를 선택하세요<br>
                    마이페이지 ‘이력 관리’의 이력사항을 불러오려면 내 이력서 보기를 눌러주세요.
                </p>
            </div>
            <div class="modal-body">
                <div class="list-top">
                    <p>총 <span>{{ $number(totalCount)}}</span>개</p>
                </div>
                <div class="table-form">
                    <table class="vertical">
                        <colgroup>
                            <col />
                            <col width="150px" />
                            <col width="150px" />
                        </colgroup>
                        <tr>
                            <th class="text-center">강의명</th>
                            <th class="text-center">수강신청일</th>
                            <th class="text-center">이력서 확인</th>
                        </tr>
                        <tr v-for="resume in list">
                            <td class="text-left">{{ resume.lectName }}</td>
                            <td class="text-center">{{ $yyyymmdd(resume.regDt) }}</td>
                            <td class="link text-center">
                                <KSATextButton text="이력서 확인" @click="onClickUserLectureResumeDetail(resume.userId, resume.lectId, resume.regDt)" />
                            </td>
                        </tr>
                        <tr v-if="list.length === 0">
                            <td class="text-center" colspan="3">
                                수강 신청한 강의가 없습니다.
                            </td>
                        </tr>
                        <tr>
                            <th class="text-left" colspan="2" style="font-weight: 400;">‘이력 관리’의 이력사항 불러오기</th>
                            <th class="link text-center" style="font-weight: 400;">
                                <KSATextButton text="내 이력서 보기" @click="onClickMypageResumeDetail()" />
                            </th>
                        </tr>
                    </table>
                </div>
                <Paginate
                    v-if="list.length > 0"
                    v-model="currentPage"
                    :page-count="totalPages"
                    :click-handler="onSelectPage"
                />
            </div>
            <div class="modal-footer mt-xs" style="justify-content: center;">
                <KSASecondaryButton class="gray_button" text="취소" @click="cancel" />
            </div>
        </div>
    </Modal>
    <MyPageResumeModal ref="resumeModal"/>
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .modal-inner {
        width: 598px !important;
    }

    .list-top {
        p {
            margin-right: 0;
            padding-left: 2px;
            &:first-of-type {
                padding-left: 0;
            }
        }
    }

    .grid {
        &.half {
            div { width: 312px; }
        }
    }

    .modal-title p span {
        color: $red_color;
        font-weight: 700;
    }

    .table-form table .grid span { margin: 0 0 0 10px; }

</style>