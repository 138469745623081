<script setup>
    import { ref, onMounted, computed, getCurrentInstance, inject } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import SNB from '../../../../../layouts/nav/SNB.vue'
    import mypageProposalService from "../../../services/mypage/mypageProposalService";
    import { usePageLoadingStore } from "../../../../../stores/pageLoading";
    import { useMypageProposalStore } from "../../../stores/mypage/mypageProposalStore";
    import MypageProposalContentModal from "./MypageProposalContentModal.vue";
    import MyPageResumeModal from "../../../components/modal/mypage/MyPageResumeModal.vue";
    import _ from 'lodash'
    import KSATextButton from "../../../../../components/ui-components/button/KSATextButton.vue";
    import KSASecondaryButton from "../../../../../components/ui-components/button/KSASecondaryButton.vue";
    import mypageMatchingService from "../../../services/mypage/mypageMatchingService";
    import {useMatchingUpdateStore} from "../../../stores/matching/matchingUpdateStore";

    const globals = getCurrentInstance().appContext.config.globalProperties;

    const { $toast, $gb, $gbNameKR, $corpState } = globals;

    const corpState = ref($corpState)
    const getSystemCodeValues = inject('systemCodeValues');

    const router = useRouter()
    const route = useRoute()
    const loadingStore = usePageLoadingStore()
    const proposalStore = useMypageProposalStore()
    const matchingUpdateStore = useMatchingUpdateStore()

    const proposalDetail = ref({
        propId: route.params.id,
    })

    const snbKey = ref(0);

    // 제안서보기모달
    const contentModal = ref(null)

    // 상태변경 모달
    const modal = ref(null);
    const confirmModal = ref(null);
    const modalContent = ref([]);
    const okButtonText = ref("확인");

    // 이력서 모달
    const userMatchingResumeModal = ref(null);

    const propStateGroupRef = ref(null)
    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = 10;
    const totalPages = ref(1);
    const totalCount = ref(0);
    const searchOpen = ref(true)

    const _searchInput = ref({
        searchPropStates: [],
    });

    onMounted(() => {
        retrieveList();
    })

    const isInitView = computed(()=> {
        return currentPage.value === 1;
    });

    const retrieveList = async () => {

        const propStates = _.map(_searchInput.value.searchPropStates).join(',');

        let paramObj = {
            pageNumber: currentPage.value,
            size: pageSize,
            propId: proposalDetail.value.propId,
            searchPropStateGbs: propStates
        }
        // console.log(paramObj);
        loadingStore.loading = true;
        await getSystemCodeValues();
        mypageProposalService.retrieveProposalUserList(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = result.content;
                    // console.log(list.value)
                    if(isInitView.value) {
                        proposalStore.setProposalList(list.value);
                    }
                }
            }).catch( (error) =>  {
                loadingStore.loading = false;
                console.log(error);
            });
    };

    const onSelectedPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    };

    const clearSearchInput = () => {
        _searchInput.value.searchPropStates = ['01', '02', '03', '04', '05', '06', '07', '08'];

        propStateGroupRef.value.initOptions();
    };

    // 매칭 상태값 업데이트 (matchingUpdateStore 업데이트)
    const retrieveMatchingUpdate = async () => {
      await mypageMatchingService.retrieveMatchingUpdate()
          .then( async (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              matchingUpdateStore.matchingUpdate = result;
              await matchingUpdateStore.setMatchingUpdate( result );
              matchingUpdateStore.value = await useMatchingUpdateStore();
              snbKey.value += 1;
            }
          }).catch( (error) => {
            loadingStore.loading = false;
            console.log(error);
          });
    }

    // 채용 상태 변경
    const changeState = async (userId, stateGb) => {
        if(stateGb === '02') {
          modalContent.value = ["제안서 전송이 취소(철회)됩니다.", "상태를 ‘제안취소’로 변경할까요?"];
        } else if(stateGb === '05') {
          modalContent.value = ["상태를 ‘전형(실기/면접) 진행’으로 변경할까요?"];
        } else if(stateGb === '06') {
          modalContent.value = ["상태를 ‘전형(실기/면접) 중단’으로 변경할까요?"];
        } else if(stateGb === '07') {
          modalContent.value = ["상태를 ‘합격’ 으로 변경할까요? "];
        } else if(stateGb === '08') {
          modalContent.value = ["상태를 ‘불합격’ 으로 변경할까요?"];
        }
        okButtonText.value = "변경";

        const ok = await confirmModal.value.show();
        if(!ok) {
            return false;
        } else {
            let paramObj = {
                propId: proposalDetail.value.propId,
                userId: userId,
                propStateGb: stateGb
            };
            //console.log(paramObj);

            loadingStore.loading = true;
            mypageProposalService.changeProposalUserState(paramObj)
                .then( (res) => {
                  loadingStore.loading = false;
                  if(res.data.code.toLowerCase() === 'success') {
                    // const result = res.data.result;
                    modalContent.value = ["변경하였습니다."];
                    modal.value.show();
                    retrieveList();
                    // 매칭 상태 값 업데이트
                    retrieveMatchingUpdate();
                  } else {
                    // console.log(res);
                  }
                }).catch( (res) => {
                loadingStore.loading = false;
                console.log(res);
            });
        }

    };

    const showContent = async () => {
        contentModal.value.retrieveProposalContent(proposalDetail.value.propId);
        await contentModal.value.show();
    };

    // 기본 이력서 보기
    const onClickUserMatchingResumeDetail = (userId, propId) => {
        userMatchingResumeModal.value.init(false);
        userMatchingResumeModal.value.retrieveMatchingRecordDetail(userId, propId);
        userMatchingResumeModal.value.show();
    }

    // 채용제안현황 - 제안인재 보기 - 제안인재 정보/이력서 다운로드 버튼 이벤트
    const onClickProposalUserDownload = async () => {
      modalContent.value = ["제안인재 회원정보와 이력서를 이메일로 발송할까요?"];
      okButtonText.value = "메일발송";
      const ok = await confirmModal.value.show();
      if(ok){
        downloadProposalUser();
      }
    }

    // 제안인재 정보/이력서 다운로드
    const downloadProposalUser = () => {
      const propStates = _.map(_searchInput.value.searchPropStates).join(',');
      let paramObj = {
        pageNumber: currentPage.value,
        size: pageSize,
        propId: proposalDetail.value.propId,
        searchPropStateGbs: propStates
      }
      $toast('', `이메일 발송에 몇 분이 소요될 수도 있습니다.`);
      mypageProposalService.downloadProposalUserList(paramObj)
          .then( async (res) => {
            $toast('', `이메일로 발송되었습니다.`);
            //console.log( res );
          }).catch( (err) =>  {
            //console.log( res );
            let errorMessage = '';
            if (err.response && err.response.data) {
              errorMessage = err.response.data.result && err.response.data.result.message
                  ? err.response.data.result.message
                  : '이메일발송이 실패하였습니다.';
            } else {
              errorMessage = '이메일발송이 실패하였습니다.'
            }
            $toast('error', errorMessage);
      });
    }

</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="flex-wrap admin">
                <SNB :key="snbKey" class="snb" />
                <div class="content">
                    <KSATextButton text="채용 제안 현황" @click="router.push({name:'MypageProposalListView'})" style="color: blue;margin-bottom: 20px;"/>
                    <div class="sub-title">
                        <h3>제안 인재</h3>
                    </div>
                    <div class="filter-wrap" :class="{'open': searchOpen}">
                        <p @click="searchOpen = !searchOpen">검색</p>
                        <div class="filter">
                            <dl>
                                <dt>상태</dt>
                                <dd>
                                    <KSACheckBoxGroup
                                        ref="propStateGroupRef"
                                        className="mr-md"
                                        name="searchPropStates"
                                        v-model="_searchInput.searchPropStates"
                                        :options="corpState" />
                                </dd>
                            </dl>
                            <div class="btn-wrap">
                                <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                                <KSAPrimaryButton text="검색" @click="retrieveList" />
                            </div>
                        </div>
                    </div>
                    <div class="list-top">
                        <p>총 <span>{{ $number(totalCount) }}</span>명의 인재에게 제안서를 보냈습니다.</p>
                        <div class="search-box">
                            <KSAPrimaryButton v-if="$number(totalCount) > 0" text="제안인재 정보/이력서 다운로드" @click="onClickProposalUserDownload" />
                            <KSAPrimaryButton style="margin-left: 10px;" text="채용 제안서 보기" @click="showContent()" />
                        </div>
                    </div>
                    <div class="table-form board-mobile-vertical">
                        <table>
                            <tr>
                                <th>이름</th>
                                <th>관심 카테고리</th>
                                <th>이력서보기</th>
                                <th>상태</th>
                                <th>상태 변경</th>
                            </tr>
                            <tr v-for="(proposalUser) in list">
                                <td>{{ proposalUser.userName }}</td>
                                <td>{{ proposalUser.interests }}</td>
                                <td><KSATextButton class="clickable resume" text="이력서 보기" @click="onClickUserMatchingResumeDetail(proposalUser.userId, proposalUser.propId)"/></td>
                                <td>
                                  <span>
                                    <span>
                                      <span v-if="proposalUser.propStateGb === '03' || proposalUser.propStateGb === '05'" class="red-dot"></span>
                                      {{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB', proposalUser.propStateGb) }}
                                    </span>
                                    <br>
                                    <small style="color: #86889A">{{ $gbNameKR('MATCHING_JOB_STATE_CORP_GB2', proposalUser.propStateGb) }}</small>
                                  </span>
                                </td>

                                <!-- 01 매칭중 02 제안취소 03 매칭완료 04 매칭중단 05 전형진행중 06 전형중단 07 합격 08 불합격 -->
                                <td v-if="proposalUser.propStateGb === '01'" data-title="상태 변경 : ">
                                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '02')" @click="changeState(proposalUser.userId, '02')" class="gray_button md_btn"/>
                                </td>
                                <td v-else-if="proposalUser.propStateGb === '03'" data-title="상태 변경 : ">
                                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '05')" @click="changeState(proposalUser.userId, '05')" class="green_button md_btn"/>
                                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '06')" @click="changeState(proposalUser.userId, '06')" class="orange_button md_btn"/>
                                </td>
                                <td v-else-if="proposalUser.propStateGb === '05'" data-title="상태 변경 : ">
                                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '07')" @click="changeState(proposalUser.userId, '07')" class="blue_button md_btn"/>
                                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '08')" @click="changeState(proposalUser.userId, '08')" class="red_button md_btn"/>
                                </td>
                                <td v-else-if="proposalUser.propStateGb === '06'" data-title="상태 변경 : ">
                                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '05')" @click="changeState(proposalUser.userId, '05')" class="green_button md_btn"/>
                                </td>
                                <td v-else-if="proposalUser.propStateGb === '07'" data-title="상태 변경 : ">
                                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '08')" @click="changeState(proposalUser.userId, '08')" class="red_button md_btn"/>
                                </td>
                                <td v-else-if="proposalUser.propStateGb === '08'" data-title="상태 변경 : ">
                                  <KSASecondaryButton :text="$gbNameKR('MATCHING_JOB_STATE_CORP_GB', '07')" @click="changeState(proposalUser.userId, '07')" class="blue_button md_btn"/>
                                </td>
                                <td v-else data-title="상태 변경 : ">
                                  -
                                </td>
                            </tr>
                        </table>
                        <div class="result-none" v-if="list.length === 0">
                            전송한 채용 인재가 없습니다.
                        </div>
                    </div>
                    <Paginate
                        v-if="list.length > 0"
                        v-model="currentPage"
                        :page-count="totalPages"
                        :click-handler="onSelectedPage"
                    />
                </div>
            </div>
        </div>
    </div>
    <MypageProposalContentModal ref="contentModal"/>
    <MyPageResumeModal ref="userMatchingResumeModal"/>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText"/>
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .clickable {
        cursor: pointer;
        &.resume {
            color: #3C72FF;
            &:hover {
                font-weight: 700;
                text-decoration: underline;
            }
        }
    }

    .disabledcolor {
        color: #86889A;
        .pad {
            margin-left: 5px;
        }
    }

    .matching-fin {
        line-height: 28px;
        padding: 6px 9px 6px 9px;
        display: inline;
        border-radius: 5px;
        border: 1px solid #3843AF;
        color: #3843AF;
        cursor: pointer;
        &:hover {
            background-color: #E4E5EF;
        }
        &.disabled {
            border: 0px solid #86889A;
            color: #86889A;
            &:hover {
                cursor: default;
                background-color: #F8F8FA;
            }
        }

    }
    .matching-cancel {
        margin-left: 5px;
        line-height: 28px;
        padding: 6px 9px 6px 9px;
        display: inline;
        border-radius: 5px;
        border: 1px solid #E71414;
        color: #E71414;
        cursor: pointer;
        &:hover {
            background-color: #F7E0E0;
        }
        &.disabled {
            border: 0px solid #E71414;
            color: #86889A;
            &:hover {
                cursor: default;
                background-color: #F8F8FA;
            }
        }
    }
</style>