<script setup>
    import { ref, computed, onMounted, getCurrentInstance } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import SNB from '../../../../../layouts/nav/SNB.vue'
    import {usePageLoadingStore} from "../../../../../stores/pageLoading";
    import {useUserStore} from "../../../stores/user";
    import {useSasTokenStore} from "../../../../../stores/sasTokenStore";
    import {useMyPageLectDetailStore} from "../../../stores/mypage/mypageLectDetailStore";
    import _ from "lodash";
    import LecturePlayerModal from "../../../components/modal/mypage/LecturePlayerModal.vue";
    import LectureHomeworkCreateModal from "../../../components/modal/mypage/LectureHomeworkCreateModal.vue";
    import LectureHomeworkMarkModal from "../../../components/modal/mypage/LectureHomeworkMarkModal.vue";
    import lectureUserService from "../../../services/lecture/lectureUserService";
    import lectureHomeworkService from "../../../../admin/services/lecture/lectureHomeworkService";
    import lectureQnaService from "../../../../admin/services/lecture/lectureQnaService";
    import lectureNoticeService from "../../../../admin/services/lecture/lectureNoticeService";
    import fileService from "../../../../../services/fileService";
    import KSAVideo from "../../../../../components/ui-components/video/KSAVideo.vue";
    import { VideoPlayer } from '@videojs-player/vue'
    import 'video.js/dist/video-js.css'

    const route = useRoute()
    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const sasTkStore = useSasTokenStore()
    const lectureStore = useMyPageLectDetailStore()
    const { isAuthenticated } = useUserStore()
    const { $pageListBoardNo } = getCurrentInstance().appContext.config.globalProperties;

    const LECTURE = 'LECTURE';
    const QNA_DETAIL = 'QNA_DETAIL';
    const NOTICE_CREATE = 'NOTICE_CREATE';
    const NOTICE_DETAIL = 'NOTICE_DETAIL';
    const NOTICE_UPDATE = 'NOTICE_UPDATE';
    const MAX_SIZE = 200;

    const showVideo = ref(false)

    onMounted(()=>{
        const { id } = route.params;
        if(!id) {
            router.push({name: 'MyPageMyClass'})
        } else {
            loadingStore.loading = true;
            const p1 = retrieveLectureDetail(id)
            const p2 = retrieveLectureAllIndex(id)
            Promise.all([p1, p2])
                .then( async (res) => {
                    loadingStore.loading = false;
                    // console.log(res[0])
                    // console.log(res[1])
                    if(res[0].data.code.toLowerCase() === 'success') {
                        const result = res[0].data.result;
                        lectDetail.value = result;
                    } else {
                        console.log(res[0].response.data.msg);
                        modalContent.value = [res[0].response.data.msg];
                        const mod = await modal.value.show();
                        if( mod ) {
                          router.go(-1);
                        }
                    }
                    if(res[1].data.code.toLowerCase() === 'success') {
                        const result = res[1].data.result;
                        lectIndexList.value = result;

                        if(lectIndexList.value.length > 0) {
                            const teacherNames = _.uniq(_.map(lectIndexList.value, (lectIndex) => {
                                if(lectIndex.teacher && lectIndex.teacher.userName) {
                                    return lectIndex.teacher.userName;
                                } else {
                                    return false;
                                }
                            }));

                            if(teacherNames.length > 0) {
                                lectDetail.value.teacherNameText = teacherNames[0];
                                if(teacherNames.length > 1) {
                                    lectDetail.value.teacherNameText += ` 외 ${teacherNames.length-1}명`;
                                }
                            }

                        }
                    }
                })
                .catch( async (err) => {
                    loadingStore.loading = false;
                    if (err.response && err.response.data && err.response.data.msg) {
                      console.log(err.response.data.msg);
                      modalContent.value = [err.response.data.msg];
                      const mod = await modal.value.show();
                      if( mod ) {
                        router.go(-1);
                      }
                    } else {
                      console.log(err);
                    }
                })
        }
    })

    const viewMode = ref(LECTURE); // LECTURE, QNA_DETAIL, NOTICE_DETAIL

    const lectDetail = ref({})
    const lectIndexList = ref([])

    const userList = ref([])

    const homeworkSummaryList = ref([]);  // 과제요약정보 목록
    const userHomeworkList = ref([]); // 과제제출정보 목록

    const qnaList = ref([])
    const qnaDetail = ref({})
    const qnaInput = ref({
        answer: '',
    })
    const qnaInputError = ref({
        answer: '',
    })

    const noticeList = ref([])
    const noticeDetail = ref({
        attachList: [],
    })
    const noticeInput = ref({
        title: '',
        content: '',
        attachList: [
            {
                attachSeq: 0,
                fileName: "",
                fileSize: "",
                fileDir: "",
                deleteYn: "N"
            }
        ],
        securePdfList: [
            {
                attachSeq: 0,
                fileName: "",
                pageCount: 0,
                fileDir: "",
                deleteYn: "N"
            }
        ]
    })
    const noticeInputError = ref({
        title: '',
        content: '',
    })

    const tabOptions = [
        {label:'학습목차', value: 0},
        {label:'수강생', value: 1},
        {label:'TEST', value: 2},
        {label:'과제', value: 3},
        {label:'Q&A', value: 4},
        {label:'공지사항', value:5},
    ]
    const tabIndex = ref(0)

    const modal = ref(null)
    const confirmModal = ref(null)
    const lecturePlayerModal = ref(null)
    const lectureHomeworkCreateModal = ref(null)
    const lectureHomeworkMarkModal = ref(null)

    const modalContent = ref([]);

    // s : 검색관련
    const searchOpen2 = ref(true); // TEST tab 검색창
    const searchOpen3 = ref(true); // 과제 tab 검색창

    const searchDurationGbRef = ref(null);

    const currentPage = ref(1);
    const pageSize = 30;
    const totalPages = ref(1);
    const totalCount = ref(0);

    // 과제 tab 검색조건
    const searchInput3 = ref({
        searchType: 'submitName', // submitName 제출자명, submitId 제출자아이디, markName 채점자명
        searchInput: '',
        searchDurationGb: 'R',
        searchDurationType: 'all',
        startYmd: '',
        endYmd: '',
    });

    // Q&A tab 검색조건
    const searchInput4 = ref({
        searchType: 'all',
        searchInput: '',
    })

    // 검색조건 - 페이지 크기
    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ])

    // 검색조건 - 검색기준 (submitName 제출자명, submitId 제출자아이디, markName 채점자명). 과제 tab
    const searchTypeGroup3 = ref([
        { label:"제출자명", value: "submitName", placeholder: "제출자명을 입력해주세요." },
        { label:"제출자아이디", value: "submitId", placeholder: "제출자아이디를 입력해주세요."  },
        { label:"채점자명", value: "markName", placeholder: "채점자명을 입력해주세요."  }
    ]);

    const searchInputPlaceholder3 = computed(()=>{
        const option = _.find(searchTypeGroup3.value, { value: searchInput3.value.searchType });
        if(option) {
            return option.placeholder;
        } else {
            return '';
        }
    });

    // 검색조건 - 검색기준 (submitName 제출자명, submitId 제출자아이디, markName 채점자명). Q&A tab
    const searchTypeGroup4 = [
        {label:' 전체', value: 'all'},
        {label:' 제목', value: 'title'},
        {label:' 내용', value: 'content'},
    ]

    // 검색조건 - 기간 (S 제출일, M 채점일). 과제 tab
    const searchDurationGbGroup = ref([
        { label:"제출일", value: "S" },
        { label:"채점일", value: "M" },
    ]);

    // 검색조건 - 날짜검색기준 (all 모든 기간, term 기간 검색). 과제 tab
    const searchDurationTypeGroup = ref([
        { label:"모든 기간", value: "all" },
        { label:"기간 검색", value: "term" },
    ]);

    // 검색조건 - 검색기준 매핑
    const onSelectSearchTypeGroup = (option) => {
        if(tabIndex.value === 2) { // TEST

        } else if(tabIndex.value === 3) { // 과제
            searchInput3.value.searchType = option.value;
        } else if(tabIndex.value === 4) { // Q&A
            searchInput4.value.searchType = option.value;
        }
    }

    // 검색조건 - 기간 매핑
    const onSelectSearchDurationGbGroup = (option) => {
        searchInput3.value.searchDurationGb = option.value;
    }

    // 검색조건 - 초기화 이벤트
    const clearSearchInput = () => {
        if(tabIndex.value === 2) { // TEST

        } else if(tabIndex.value === 3) { // 과제
            searchInput3.value = {
                searchType: 'submitName', // submitName 제출자명, submitId 제출자아이디, markName 채점자명
                searchInput: '',
                searchDurationGb: 'R',
                searchDurationType: 'all',
                startYmd: '',
                endYmd: '',
            }
        }
    }

    // 검색조건 - 검색 이벤트
    const onClickSearch = () => {
        currentPage.value = 1;

        if(tabIndex.value === 2) { // TEST

        } else if(tabIndex.value === 3) { // 과제
            retrieveAllHomeworkPaging();
        } else if(tabIndex.value === 4) { // Q&A
            searchInput4.value.searchInput = value;
            retrieveLectureQnAList();
        }
    }

    // 페이지 선택
    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;

        if(tabIndex.value === 2) { // TEST

        } else if(tabIndex.value === 3) { // 과제
            retrieveAllHomeworkPaging();
        } else if(tabIndex.value === 4) { // Q
            retrieveLectureQnAList();
        } else if(tabIndex.value === 5) { // 공지사항
            retrieveLectureNoticeList();
        }
    }

    // 검색조건 - 페이지 크기 변경 이벤트
    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;

        if(tabIndex.value === 2) { // TEST

        } else if(tabIndex.value === 3) { // 과제
            retrieveAllHomeworkPaging();
        }
    }

    // e : 검색관련

    const retrieveLectureDetail = (lectId) => {
        const { regDt, userId } = lectureStore.lecture;
        const paramObj = {
            lectId,
            regDt,
            userId,
        }
        return lectureUserService.retrieveMyLectureDetail(paramObj)
    }

    const retrieveLectureAllIndex = (id) => {
        return lectureUserService.retrieveLectureAllIndex(id);
    }

    const onSelectTab = (option) => {
        tabIndex.value = option.value;
        currentPage.value = 1;

        if(tabIndex.value === 1) { // 수강생
            retrieveLectureUserList();
        } else if(tabIndex.value === 3) { // 과제
            retrieveHomeworkSummary();
        } else if(tabIndex.value === 4) { // Q&A
            retrieveLectureQnAList();
        } else if(tabIndex.value === 5) { // 공지사항
            retrieveLectureNoticeList();
        }
    }

    // s : 수강생

    const retrieveLectureUserList = () => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            pageNo: currentPage.value,
            size: pageSize,
        }
        loadingStore.loading = true;
        lectureUserService.retrieveMyLecutreUserList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    userList.value = result.content;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    // e : 수강생

    // s : TEST

    // e : TEST

    // s : 과제
    const retrieveHomeworkSummary = () => {
        loadingStore.loading = true;
        lectureHomeworkService.retrieveHomeworkSummary(lectDetail.value.lectId)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    homeworkSummaryList.value = result;
                }
                if( homeworkSummaryList.length !== 0 ) {
                    retrieveAllHomeworkPaging();
                }
            }).catch( (error) =>  {
            console.log(error);
            loadingStore.loading = false;
            $toast('error', '오류가 발생하였습니다.');
        });
    }

    // 과제 tab - 과제 제출 목록 조회
    const retrieveAllHomeworkPaging = () => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            searchType: searchInput3.value.searchType,
            searchInput: searchInput3.value.searchInput,
            searchDurationGb: searchInput3.value.searchDurationGb,
            startYmd: searchInput3.value.searchDurationType === 'all' ? "" : searchInput3.value.startYmd,
            endYmd: searchInput3.value.searchDurationType === 'all' ? "" : searchInput3.value.endYmd,
            pageNo: currentPage.value,
            size: pageSize.value,
        }

        loadingStore.loading = true;
        lectureHomeworkService.retrieveAllHomeworkPaging(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    userHomeworkList.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
                }
            }).catch( (error) =>  {
            console.log(error);
            loadingStore.loading = false;
            $toast('error', '오류가 발생하였습니다.');
        });
    }

    // 과제 tab - 과제 등록 모달 실행
    const onClickCreateHomework = async () => {
        lectureHomeworkCreateModal.value.init(lectDetail.value.lectId);
        const result = await lectureHomeworkCreateModal.value.show();
        // console.log(result);
        if(result) { // 심사상태 업데이트
            retrieveHomeworkSummary();
        }
    }

    // 과제 tab - 과제 채점하기 모달 실행
    const onClickMarkHomework = async (homework) => {
        console.log(homework);
        lectureHomeworkMarkModal.value.init(homework.userId, homework.lectId, homework.regDt, homework.hwId, homework.fullMarks);
        const result = await lectureHomeworkMarkModal.value.show();
        if(result) { // 심사상태 업데이트
            retrieveHomeworkSummary();
        }
    }

    // e : 과제

    // s : QNA

    const retrieveLectureQnAList = () => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            pageNo: currentPage.value,
            size: pageSize,
            searchType: searchInput4.value.searchType,
            searchInput: searchInput4.value.searchInput,
        }
        loadingStore.loading = true;
        lectureQnaService.retrieveList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    qnaList.value = $pageListBoardNo(result.content, totalCount.value, pageSize, currentPage.value);
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const retrieveQnADetail = (id) => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            boardId: id,
        }

        loadingStore.loading = true;
        lectureQnaService.retrieveDetail(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    qnaDetail.value = result;
                    qnaInput.value.isEdit = qnaDetail.value.answer === null || qnaDetail.value.answer === '';
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onClickUpdateAnswer = () => {
        const { answer } = qnaInput.value;
        const { boardId } = qnaDetail.value;

        let paramObj = {
            lectId: lectDetail.value.lectId,
            boardId,
            answer,
        }

        loadingStore.loading = true;
        lectureQnaService.updateAnswer(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    qnaDetail.value = result;
                    qnaInput.value.isEdit = false;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onClickEditCancel = () => {
        qnaInput.value.answer = qnaDetail.value.answer;
        qnaInput.value.isEdit = false;
    }

    const onClickEditAnswer = () => {
        qnaInput.value.answer = qnaDetail.value.answer;
        qnaInput.value.isEdit = true;

    }

    // e : QNA

    // s : 공지사항

    const retrieveLectureNoticeList = () => {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            pageNo: currentPage.value,
            size: pageSize,
        }
        loadingStore.loading = true;
        lectureNoticeService.retrieveList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    noticeList.value = $pageListBoardNo(result.content, totalCount.value, pageSize, currentPage.value);
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const retrieveNoticeDetail = (id) => {
        if(id) {
            noticeDetail.value = {
                title: '',
                content: '',
                attachList: [],
            };

            loadingStore.loading = true;
            let paramObj = {
                lectId: lectDetail.value.lectId,
                boardId: id,
            }
            lectureNoticeService.retrieveDetail(paramObj)
                .then( (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        noticeDetail.value = result;
                    }
                }).catch( (error) =>  {
                loadingStore.loading = false;
                console.log(error);
            });
        }
    }

    const uploadFile = () => {
        const fileInput = document.createElement('input');
        fileInput.type= 'file';
        fileInput.click();

        fileInput.addEventListener('change', function(event){
            // console.log(event.target.files);
            const file = event.target.files[0];
            // console.log(file);
            if(file.size > (MAX_SIZE*1024*1024)) {
                modalContent.value = [`파일은 최대 ${MAX_SIZE}MB까지 첨부할 수 있습니다.`];
                modal.value.show();
            } else {
                let paramObj = {
                    container: 'private',
                    folder: 'lecture_notice',
                    file,
                }

                fileService.uploadFile(paramObj)
                    .then((res) => {
                        // console.log(res);
                        if(res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            // console.log(result);
                            noticeInput.value.attachList[0].fileName = result.originalFilename;
                            noticeInput.value.attachList[0].fileSize = result.fileSize;
                            noticeInput.value.attachList[0].fileDir = result.blobUrl;
                            // console.log(input.value)
                        }
                    })
                    .catch( (error) =>  {
                        console.log(error);
                    });
            }
        });
    }

    const removeFile = () => {
        noticeInput.value.attachList[0].fileName = '';
        noticeInput.value.attachList[0].fileSize =  '';
        noticeInput.value.attachList[0].fileDir =  '';
    }

    const uploadSecurePdfFile = () => {
        const fileInput = document.createElement('input');
        fileInput.type= 'file';
        fileInput.click();

        fileInput.addEventListener('change', function(event){
            // console.log(event.target.files);
            const file = event.target.files[0];
            // console.log(file);
            if(file.size > (MAX_SIZE*1024*1024)) {
                modalContent.value = [`파일은 최대 ${MAX_SIZE}MB까지 첨부할 수 있습니다.`];
                modal.value.show();
            } else {
                fileService.uploadSecurePDF(file)
                    .then((res) => {
                        // console.log(res);
                        if(res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            // console.log(result);
                            noticeInput.value.securePdfList[0].fileName = result.originalFilename;
                            noticeInput.value.securePdfList[0].pageCount = result.pageCount;
                            noticeInput.value.securePdfList[0].fileDir = result.blobUrl;
                            // console.log(input.value)
                        }
                    })
                    .catch( (error) =>  {
                        console.log(error);
                    });
            }
        });
    }

    const removeSecurePdfFile = () => {
        noticeInput.value.securePdfList[0].fileName = '';
        noticeInput.value.securePdfList[0].pageCount = 0;
        noticeInput.value.securePdfList[0].fileDir =  '';
    }

    const createNotice = () => {
        const { title, content, attachList, securePdfList } = noticeInput.value;
        let isInvalid = false;

        noticeInputError.value = {
            title: '',
            content: '',
        }

        if(!title) {
            noticeInputError.value.title = '제목을 입력하세요.';
            isInvalid = true;
        }
        if(!content) {
            noticeInputError.value.content = '내용을 입력하세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            let paramObj = {
                lectId: lectDetail.value.lectId,
                title,
                content,
                attachList: _.filter(attachList, attach => {
                    return attach.fileName !== null && attach.fileName !== '';
                }),
                securePdfList: _.filter(securePdfList, attach => {
                    return attach.fileName !== null && attach.fileName !== '';
                }),
            }
            loadingStore.loading = true;
            lectureNoticeService.createNotice(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        console.log(result);
                        retrieveLectureNoticeList();
                        setViewMode(LECTURE)
                    } else {
                    }
                }).catch( (error) =>  {
                // console.log(error);
                loadingStore.loading = false;
            });
        }
    }

    const deleteNotice = async () => {
        modalContent.value = ["공지사항을 삭제할까요?", "삭제 시 사용자 사이트에 즉시 적용되며 되돌릴 수 없습니다."];
        const ok = await confirmModal.value.show()
        if(ok){
            _deleteNotice()
        }
    }

    function _deleteNotice() {
        let paramObj = {
            lectId: lectDetail.value.lectId,
            boardId: noticeDetail.value.boardId
        };

        loadingStore.loading = true;
        lectureNoticeService.deleteNotice(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    retrieveLectureNoticeList();
                    setViewMode(LECTURE);
                } else {
                    modalContent.value = ["삭제를 실패하였습니다."];
                    modal.value.show();
                }
            }).catch( (res) =>  {
            loadingStore.loading = false;
            console.log(res);
            modalContent.value = ["삭제를 실패하였습니다."];
            modal.value.show();
        });
    }

    const updateNotice = () => {
        const { title, content, attachList, securePdfList } = noticeInput.value;
        let isInvalid = false;

        noticeInputError.value = {
            title: '',
            content: '',
        }

        if(!title) {
            noticeInputError.value.title = '제목을 입력하세요.';
            isInvalid = true;
        }
        if(!content) {
            noticeInputError.value.content = '내용을 입력하세요.';
            isInvalid = true;
        }

        _.each(attachList, attach => {
            attach.deleteYn = !attach.fileName ? 'Y' : 'N';
        });
        _.each(securePdfList, attach => {
            attach.deleteYn = !attach.fileName ? 'Y' : 'N';
        });

        if(!isInvalid) {
            let paramObj = {
                lectId: lectDetail.value.lectId,
                boardId: noticeDetail.value.boardId,
                title,
                content,
                attachList,
                securePdfList,
            }
            loadingStore.loading = true;
            lectureNoticeService.updateNotice(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        noticeDetail.value = result;
                        // retrieveLectureNoticeList();
                        setViewMode(NOTICE_DETAIL)
                    } else {
                    }
                }).catch( (error) =>  {
                // console.log(error);
                loadingStore.loading = false;
            });
        }
    }

    const onClickViewer = (attach) => {
        sessionStorage.setItem('pdfviewer', JSON.stringify({
            keyName: attach.fileDir,
            lectId: lectDetail.value.lectId,
            pageCount: attach.pageCount,
        }));
        window.open('/mypage/lecture/viewer', '_blank');
    }

    // e : 공지사항

    const setViewMode = (viewName, boardId) => {
        viewMode.value = viewName;

        if(viewName === NOTICE_CREATE || viewName === NOTICE_UPDATE) {
            noticeInput.value = {
                title: viewName === NOTICE_UPDATE ? noticeDetail.value.title : '',
                content: viewName === NOTICE_UPDATE ? noticeDetail.value.content : '',
                attachList: viewName === NOTICE_UPDATE ? noticeDetail.value.attachList : [
                    {
                        attachSeq: 0,
                        lectId: lectDetail.value.lectId,
                        fileName: "",
                        fileSize: "",
                        fileDir: "",
                        deleteYn: "N"
                    }
                ],
                securePdfList: viewName === NOTICE_UPDATE ? noticeDetail.value.securePdfList : [
                    {
                        attachSeq: 0,
                        lectId: lectDetail.value.lectId,
                        fileName: "",
                        pageCount: 0,
                        fileDir: "",
                        deleteYn: "N"
                    }
                ],
            };
            noticeInputError.value = {
                title: '',
                content: '',
            };

            if(viewName === NOTICE_UPDATE) {
                if(_.isEmpty(noticeDetail.value.attachList)) {
                    noticeInput.value.attachList = [
                        {
                            attachSeq: 0,
                            lectId: lectDetail.value.lectId,
                            fileName: "",
                            fileSize: "",
                            fileDir: "",
                            deleteYn: "N"
                        }
                    ];
                }
                if(_.isEmpty(noticeDetail.value.securePdfList)) {
                    noticeInput.value.securePdfList = [
                        {
                            attachSeq: 0,
                            lectId: lectDetail.value.lectId,
                            fileName: "",
                            pageCount: 0,
                            fileDir: "",
                            deleteYn: "N"
                        }
                    ];
                }
            }

            if(viewName === NOTICE_CREATE) {
                fileService.generateAccountSas();
            } else if (viewName === NOTICE_UPDATE) {
                sasTkStore.sasToken = noticeDetail.value.sasToken;
            }

        } else if(viewName === 'QNA_DETAIL') {
            retrieveQnADetail(boardId)
        } else if(viewName === 'NOTICE_DETAIL') {
            retrieveNoticeDetail(boardId)
        } else { // LECTURE

            if(tabIndex.value === 4) {
                retrieveLectureQnAList()
            } else if(tabIndex.value === 5) {
                retrieveLectureNoticeList()
            }
        }
    }

    const onClickVideoPlay = (lectIndex) => {
        let paramObj = {
            uploadFileKey: lectIndex.uploadFileKey,
            lectId: lectDetail.value.lectId,
            lectIdx: lectIndex.lecIdx,
            regDt: lectDetail.value.regDt,
        }
        lecturePlayerModal.value.show(lectDetail.value, paramObj, true);
    }

    const startVideoPreview = () => {
        if(lectDetail.value.oneMovDir) {
            showVideo.value = true;
        }
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="flex-wrap">
                <SNB class="snb" />
                <!-- S : 강의 상세-->
                <div class="content">
                    <div class="lecture-wrap">
                        <div class="sub-title grid">
                            <h3><span class="badge">{{ lectDetail.lectGb === '01' ? '온라인' : '오프라인' }} 강의</span>{{ lectDetail.lectName }}</h3>
                        </div>
                        <div class="lecture-summary" v-show="viewMode === LECTURE">
                            <div class="left-area">
                                <div v-show="!showVideo" class="img" @click="startVideoPreview">
                                    <KSAImage :src="lectDetail.lectThombDir"/>
                                    <button class="play-btn" v-if="lectDetail.oneMovDir">
                                        <span></span>
                                    </button>
                                </div>
                                <div v-if="showVideo" class="video">
                                    <KSAVideo :src="lectDetail.oneMovDir" :poster="lectDetail.lectThombDir"/>
                                </div>
                            </div>
                            <div class="right-area" v-if="lectDetail.lectGb === '01'">
                                <p>
                                    <span>신청 기간</span>
                                    <span v-if="lectDetail.regTermGb === '01'">상시</span>
                                    <span v-else>{{ $yyyymmdd(lectDetail.regStartYmd) }} ~ {{ $yyyymmdd(lectDetail.regEndYmd) }}</span>
                                </p>
                                <p>
                                    <span>교육 기간</span>
                                    <span v-if="lectDetail.lectTermGb === '01'">{{ lectDetail.lectTerm}}일</span>
                                    <span v-else>{{ $yyyymmdd(lectDetail.lectStartYmd) }} ~ {{ $yyyymmdd(lectDetail.lectEndYmd) }}</span>
                                </p>
                                <p>
                                    <span>총 시간</span>
                                    <span>{{ lectDetail.lectureIndexMovPlayTimeSumString || '-' }}</span>
                                </p>
                                <p>
                                    <span>강의 수</span>
                                    <span>총 {{ lectDetail.lectureIndexCount }}차시</span>
                                </p>
                                <p>
                                    <span>강사 명</span>
                                    <span>{{ lectDetail.teacherNameText }}</span>
                                </p>
                                <p>
                                    <span>수료 기준</span>
                                    <span v-if="lectDetail.finCriteriaGb === '02'">교육기간 내 진도율 {{ lectDetail.finishCriteria }}% 이상</span>
                                    <span v-else-if="lectDetail.finCriteriaGb === '03'">Pass/Fail</span>
                                    <span v-else>-</span>
                                </p>
                                <p>
                                    <span>제한 인원</span>
                                    <span v-if="lectDetail.limitMemYn === 'Y'">{{ lectDetail.limitMemNum }}명</span>
                                    <span v-else>없음</span>
                                </p>
                                <p v-if="lectDetail.note">
                                    <small>*{{ lectDetail.note }}</small>
                                </p>
                            </div>
                            <div class="right-area" v-if="lectDetail.lectGb === '02'">
                                <p>
                                    <span>교육 기간</span>
                                    <span v-if="lectDetail.lectTermGb === '01'">신청일로부터 {{ lectDetail.lectTerm }}일</span>
                                    <span v-else>{{ $yyyymmdd(lectDetail.lectStartYmd) }} ~ {{ $yyyymmdd(lectDetail.lectEndYmd) }}</span>
                                </p>
                                <p v-if="lectDetail.ticketOfferGb === '03'">
                                    <span>면접 기간</span>
                                    <span v-if="lectDetail.intvTermGb === '01'">상시</span>
                                    <span v-else>{{ $yyyymmdd(lectDetail.intvStartYmd) }} ~ {{ $yyyymmdd(lectDetail.intvEndYmd) }}</span>
                                </p>
                                <p>
                                    <span>교육 시간</span>
                                    <span v-if="lectDetail.lectHour || lectDetail.lectMin">
                                        {{ lectDetail.lectHour ? `${lectDetail.lectHour}시간` : '' }}{{ lectDetail.lectMin ? ` ${lectDetail.lectMin}분` : '' }}
                                    </span>
                                    <span v-else>-</span>
                                </p>
                                <p>
                                    <span>교육 장소</span>
                                    <span>{{ lectDetail.lectPlace }}</span>
                                </p>
                                <p>
                                    <span>강사 명</span>
                                    <span>{{ lectDetail.teacherNameText }}</span>
                                </p>
                                <p>
                                    <span>수료 여부</span>
                                    <span>{{ lectDetail.finYn === 'Y' ? '수료'
                                           : lectDetail.finYn === 'N' ? 'Fail'
                                           : lectDetail.finYn === 'D' ? '평가전'
                                           : '-' }}</span>
                                </p>
                                <p v-if="lectDetail.regMemOpenYn === 'Y'">
                                    <span>신청 인원</span>
                                    <span>{{ lectDetail.userLectureCount }}명</span>
                                </p>
                                <p v-if="lectDetail.selMemOpenYn === 'Y'">
                                    <span>선발 인원</span>
                                    <span>{{ lectDetail.userLectureSelCount }}명</span>
                                </p>
                                <p>
                                    <small>*본 신청은 내부 점검용으로 신청하셔도 선발되지 않으니 참고하시길 바랍니다.</small>
                                </p>
                            </div>
                        </div>
                        <div class="lecture-detail" v-show="viewMode === LECTURE">
                            <KSATab class="tab-style_border" :options="tabOptions" @change="onSelectTab"/>
                            <!-- s : 학습 목차(온라인) -->
                            <div class="table-form" v-if="tabIndex === 0 && lectDetail.lectGb === '01'">
                                <table class="horizontal">
                                    <tr>
                                        <th>차시</th>
                                        <th>차시명</th>
                                        <th>시간</th>
                                        <th>수강하기</th>
                                    </tr>
                                    <tr v-for="lectIndex in lectIndexList">
                                        <td>
                                            {{ lectIndex.idxNum }}차시
                                        </td>
                                        <td class="text-left">{{ lectIndex.idxName }}</td>
                                        <td>
                                            <span v-if="lectIndex.movPlayHm">{{ $movPlayHm(lectIndex.movPlayHm) }}</span>
                                            <span v-else>{{ lectIndex.uploadFileKey ? '인코딩 중' : '-' }}</span>
                                        </td>
                                        <td><KSAPrimaryButton text="수강하기" @click="onClickVideoPlay(lectIndex)" :disabled="!(lectIndex.uploadFileKey && lectIndex.movPlayHm)" /></td>
                                    </tr>
                                    <tr v-if="lectIndexList.length === 0">
                                        <td colspan="4">등록된 차시가 없습니다.</td>
                                    </tr>
                                </table>
                            </div>
                            <!-- e : 학습 목차(온라인) -->
                            <!-- s : 학습 목차(오프라인) -->
                            <div class="table-form" v-if="tabIndex === 0 && lectDetail.lectGb === '02'">
                                <table class="horizontal">
                                    <tr>
                                        <th>주차</th>
                                        <th>일시</th>
                                        <th>요일</th>
                                        <th>시간</th>
                                        <th>강사명</th>
                                        <th>실습 여부</th>
                                        <th>교육명</th>
                                        <th>필요 기자재</th>
                                    </tr>
                                    <tr v-for="(lectIndex, idx) in lectIndexList">
                                        <td>
                                            {{ lectIndex.idxNum }}차시
                                        </td>
                                        <td>
                                            <span v-if="lectIndex.idxYmdYn === 'Y'">자유</span>
                                            <span v-else>{{ $yyyymmdd(lectIndex.idxYmd) }}</span>
                                        </td>
                                        <td>
                                            <span v-if="lectIndex.idxYmdYn === 'Y'">자유</span>
                                            <span v-else>{{ $dateToWeekDay(lectIndex.idxYmd) }}</span>
                                        </td>
                                        <td>
                                            {{ lectIndex.idxHms }}시간
                                        </td>
                                        <td>
                                            <span v-if="!lectIndex.teacher">-</span>
                                            <span v-else>{{ lectIndex.teacher.userName }}</span>
                                        </td>
                                        <td>
                                            {{ lectIndex.practiceYn === 'Y' ? 'O' : 'X' }}
                                        </td>
                                        <td class="text-left">{{ lectIndex.idxName }}</td>
                                        <td>{{ lectIndex.supplies }}</td>
                                    </tr>
                                    <tr v-if="lectIndexList.length === 0">
                                        <td colspan="8">등록된 차시가 없습니다.</td>
                                    </tr>
                                </table>
                            </div>
                            <!-- e : 학습 목차(오프라인) -->

                            <!-- s : 수강생 -->
                            <div v-else-if="tabIndex === 1">
                                <div class="list-top" v-if="userList.length > 0">
                                    <p>총 <span>{{ $number(totalCount)}}</span>명</p>
                                </div>
                                <div class="table-form" v-if="userList.length > 0">
                                    <table class="horizontal">
                                        <tr>
                                            <th>번호</th>
                                            <th>이름</th>
                                            <th>수강 신청일</th>
                                            <th>교육 기간</th>
                                            <th>진도율</th>
                                        </tr>
                                        <tr v-for="user in userList">
                                            <td>{{ user.userId }}</td>
                                            <td>{{ user.userName }}</td>
                                            <td>{{ $yyyymmdd(user.regYmd) }}</td>
                                            <td>
                                                {{ $yyyymmdd(user.lectStartYmd) }} ~ {{ $yyyymmdd(user.lectEndYmd) }}
                                            </td>
                                            <td>
                                                {{ user.lectureIndexFinishCount }}/{{ user.lectureIndexCount }}({{ user.progRate }}%)
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="result-none lecture-none" v-if="userList.length === 0">
                                    검색된 수강생이 없습니다.
                                </div>
                                <Paginate
                                    v-if="userList.length > 0"
                                    v-model="currentPage"
                                    :page-count="totalPages"
                                    :click-handler="onSelectPage"
                                />
                            </div>
                            <!-- e : 수강생 -->

                            <!-- s : TEST -->
                            <div class="table-form" v-else-if="tabIndex === 2">
                                <div class="result-none lecture-none">
                                    진행중인 테스트가 없습니다.
                                </div>
                            </div>
                            <!-- e : TEST -->

                            <!-- s : 과제 -->
                            <div class="table-form" v-else-if="tabIndex === 3">
                                <!--
                                <div class="list-top">
                                    <KSASecondaryButton class="gray_button" text="과제 등록" @click="onClickCreateHomework" />
                                </div>
                                -->
                                <div v-if="homeworkSummaryList.length !== 0">
                                    <div class="table-form mb-lg">
                                        <table class="horizontal">
                                            <tr>
                                                <th>제목</th>
                                                <th>전체 대상자</th>
                                                <th>제출자</th>
                                                <th>제출율</th>
                                                <th>평균점수</th>
                                            </tr>
                                            <tr v-for="summary in homeworkSummaryList">
                                                <td>{{ summary.title }}</td>
                                                <td>{{ summary.totalStudent }}명</td>
                                                <td>{{ summary.submitCount }}명</td>
                                                <td>{{ summary.submitCount / summary.totalStudent * 100 }}%</td>
                                                <td>
                                        <span v-if="summary.avgScore">
                                            {{ summary.avgScore }}점
                                        </span>
                                                    <span v-else>-</span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="filter-wrap" :class="{'open': searchOpen3}">
                                        <p @click="searchOpen3 = !searchOpen3">검색</p>
                                        <div class="filter">
                                            <dl>
                                                <dt>
                                                    <KSASelect :options="searchTypeGroup3" class="select" @selectOption="onSelectSearchTypeGroup"/>
                                                </dt>
                                                <dd>
                                                    <KSAInput class="text-box" v-model="searchInput3.searchInput" :placeholder="searchInputPlaceholder3" />
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>
                                                    <KSASelect ref="searchDurationGbRef" :options="searchDurationGbGroup" class="select" @selectOption="onSelectSearchDurationGbGroup"/>
                                                </dt>
                                                <dd class="text-box">
                                                    <div class="grid">
                                                        <template v-for="group in searchDurationTypeGroup">
                                                            <KSARadioButton class="mr-md" name="searchDurationGb3" :label="group.label" v-model="searchInput3.searchDurationType" :value="group.value" />
                                                        </template>
                                                        <div v-show="searchInput3.searchDurationType !== 'all'" style="display: inline-block;">
                                                            <KSADatepickerTerms
                                                                :startDate="searchInput3.startYmd"
                                                                :endDate="searchInput3.endYmd"
                                                                @update:startDate="(value)=> searchInput3.startYmd = value"
                                                                @update:endDate="(value)=> searchInput3.endYmd = value"
                                                            />
                                                        </div>
                                                    </div>
                                                </dd>
                                            </dl>
                                            <div class="btn-wrap">
                                                <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                                                <KSAPrimaryButton text="검색" @click="onClickSearch" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-top">
                                        <p>총 <span>{{ $number(totalCount)}}</span>명</p>
                                        <div>
                                            <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                                        </div>
                                    </div>
                                    <div class="table-form word-break-all">
                                        <table class="horizontal">
                                            <tr>
                                                <th>채점 상태</th>
                                                <th>제목</th>
                                                <th>제출자</th>
                                                <th>점수</th>
                                                <th>제출일시</th>
                                                <th>제출과제</th>
                                                <th>채점자</th>
                                                <th>채점일시</th>
                                                <th>채점하기</th>
                                            </tr>
                                            <tr v-for="homework in userHomeworkList">
                                                <td>
                                                    <span v-if="homework.markDt">채점 완료</span>
                                                    <span v-else>제출 완료</span>
                                                </td>
                                                <td>{{ homework.title }}</td>
                                                <td>
                                                    {{ homework.userName }}<br>
                                                    ({{ homework.userLoginId }})
                                                </td>
                                                <td>
                                                    <span v-if="homework.score">
                                                        {{ homework.score }}점
                                                    </span>
                                                    <span v-else>-</span>
                                                </td>
                                                <td>{{ $yyyymmddHHmm(homework.submitDt) }}</td>
                                                <td><KSATextButton v-if="homework.fileName" class="" text="다운로드" @click="$download(homework.fileDir)" :title=homework.fileName /></td>
                                                <td>
                                                    <span v-if="homework.markUserName">
                                                        {{ homework.markUserName }}
                                                    </span>
                                                    <span v-else>-</span>
                                                </td>
                                                <td>
                                                    <span v-if="homework.markDt">
                                                        {{ $yyyymmddHHmm(homework.markDt) }}
                                                    </span>
                                                    <span v-else>-</span>
                                                </td>
                                                <td><KSATextButton :disabled="homework.score > 0" class="" text="채점하기" @click="onClickMarkHomework(homework)" /></td>
                                            </tr>
                                            <tr v-if="userHomeworkList.length === 0">
                                                <td colspan="9">제출된 과제가 없습니다.</td>
                                            </tr>
                                        </table>
                                        <Paginate
                                            v-if="userHomeworkList.length > 0"
                                            v-model="currentPage"
                                            :page-count="totalPages"
                                            :click-handler="onSelectPage"
                                        />
                                    </div>
                                </div>
                                <div class="result-none" v-if="homeworkSummaryList.length === 0">
                                    등록된 과제가 없습니다.
                                </div>
                            </div>
                            <!-- e : 과제 -->

                            <!-- s : Q&A -->
                            <div v-else-if="tabIndex === 4">
                                <div class="list-top">
                                    <p>총 게시물 <span>{{ $number(totalCount)}}</span>건</p>
                                    <div class="search-box">
                                        <KSASelect :options="searchTypeGroup4" class="search-select" @selectOption="onSelectSearchTypeGroup"/>
                                        <KSASearchInputBox placeholder="검색어를 입력하세요." class="search-input" @search="onClickSearch" />
                                    </div>
                                </div>
                                <div class="table-form">
                                    <table class="horizontal">
                                        <tr>
                                            <th>번호</th>
                                            <th>제목</th>
                                            <th>작성자</th>
                                            <th>작성일</th>
                                            <th>상태</th>
                                        </tr>
                                        <tr v-for="board in qnaList" @click="setViewMode(QNA_DETAIL, board.boardId)">
                                            <td>{{ board.boardNo }}</td>
                                            <td class="text-left"><a href="javascript:void(0);">{{ board.title }}</a></td>
                                            <td>{{ board.createUserName }}</td>
                                            <td>{{ $yyyymmddOrHHmm(board.createDate) }}</td>
                                            <td :class="{'wait': !board.answer, 'complete': board.answer}">답변 {{ board.answer ? '완료' : '대기' }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="result-none lecture-none" v-if="qnaList.length === 0">
                                    검색 결과가 없습니다.
                                </div>
                                <Paginate
                                    v-if="qnaList.length > 0"
                                    v-model="currentPage"
                                    :page-count="totalPages"
                                    :click-handler="onSelectPage"
                                />
                            </div>
                            <!-- e : Q&A -->

                            <!-- s : 공지사항 -->
                            <div v-else-if="tabIndex === 5">
                                <div class="list-top" v-if="noticeList.length > 0">
                                    <p>총 게시물 <span>{{ $number(totalCount)}}</span>건</p>
                                </div>
                                <div class="table-form">
                                    <table class="horizontal">
                                        <tr>
                                            <th>번호</th>
                                            <th>제목</th>
                                            <th>작성자</th>
                                            <th>작성일</th>
                                            <th>조회수</th>
                                        </tr>
                                        <tr v-for="board in noticeList" @click="setViewMode(NOTICE_DETAIL, board.boardId)">
                                            <td>{{ board.boardNo }}</td>
                                            <td class="text-left"><a href="javascript:void(0);">{{ board.title }}<img v-if="board.attachList.length > 0 && board.attachList[0].fileName" src="/src/assets/images/icon-file.png" class="icon-file" /></a></td>
                                            <td>{{ board.createUserName }}</td>
                                            <td>{{ $yyyymmddOrHHmm(board.createDate) }}</td>
                                            <td>{{ $number(board.hitCount) }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="result-none lecture-none" v-if="noticeList.length === 0">
                                    등록된 공지사항이 없습니다.
                                </div>
                                <div class="btn-wrap text-right mb-xs">
                                    <KSASecondaryButton class="gray_button" text="글 작성" @click="setViewMode(NOTICE_CREATE)" />
                                </div>
                                <Paginate
                                    v-if="noticeList.length > 0"
                                    v-model="currentPage"
                                    :page-count="totalPages"
                                    :click-handler="onSelectPage"
                                />
                            </div>
                            <!-- e : 공지사항 -->
                        </div>
                        <div class="lecture-detail" v-show="viewMode !== LECTURE">
                            <!-- S : QNA 상세 -->
                            <div v-if="viewMode === QNA_DETAIL && qnaDetail.boardId">
                                <div class="board-detail">
                                    <div class="header" style="position: relative">
                                        <div class="board-title">
                                            <span :class="{'complete': qnaDetail.answer}">답변 {{ qnaDetail.answer ? '완료' : '대기' }}</span>
                                            <p>{{ qnaDetail.title }} <img v-if="qnaDetail.secretYn === 'Y'" src="/src/assets/images/icon-lock.png" /></p>
                                        </div>
                                        <span>작성일 <i>{{ $yyyymmddOrHHmm(qnaDetail.createDate) }}</i></span>
                                        <span>작성자 <i>{{ qnaDetail.createUserName || '작성자' }}<span v-if="qnaDetail.createUserLoginId">({{ qnaDetail.createUserLoginId }})</span></i></span>
                                        <span>조회수 <i>{{ $number(qnaDetail.hitCount) }}</i></span>
                                    </div>
                                    <div class="body">
                                        <div class="text" v-html="qnaDetail.content"></div>
                                        <div class="answer">
                                            <span>답변</span>
                                            <p v-if="qnaDetail.answererUserName">{{ qnaDetail.answererUserName }}
                                                <span v-if="qnaDetail.answererUserLoginId">({{ qnaDetail.answererUserLoginId }})</span>
                                            </p>
                                            <div v-if="!qnaDetail.answer || qnaInput.isEdit">
                                                <KSATextArea class="textarea-box" v-model="qnaInput.answer" :msg="qnaInputError.answer" :displayMaxLenYn="false" placeholder="답변을 입력하세요." max-length="500"/>
                                                <div class="text-right">
                                                    <KSATextButton v-if="qnaDetail.answer" class="btn save" text="취소" @click="onClickEditCancel" />&nbsp;
                                                    <KSATextButton class="btn save" text="저장하기" @click="onClickUpdateAnswer" :disabled="!qnaInput.answer" />
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="text">
                                                    <div v-html="qnaDetail.answer"></div>
                                                    <p>{{ $yyyymmddOrHHmm(qnaDetail.answerDate) }}</p>
                                                </div>
                                                <KSATextButton class="btn edit black_button" text="수정하기" @click="onClickEditAnswer" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-wrap text-left">
                                    <KSASecondaryButton class="gray_button" text="목록" @click="setViewMode(LECTURE)" />
                                </div>
                            </div>
                            <!-- E : QNA 상세 -->

                            <!-- S : 공지사항 상세-->
                            <div v-if="viewMode === NOTICE_DETAIL && noticeDetail.boardId">
                                <div class="board-detail">
                                    <div class="header">
                                        <div class="board-title">
                                            <p>{{ noticeDetail.title }}<img v-if="noticeDetail.attachList.length > 0 && noticeDetail.attachList[0].fileName" src="/src/assets/images/icon-file.png" class="icon-file" /></p>
                                        </div>
                                        <span>작성일<i>{{ $yyyymmddOrHHmm(noticeDetail.createDate) }}</i></span>
                                        <span>작성자 <i>{{ noticeDetail.createUserName }}<span v-if="noticeDetail.createUserLoginId">({{ noticeDetail.createUserLoginId}})</span></i></span>
                                        <span>조회수 <i>{{ $number(noticeDetail.hitCount)}}</i></span>
                                    </div>
                                    <div class="body">
                                        <div class="text" v-html="noticeDetail.content"></div>
                                    </div>
                                    <div class="control">
                                        <dl class="download" v-if="noticeDetail.attachList.length > 0 && noticeDetail.attachList[0].fileName">
                                            <dt>첨부</dt>
                                            <dd>
                                                <div v-for="attach in noticeDetail.attachList">
                                                    {{ attach.fileName }}
                                                    <KSATextButton v-if="attach.fileDir" class="ml-xs" text="다운로드" @click="$downloadForPrivate(attach)" />
                                                </div>
                                            </dd>
                                        </dl>
                                        <dl class="download" v-if="noticeDetail.securePdfList.length > 0 && noticeDetail.securePdfList[0].fileName">
                                            <dt>보안 PDF</dt>
                                            <dd>
                                                <div v-for="attach in noticeDetail.securePdfList">
                                                    {{ attach.fileName }}
                                                    <KSATextButton v-if="attach.fileDir" class="ml-xs" text="뷰어로 보기" @click="onClickViewer(attach)" />
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <div class="btn-wrap space-between">
                                    <KSASecondaryButton class="gray_button" text="목록" @click="setViewMode(LECTURE)" />
                                    <div>
                                        <KSASecondaryButton text="수정" @click="setViewMode(NOTICE_UPDATE)" />
                                        <KSASecondaryButton class="red_button" text="삭제" @click="deleteNotice" />
                                    </div>
                                </div>
                            </div>
                            <!-- E : 공지사항 상세-->
                            <!-- S : 공지사항 생성 -->
                            <div v-if="viewMode === NOTICE_CREATE">
                                <div class="board-detail">
                                    <div class="table-form">
                                        <table class="vertical">
                                            <colgroup>
                                                <col width="110px" />
                                                <col />
                                            </colgroup>
                                            <tr>
                                                <th>제목</th>
                                                <td>
                                                    <KSAInput class="text-box mb-xs" v-model="noticeInput.title" :msg="noticeInputError.title" placeholder="제목을 입력하세요."/>
                                                    <!--                                    <KSACheckBox label="비밀글로 설정합니다." v-model="qnaInput.secretYn" />-->
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>내용</th>
                                                <td>
                                                    <KSACKEditor v-model="noticeInput.content" container="private"/>
                                                    <div>
                                                        <small style="color:#E71414;">{{ noticeInputError.content }}</small>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>첨부</th>
                                                <td class="text-box">
                                                    <div class="grid file" v-for="attach in noticeInput.attachList">
                                                        <KSAPrimaryButton text="파일 선택" @click="uploadFile" />
                                                        <KSAInput class="input icon" v-model="attach.fileName" disabled />
                                                        <button class="delete" @click="removeFile" :disabled="!attach.fileName"><i></i></button>
                                                    </div>
                                                    <small>최대 {{ MAX_SIZE }}MB 까지 첨부 가능</small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>보안 PDF 업로드</th>
                                                <td class="text-box">
                                                    <div class="grid file" v-for="attach in noticeInput.securePdfList">
                                                        <KSAPrimaryButton text="파일 선택" @click="uploadSecurePdfFile" />
                                                        <KSAInput class="input icon" v-model="attach.fileName" disabled />
                                                        <button class="delete" @click="removeSecurePdfFile" :disabled="!attach.fileName"><i></i></button>
                                                    </div>
                                                    <small>최대 {{ MAX_SIZE || 200 }}MB 까지 첨부 가능</small>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="btn-wrap space-between">
                                    <KSASecondaryButton class="gray_button" text="취소" @click="setViewMode(LECTURE)" />
                                    <KSAPrimaryButton text="등록" @click="createNotice" :disabled="!noticeInput.title || !noticeInput.content" />
                                </div>
                            </div>
                            <!-- E : 공지사항 생성 -->
                            <!-- S : 공지사항 수정 -->
                            <div v-if="viewMode === NOTICE_UPDATE">
                                <div class="board-detail">
                                    <div class="table-form">
                                        <table class="vertical">
                                            <colgroup>
                                                <col width="110px" />
                                                <col />
                                            </colgroup>
                                            <tr>
                                                <th>제목</th>
                                                <td>
                                                    <KSAInput class="text-box mb-xs" v-model="noticeInput.title" :msg="noticeInputError.title" placeholder="제목을 입력하세요."/>
                                                    <!--                                    <KSACheckBox label="비밀글로 설정합니다." v-model="qnaInput.secretYn" />-->
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>내용</th>
                                                <td>
                                                    <KSACKEditor v-model="noticeInput.content" container="private"/>
                                                    <div>
                                                        <small style="color:#E71414;">{{ noticeInputError.content }}</small>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>첨부</th>
                                                <td class="text-box">
                                                    <div class="grid file" v-for="attach in noticeInput.attachList">
                                                        <KSAPrimaryButton text="파일 선택" @click="uploadFile" />
                                                        <KSAInput class="input icon" v-model="attach.fileName" disabled />
                                                        <button class="delete" @click="removeFile" :disabled="!attach.fileName"><i></i></button>
                                                    </div>
                                                    <small>최대 {{ MAX_SIZE }}MB 까지 첨부 가능</small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>보안 PDF 업로드</th>
                                                <td class="text-box">
                                                    <div class="grid file" v-for="attach in noticeInput.securePdfList">
                                                        <KSAPrimaryButton text="파일 선택" @click="uploadSecurePdfFile" />
                                                        <KSAInput class="input icon" v-model="attach.fileName" disabled />
                                                        <button class="delete" @click="removeSecurePdfFile" :disabled="!attach.fileName"><i></i></button>
                                                    </div>
                                                    <small>최대 {{ MAX_SIZE || 200 }}MB 까지 첨부 가능</small>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="btn-wrap space-between">
                                    <KSASecondaryButton class="gray_button" text="취소" @click="setViewMode(NOTICE_DETAIL)" />
                                    <KSAPrimaryButton text="저장" @click="updateNotice" :disabled="!noticeInput.title || !noticeInput.content" />
                                </div>
                            </div>
                            <!-- E : 공지사항 수정 -->
                        </div>
                    </div>
                </div>
                <!-- E : 강의 상세-->
            </div>
        </div>
    </div>
    <LecturePlayerModal ref="lecturePlayerModal"/>
    <LectureHomeworkCreateModal ref="lectureHomeworkCreateModal"/>
    <LectureHomeworkMarkModal ref="lectureHomeworkMarkModal"/>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="삭제" />
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .board-detail {
        .header {
            border-top: 0;
        }
        .table-form {
            table {
                border-top: 0;
            }
        }
    }

    // 너무 큰 그리드의 정리를 위해 필요 - 과제 tab
    .word-break-all {
        tr, th, td, span {
            word-break: break-all;
        }
    }

    // 검색 영역 - 과제 tab
    .filter-wrap {
        margin-bottom: 40px;
        &.open {
            p {
                &:before {
                    transition-duration: 0.2s;
                    -webkit-transform: translateY(-50%) rotate(225deg);
                    transform: translateY(-50%) rotate(225deg);
                }
            }
            .filter {
                display: block;
                border-bottom: $border_line;
            }
        }
        p {
            position: relative;
            @include font-size_md;
            background: $gray-light_color;
            border: $border_line;
            font-weight: 700;
            cursor: pointer;
            padding: 10px 20px;
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                right: 20px;
                display: block;
                width: 8px;
                height: 8px;
                border-top: 1px solid #000000;
                border-left: 1px solid #000000;
                -webkit-transform: translateY(-50%) rotate(45deg);
                transform: translateY(-50%) rotate(45deg);
                transition-duration: 0.2s;
            }
        }
        .filter {
            display: none;
            border-left: $border_line;
            border-right: $border_line;
            dl {
                display: flex;
                align-items: center;
                dt {
                    display: flex;
                    align-items: center;
                    width: 180px;
                    height: 48px;
                    font-weight: 700;
                    padding: 10px 10px 10px 20px;
                    .select {
                        font-weight: initial;
                    }
                }
                dd {
                    width: calc(100% - 180px);
                    padding: 10px 20px 10px 0;
                    .grid {
                        span {
                            margin: 0 4px;
                        }
                    }
                }
                &:first-of-type {
                    padding-top: 10px;
                }
                &:last-of-type {
                    padding-bottom: 10px;
                }
            }
            .btn-wrap {
                border-top: $border_line;
                padding: 10px 20px;
                margin: 0;
                button {
                    margin: 0 5px;
                }
            }
            .text-box {
                margin-top: 0;
            }
        }
    }
</style>