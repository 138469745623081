<script setup>
    import { ref, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import {useRecordStore} from "../../stores/mypage/recordStore";
    import _ from 'lodash'

    const router = useRouter()
    const recordStore = useRecordStore()

    const record = ref({});

    onMounted(() => {
        if(recordStore.record === null) {
            router.push({name: 'MyPageRecord'})
        } else {
            record.value = recordStore.record;
        }
    })

    const filterNotDeleted = (list) => {
        return _.filter(list, (el) => el.deleteYn !== 'Y');
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="resume-wrap">
                <div class="self-info">
                    <h1>{{ record.userName }}</h1>
                    <p><span>{{ $phoneNum(record.phoneNum) }}</span><span>{{ record.email }}</span></p>
                </div>
                <!-- s : 기본정보 -->
                <section class="basic">
                    <dl>
                        <dt>관심 카테고리</dt>
                        <dd>{{ record.interests || '-' }}</dd>
                    </dl>
                    <dl>
                        <dt>희망 직무</dt>
                        <dd>{{ record.desiredJob || '-' }}</dd>
                    </dl>
                    <dl>
                        <dt>희망 연봉</dt>
                        <dd>
                            <span v-if="record.desiredSalary">{{ $number(record.desiredSalary) }}만원 이상</span>
                            <span v-else>-</span>
                        </dd>
                    </dl>
                    <div v-if="record.introOpenYn">
                        <h2>{{ record.introAline }}</h2>
                        <p>{{ record.selfIntro }}</p>
                    </div>
                </section>
                <!-- e : 기본정보 -->

                <!-- s : 학력 -->
                <section v-if="record.eduHistOpenYn">
                    <h2>학력</h2>
                    <dl v-for="eduHist in record.eduHistoris">
                        <dt>
                            <b>{{ $yyyymm(eduHist.startYm) }} ~ {{ $yyyymm(eduHist.endYm) }}</b>
                            {{ $gbNameKR('SCHOOL_GB',eduHist.schoolGb) }} {{ $gbNameKR('EDU_TENURE_GB',eduHist.eduTenureGb) }}
                        </dt>
                        <dd>
                            <b>{{ eduHist.schoolName }}</b>
                            <p>주전공 {{ $gbNameKR('MAJOR_GB', eduHist.majorGb) }} {{ eduHist.majorName }}</p>
                            <p v-if="eduHist.submajorName">{{ $gbNameKR('SUBMAJOR_TYPE_GB', eduHist.submajorTypeGb) }} {{ $gbNameKR('MAJOR_GB', eduHist.submajorGb) }} {{ eduHist.submajorName }}</p>
                            <p>{{ eduHist.score }} {{ $gbNameKR('FULL_SCORE_GB', eduHist.fullScoreGb) }}</p>
                            <p class="file" v-if="eduHist.transcriptName">{{ eduHist.transcriptName }}<span v-if="eduHist.transcriptSize">({{ $fileSize(eduHist.transcriptSize) }})</span></p>
                        </dd>
                    </dl>
                </section>
                <!-- e : 학력 -->

                <!-- s : 포트폴리오 -->
                <section v-if="record.portfolioOpenYn">
                    <h2>포트폴리오</h2>
                    <dl v-for="pofol in filterNotDeleted(record.portfolios)">
                        <dt>
                            <b>{{ $yyyymm(pofol.startYm) }} ~ {{ $yyyymm(pofol.endYm) }}</b>
                        </dt>
                        <dd>
                            <b>{{ $gbNameKR('POFOL_GB', pofol.pofolGb) }}</b>
                            <p>{{ pofol.pofolName }}</p>
                            <p>{{ pofol.pofolOrgan }}</p>
                            <p>{{ pofol.pofolContent }}</p>
                            <p class="file" v-for="portfolioAttach in pofol.portfolioAttachs">
                                {{ portfolioAttach.fileName }}<span v-if="portfolioAttach.fileSize">({{ portfolioAttach.fileSize}})</span>
                            </p>
                        </dd>
                    </dl>
                </section>
                <!-- e : 포트폴리오 -->

                <!-- s : 자격증 및 외국어 -->
                <section v-if="record.certForeignOpenYn">
                    <h2>자격증 및 외국어</h2>
                    <div>
                        <h3>자격증</h3>
                        <dl v-for="cert in filterNotDeleted(record.certificates)">
                            <dt>
                                {{ cert.getYy }}
                            </dt>
                            <dd>
                                <b>{{ cert.certName }}</b>
                                <p>{{ cert.certOrgan }}</p>
                            </dd>
                        </dl>
                    </div>
                    <div>
                        <h3>어학 시험</h3>
                        <dl v-for="languageTest in filterNotDeleted(record.languageTests)">
                            <dt>
                                {{ $yyyymm(languageTest.getYmd) }}
                            </dt>
                            <dd>
                                <b>{{ $gbNameKR('LANG_GB', languageTest.langGb) }}</b>
                                <p>시험 종류: {{ languageTest.testType }} / 점수: {{ languageTest.testScore }}</p>
                            </dd>
                        </dl>
                    </div>
                    <div>
                        <h3>외국어</h3>
                        <dl v-for="foreignLanguage in filterNotDeleted(record.foreignLanguages)">
                            <dt>
                                <b>{{ foreignLanguage.foreignLangName }}</b>
                            </dt>
                            <dd>
                                <p>{{ $gbNameKR('LANG_LEVEL_GB', foreignLanguage.langLevelGb) }}</p>
                            </dd>
                        </dl>
                    </div>
                </section>
                <!-- e : 자격증 및 외국어 -->

                <!-- s : 업무 경험 -->
                <section v-if="record.jobHistOpenYn">
                    <h2>업무 경험</h2>
                    <dl v-for="jobHist in filterNotDeleted(record.jobHistorys)">
                        <dt>
                            <b>{{ jobHist.companyName }}</b>
                            {{ $yyyymm(jobHist.startYm) }} ~ {{ jobHist.tenureYn ? '재직중' : $yyyymm(jobHist.endYm) }}
                        </dt>
                        <dd>
                            <p>{{ jobHist.jobTitle }} <span>{{ jobHist.departName }}</span></p>
                            <p>{{ jobHist.jobNote }}</p>
                        </dd>
                    </dl>
                </section>
                <!-- e : 업무 경험 -->

                <!-- s : 병역 -->
                <section class="army" v-if="record.milOpenYn">
                    <dl>
                        <dt><h2>병역</h2></dt>
                        <dd><p>{{ $gbNameKR('MIL_SERVICE_GB', record.milServiceGb) }}</p></dd>
                    </dl>
                </section>
                <!-- e : 병역 -->
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";
    .resume-wrap {
        margin-top: 58px;
        .self-info {
            border-bottom: 2px solid $black_color;
            h1 {
                @include font-size_xl;
                margin-bottom: 10px;
            }
            p {
                @include font-size_md;
                margin-bottom: 20px;
                span {
                    position: relative;
                    margin-left: 20px;
                    padding-left: 20px;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 1px;
                        height: 14px;
                        background: $gray-regular_color;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }
                    &:first-child {
                        padding: 0;
                        margin: 0;
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
        section {
            border-bottom: 1px solid $black_color;
            padding: 30px 0;
            &:last-child {
                border-bottom: 0;
                padding: 30px 0;
            }
            &.basic {
                div {
                    border-top: $border_line;
                    padding-top: 30px;
                    h2 {
                        margin-bottom: 10px;
                    }
                }
            }
            &.army {
                h2 {
                    color: $black_color;
                }
            }
            h2 {
                @include font-size_md;
                margin-bottom: 20px;
            }
            dl {
                display: flex;
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                }
                dt {
                    width: 192px;
                    color: $gray_color;
                    b {
                        display: block;
                        color: $black_color;
                        margin-bottom: 6px;
                    }
                }
                dd {
                    b {
                        display: block;
                        margin-bottom: 6px;
                    }
                    p {
                        span {
                            position: relative;
                            padding-left: 10px;
                            margin-left: 10px;
                            &:before {
                                content: "";
                                position: absolute;
                                top: 50%;
                                left: 0;
                                width: 1px;
                                height: 10px;
                                background: $gray-regular_color;
                                -webkit-transform: translateY(-50%);
                                transform: translateY(-50%);
                            }
                        }
                    }
                    .file {
                        color: $blue_color;
                        cursor: pointer;
                    }
                }
            }
            div {
                padding-bottom: 20px;
                border-bottom: $border_line;
                &:last-child {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
                h3 {
                    @include font-size_md;
                    color: $gray_color;
                    margin-top: 20px;
                    margin-bottom: 6px;
                }
            }
        }
    }
</style>