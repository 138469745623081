<script setup>
    import { ref, computed, onMounted, getCurrentInstance } from 'vue'
    import { useRouter } from 'vue-router'
    import { useUserStore } from "../../stores/user";
    import { usePageLoadingStore } from "../../../../stores/pageLoading";
    import SNB from '../../../../layouts/nav/SNB.vue'
    import mypageUserService from "../../services/mypage/mypageUserService";
    import _ from 'lodash'
    import signupService from "../../services/signup/signupService";

    const router = useRouter()
    const userStore = useUserStore()
    const loadingStore = usePageLoadingStore()

    const globals = getCurrentInstance().appContext.config.globalProperties;
    const { $toast, $regex, $userGbKR } = globals;
    const { isAdmin } = userStore;

    onMounted(()=>{
        const { userLoginId } = userStore.user;
        if(userLoginId) {
            input.value.userLoginId = userLoginId;
        }

        _.each(data_interests.value, el => {el.on = false;})
        _.each(data_desiredJob.value, el => {el.on = false;})
    })

    const isAuthenticated = ref(false);
    const isPhoneAuthenticated = ref(false);

    const input = ref({
        userLoginId: '',
        password: '',
        authCode: '',
        corporationNum1: '', // 법인등록번호(앞자리)
        corporationNum2: '', // 법인등록번호(뒷자리)
        ownerName: '', // 대표명
        anniversary: '', // 창립일
        scale: 30, // 인원
        groups: [], // 산업군
    })

    const inputError = ref({
        userLoginId: '',
        password: '',
        authCode: '',
        corporationNum1: '', // 법인등록번호(앞자리)
        corporationNum2: '', // 법인등록번호(뒷자리)
        ownerName: '', // 대표명
        anniversary: '', // 창립일
        scale: 30, // 인원
        groups: [], // 산업군
    })

    const user = ref({});

    const isEditPhoneNum = ref(false);
    let phoneVerifyId;


    const userNameRef = ref(null);
    const birthYmdRef = ref(null);
    const schoolRef = ref(null);
    const verifyCheckInputRef = ref(null);
    const formEmailRef = ref(null);
    const eduHistGbRef = ref(null);
    const residenceAreaRef = ref(null);
    const phoneNumInput = ref(null)
    const companyNumRef = ref(null)
    const corpNum1Ref = ref(null)
    const corpNum2Ref = ref(null)

    const data_interests = ref(globals.$interests)
    const data_desiredJob = ref(globals.$desiredJob)

    const onSelectedArea1Option = (option) => {
        user.value.residenceArea1 = option;
    }

    const onSelectedArea2Option = (option) => {
        user.value.residenceArea2 = option;
    }

    const submit = () => {
    }


    const modal = ref(null)
    const modalContent = ref([]);

    const auth = () => {
        const { password } = input.value;
        let isInvalid = false;

        inputError.value.password = ''
        if(!password) {
            inputError.value.password = '비밀번호를 입력해주세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            loadingStore.loading = true;
            mypageUserService.checkPassword(password)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        isAuthenticated.value = true;
                        retrieveUserInfo();
                    } else {
                        modalContent.value = ["비밀번호가 올바르지 않습니다."];
                        modal.value.show();
                    }
                }).catch( (res) =>  {
                loadingStore.loading = false;
                // console.log(res);
                modalContent.value = [res.response.data.result.message];
                modal.value.show();
            });
        }
    }

    const retrieveUserInfo = () => {
        loadingStore.loading = true;
        mypageUserService.retrieveUserInfo()
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);

                    const { userGb, phoneNum, email, eduHistGb, residenceArea1, residenceArea2, corporation } = result;

                    result.userGbName = $userGbKR(userGb);

                    if(phoneNum) {
                        result.phoneNum = phoneNum.substring(0,3) + '-' + phoneNum.substring(3,7) + '-' + phoneNum.substring(7);
                    }

                    if(email) {
                        const emailTemp = email.split('@');
                        // console.log(emailTemp)
                        result.email1 = emailTemp[0];
                        result.email2 = emailTemp[1];
                        setTimeout(()=>{
                            formEmailRef.value.init(result.email1, result.email2);
                        },1000)
                    }

                    if(result.interests !== null && result.interests !== '') {
                        _.each(data_interests.value, el => {
                            el.on = result.interests.indexOf(el.label) > -1;
                        })
                    }

                    if(result.desiredJob !== null && result.desiredJob !== '') {
                        _.each(data_desiredJob.value, el => {
                            el.on = result.desiredJob.indexOf(el.label) > -1;
                        })
                    }

                    if(result.ableClass !== null && result.ableClass !== '') {
                        _.each(data_interests.value, el => {
                            el.on = result.ableClass.indexOf(el.label) > -1;
                        })
                    }

                    if(result.expertField !== null && result.expertField !== '') {
                        _.each(data_desiredJob.value, el => {
                            el.on = result.expertField.indexOf(el.label) > -1;
                        })
                    }

                    if(eduHistGb) {
                        setTimeout(()=>{
                            eduHistGbRef.value.initSelectedOption(eduHistGb);
                        },1000)
                    }
                    if(residenceArea1 || residenceArea2) {
                        setTimeout(()=>{
                            residenceAreaRef.value.initSelectedOption(residenceArea1, residenceArea2)
                        },1000)
                    }

                    if(corporation !== null ) {
                        const { bizNum, corpNum } = corporation;
                        if(bizNum) {
                            result.corporation.bizNum = bizNum.substring(0,3) + '-' + bizNum.substring(3,5) + '-' + bizNum.substring(5);
                        }
                        if(corpNum) {
                            result.corporation.corpNum1 = corpNum.substring(0,6);
                            result.corporation.corpNum2 = corpNum.substring(6);
                        }
                    }

                    user.value = result;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    // 이름에 한글,영어대문자만 포함되어 있는지 validation.
    const verifyUserName = (userName) => {
      const regex = /^[가-힣A-Z]+$/;
      return regex.test(userName);
    }

    // 핸드폰 번호 변경 위해 인증번호 발송 시, validation
    const sendAuthCode = () => {
        const name = user.value.userName;

        let isInvalid = false;

        inputError.value.name = '';
        inputError.value.phoneNum = '';

        if(!name || !verifyUserName(name)) {
            inputError.value.userName = '주민등록상의 실명(한글 본명)을 입력해 주세요.';
            isInvalid = true;
        }

        const phoneNum = phoneNumInput.value.getValue();
        user.value.phoneNum = phoneNum;
        if(!phoneNum) {
            inputError.value.phoneNum = '핸드폰 번호를 입력하세요';
            isInvalid = true;
        } else if(phoneNum.length !== 13 || !$regex.phoneNum.test(phoneNum)){ // 영문/국문 입력시, 11자리 아닌경우
            inputError.value.phoneNum = '핸드폰 번호를 올바르게 입력하세요';
            isInvalid = true;
        }

        if(!isInvalid) {
            user.value.authCode = '';
            inputError.value.authCode = '';

            loadingStore.loading = true;
            signupService.phoneVerify({ verifyGubun: "changenum", name, phoneNum })
                .then( (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        phoneVerifyId = result.phoneVerifyId;
                        verifyCheckInputRef.value.startTimer()

                        modalContent.value = ["인증번호를 전송하였습니다."];
                        modal.value.show();
                    }
                }).catch( (error) =>  {
                loadingStore.loading = false;
                console.log(error);
            });
        }
    }

    const verifyCheck = () => {
        const { phoneNum, authCode } = user.value;

        let isInvalid = false;

        inputError.value.authCode = '';
        if(!authCode || authCode.length !== 6) {
            inputError.value.authCode = '인증번호 6자리를 입력해주세요.';
            isInvalid = true;
        }

        if(!isInvalid) {
            let paramObj = {
                verifyGubun: "changenum",
                phoneVerifyId,
                phoneNum,
                verifyNum: authCode
            }

            loadingStore.loading = true;
            signupService.phoneVerifyCheck(paramObj)
                .then( (res) => {
                    loadingStore.loading = false;
                    const { code, msg } = res.data;
                    if(code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        inputError.value.authCode = '인증번호를 확인하였습니다.';
                        isPhoneAuthenticated.value = true;
                        verifyCheckInputRef.value.clearTimer()
                        isEditPhoneNum.value = false;

                        modalContent.value = ["인증번호를 확인하였습니다."];
                        modal.value.show();

                    } else { // fail
                        if(msg.indexOf('PhoneVerify Number different') > -1) {
                            inputError.value.authCode = '인증번호가 올바르지 않습니다.'; // msg;
                        } else if(msg.indexOf('PhoneVerify 시간초과~') > -1) {
                            inputError.value.authCode = '인증번호 입력 시간이 모두 지났습니다. 인증번호를 다시 전송하세요'; // msg;
                        } else if (msg.indexOf('Not Found User') > -1) {
                            modalContent.value = [
                                "입력한 정보와 일치하는 회원정보가 없습니다.",
                                "다시 입력하여 시도하거나 회원가입을 해주세요"
                            ];
                            modal.value.show();

                            user.value.authCode = '';
                            verifyCheckInputRef.value.clearTimer()
                        }
                        isPhoneAuthenticated.value = false;
                    }
                }).catch( (error) =>  {
                loadingStore.loading = false;
                console.log(error);
            });
        }
    }

    const onUpdateErrorMsg = (value) => {
        inputError.value.authCode = value;
    }

    const onUpdateCorp = (corp) => {
        user.value.corporation.corpId = corp.corpId;
        user.value.corporation.corpName = corp.corpName;

        inputError.value.corpName = '';
        if(corp.corpId === 0) {
            inputError.value.corpName = '사업자등록번호가 올바르지 않습니다. 다시 입력하세요.';
        }
    }


    const save = () => {
        let isInvalid = false;
        let focused = false;
        const _user = _.cloneDeep(user.value);
        const { corporation } = _user;

        inputError.value = {};
        if(!_user.userName || !verifyUserName(_user.userName)) {
            inputError.value.userName = `${_user.userGb === 'SCHOOL' ? '담당자 ' : ''}주민등록상의 실명(한글 본명)을 입력해 주세요.`;
            isInvalid = true;
            if(!focused) {
                userNameRef.value.focus();
                focused = true;
            }
        }
        if(!_user.phoneNum) {
            inputError.value.phoneNum = '핸드폰 번호를 입력하세요.';
            isInvalid = true;
            if(!focused) {
                phoneNumInput.value.focus();
                focused = true;
            }
        }
        if(!_user.email1 || !_user.email2) {
            inputError.value.email = '이메일을 입력하세요.';
            isInvalid = true;
            if(!focused) {
                formEmailRef.value.focus(!!_user.email1);
                focused = true;
            }
        }
        if(!_user.birthYmd) {
            inputError.value.birthYmd = '생년월일을 입력하세요.';
            isInvalid = true;
            if(!focused) {
                birthYmdRef.value.focus();
                focused = true;
            }
        } else if(_user.birthYmd.length !== 8) {
            inputError.value.birthYmd = '생년월일을 YYYYMMDD 형식으로 입력하세요.';
            isInvalid = true;
            if(!focused) {
                birthYmdRef.value.focus();
                focused = true;
            }
        }
        if((_user.residenceArea1 === '' || _user.residenceArea1 === '시/도 선택') ||
            (_user.residenceArea2 === '' || _user.residenceArea2 === '시/구/군 선택') ) {
            inputError.value.residenceArea = (_user.residenceArea1 === '' || _user.residenceArea1 === '시/도 선택') ? '시/도를 선택하세요.' : '시/구/군을 선택하세요.';
            isInvalid = true;
        }

        if(_user.userGb === 'STUDENT' || _user.userGb === 'SCHOOL') { // 학교, 학력
            if(!_user.school) {
                inputError.value.school = '학교를 입력하세요.';
                isInvalid = true;
                if(!focused) {
                    schoolRef.value.focus();
                    focused = true;
                }
            }
            if(!_user.eduHistGb) {
                inputError.value.eduHistGb = '학력을 선택하세요.';
                isInvalid = true;
            }
        } else if(_user.userGb === 'COMPANY') {
            if(!_user.corporation.bizNum || _user.corporation.bizNum.length !== 12) {
                inputError.value.companyNum = !_user.corporation.bizNum ? '사업자등록번호를 입력하세요.' : '10자리 숫자를 입력하세요.';
                isInvalid = true;
                if(!focused) {
                    companyNumRef.value.focus();
                    focused = true;
                }
            } else if(_user.corporation.corpId === 0) {
                inputError.value.corpName = '사업자등록번호가 올바르지 않습니다. 다시 입력하세요.';
                isInvalid = true;
            }

            if(user.value.corpMasterYn === 'Y') { // 재직자/기업 - 마스터
                if(!_user.corporation.corpNum1 || !_user.corporation.corpNum2) {
                    inputError.value.corpNum = '법인등록번호를 입력하세요.';
                    isInvalid = true;
                    if(!focused) {
                        if(!_user.corporation.corpNum1) {
                            corpNum1Ref.value.focus();
                        } else if(!_user.corporation.corpNum2) {
                            corpNum2Ref.value.focus();
                        }
                        focused = true;
                    }
                }
            }
        }
        // console.log(_user);
        // console.log(inputError);

        const interests = _.map(_.filter(data_interests.value, {'on': true}), 'value').join(',');
        const desiredJob = _.map(_.filter(data_desiredJob.value, {'on': true}), 'value').join(',');
        if(_user.userGb === 'STUDENT' || _user.userGb === 'COMPANY'){
            _user.interests = interests;
            _user.desiredJob = desiredJob;
        } else if(_user.userGb === 'TEACHER') {
            _user.ableClass = interests;
            _user.expertField = desiredJob;
        }

        if(!isInvalid) {
            let paramObj = {
                userGb: _user.userGb,
                school: _user.school,
                eduHistGb: _user.eduHistGb,
                userName: _user.userName,
                phoneNum: _user.phoneNum ? _user.phoneNum.replace('-','').replace('-','') : _user.phoneNum,
                email: `${_user.email1}@${_user.email2}`,
                birthYmd: _user.birthYmd,
                sex: _user.sex,
                corpMasterYn: _user.corpMasterYn,
                residenceArea1: _user.residenceArea1,
                residenceArea2: _user.residenceArea2,
                interests: _user.interests,
                desiredJob: _user.desiredJob,
                professorName: _user.professorName,
                labName: _user.labName,
                ableClass: _user.ableClass,
                expertField: _user.expertField,
                // modifyId: 0
            }
            // console.log(paramObj);

            if(corporation !== null && (corporation.corpId !== undefined && corporation.corpId !== null)) {
                paramObj.corporation = {
                    corpId: corporation.corpId,
                    corpName: corporation.corpName,
                    corpNum: corporation.corpNum1 + '' + corporation.corpNum2,
                    bizNum: corporation.bizNum ? corporation.bizNum.replace('-','').replace('-','') : corporation.bizNum ,
                    ceoName: corporation.ceoName,
                    foundYm: corporation.foundYm,
                    employeeNumGb: corporation.employeeNumGb,
                    industryGb: corporation.industryGb,
                    job: corporation.job,
                    relateClass: corporation.relateClass,
                    bizStateGb: corporation.bizStateGb,
                    bizStateName: corporation.bizStateName,
                    taxTypeGb: corporation.taxTypeGb,
                    taxTypeName: corporation.taxTypeName,
                    expireYmd: corporation.expireYmd ? corporation.expireYmd.replace('.','').replace('.','') : corporation.expireYmd,
                    // modifyId: 0
                };
            }

            loadingStore.loading = true;
            mypageUserService.updateUserInfo(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        $toast('', '저장하였습니다.');
                        if(userStore.user.userName !== user.value.userName) {
                            userStore.setUserName(user.value.userName);
                        }
                    } else {
                        modalContent.value = ["저장을 실패하였습니다."];
                        modal.value.show();
                    }
                }).catch( (error) =>  {
                loadingStore.loading = false;
                // console.log(error);
                modalContent.value = ["오류가 발생했습니다. 잠시 후 다시 시도해주세요."];
                modal.value.show();
            });
        }
    }

    const cancel = () => {
        isAuthenticated.value = false;
        input.value.password = '';
    }

    const getUserGbName = (userGb) => {
        switch (userGb) {
            case 'STUDENT':
                return '학생/취업준비생';
            case 'COMPANY':
                return '재직자/기업';
            case 'SCHOOL':
                return '대학/연구실';
            case 'TEACHER':
                return '강사/전문가';
            default:
                return '-';
        }
    }



    const onBlurEmail = () => {
        const { email1, email2 } = user.value;
        const email = `${email1}@${email2}`;
        if(!$regex.email.test(email)) {
            inputError.value.email = '올바른 이메일 형식이 아닙니다. 다시 입력해 주세요.';
        } else {
            inputError.value.email = '';
        }
    }

    const onBlurCompNum = () => {
        const { corporation : { bizNum } } = user.value;
        if(!$regex.compNum.test(bizNum)) {
            inputError.value.companyNum = '올바른 사업자등록번호 형식이 아닙니다. 다시 입력해 주세요.';
        } else {
            inputError.value.companyNum = '';
        }
    }

    const onBlurCorpNum = () => {
        const { corporation : { corpNum1, corpNum2 } } = user.value;
        const corpNum = `${corpNum1}-${corpNum2}`;
        if(!$regex.corpNum.test(corpNum)) {
            inputError.value.corpNum = '올바른 법인등록번호 형식이 아닙니다. 다시 입력해 주세요.';
        } else {
            inputError.value.corpNum = '';
        }
    }

    const interestCount = computed(()=>{
        return _.filter(data_interests.value, {'on': true}).length || 0;
    })

    const onClickInterest = (interest) => {
        inputError.value.interests = '';
        if(interest.on) {
            interest.on  = false;
        } else {
            if(interestCount.value < 3) {
                interest.on = true;
            } else {
                inputError.value.interests = '최대 3개까지 선택할 수 있습니다.';
            }
        }
    }

    const desiredJobCount = computed(()=>{
        return _.filter(data_desiredJob.value, {'on': true}).length || 0;
    })

    const onClickDesiredJob = (desiredJob) => {
        inputError.value.desiredJob = '';
        if(desiredJob.on) {
            desiredJob.on  = false;
        } else {
            if(desiredJobCount.value < 3) {
                desiredJob.on = true;
            } else {
                inputError.value.desiredJob = '최대 3개까지 선택할 수 있습니다.';
            }
        }
    }
</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="flex-wrap">
                <SNB class="snb" />
                <div class="content" v-if="!isAuthenticated">
                    <div class="sub-title">
                        <h3>내 정보</h3>
                        <KSATextButton class="btn gray_button" text="회원탈퇴" @click="router.push({name:'MyPageProfileWithdraw'})" />
                    </div>
                    <div class="sign-wrap">
                        <div class="sign-inner">
                            <p class="text text-center">회원님의 정보를 안전하게 보호하기 위해 비밀번호를 입력해주세요</p>
                            <div class="sign-content">
                                <KSAInput class="text-box" v-model="input.userLoginId" label="아이디" placeholder="아이디 입력하세요." disabled />
                                <KSAInput type="password" class="text-box" v-model="input.password" label="비밀번호" placeholder="비밀번호 입력하세요." :msg="inputError.password" max-length="30"/>
                                <KSAPrimaryButton class="full-btn" text="확인" @click="auth" />
                            </div>
                            <ul class="member">
                                <li><RouterLink :to="{name:'MyPageProfilePWReset'}">비밀번호 변경</RouterLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="content" v-if="isAuthenticated">
                    <div class="sub-title">
                        <h3>내 정보</h3>
                        <p><span>*표시는 필수 입력사항</span>입니다.</p>
                    </div>
                    <div class="sign-wrap">
                        <div class="sign-inner">
                            <div class="sign-content">
                                <KSAInput label="회원유형" class="text-box" v-model="user.userGbName" :disabled="true" />
                                <KSAInput label="회원코드" class="text-box" v-model="user.userCd" :disabled="true" />
                                <KSAInput label="아이디" class="text-box" v-model="user.userLoginId" placeholder="아이디를 입력해주세요" :disabled="true" />

                                <!-- 학생/취업준비생 -->
                                <template v-if="user.userGb === 'STUDENT'">
                                    <KSAInput ref="userNameRef" label="이름" class="text-box mark" v-model="user.userName" placeholder="주민등록상의 실명(한글 본명)을 입력해 주세요. ex)홍길동" :msg="inputError.userName" max-length="16"/>
                                    <div class="text-box">
                                        <p class="label mark">핸드폰 번호</p>
                                        <div class="grid phone">
                                            <KSAInputPhoneNum ref="phoneNumInput" class="input" v-model="user.phoneNum" label="" :disabled="!isEditPhoneNum"/>
                                            <KSASecondaryButton v-if="!isEditPhoneNum" class="ml-xs" text="핸드폰 번호 변경" @click="isEditPhoneNum = true" />
                                            <KSAPrimaryButton v-if="isEditPhoneNum" class="ml-xs" text="인증번호 보내기" @click="sendAuthCode" />
                                        </div>
                                        <KSAInputVerifyCheck v-if="isEditPhoneNum" class="mt-xs" ref="verifyCheckInputRef" v-model="user.authCode" @update:msg="onUpdateErrorMsg" :msg="inputError.authCode" :msg-color="isPhoneAuthenticated && 'black'"/>
                                        <KSAPrimaryButton v-if="isEditPhoneNum && !isPhoneAuthenticated" class="mt-xs" text="인증번호 확인" @click="verifyCheck" :disabled="user.authCode && user.authCode.length !== 6" />
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.phoneNum }}</small>
                                        </div>
                                   </div>
                                    <div class="text-box">
                                        <p class="label mark">이메일</p>
                                        <KSAFormEmail
                                            ref="formEmailRef"
                                            :email1="user.email1"
                                            :email2="user.email2"
                                            @update:email1="(value)=>{user.email1 = value}"
                                            @update:email2="(value)=>{user.email2 = value}"
                                            @blur="onBlurEmail"
                                        />
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.email }}</small>
                                        </div>
                                    </div>
                                    <KSAInput ref="birthYmdRef" label="생년월일" class="text-box mark" v-model="user.birthYmd" placeholder="YYYYMMDD 8자리 숫자 입력" :msg="inputError.birthYmd" max-length="8"/>
                                    <div class="text-box">
                                        <p class="label">성별</p>
                                        <KSARadioButton class="mr-lg" name="sex" label="남자" v-model="user.sex" value="01" />
                                        <KSARadioButton class="mr-lg" name="sex" label="여자" v-model="user.sex" value="02" />
                                    </div>

                                    <KSAInputSchoolSelect ref="schoolRef" label="학교" class="text-box mark" v-model="user.school" placeholder="학교를 입력하세요." :msg="inputError.school"  msg-color="#E71414" />
                                    <div class="text-box">
                                        <p class="label mark">학력</p>
                                        <KSASelect ref="eduHistGbRef" class="select" v-model="user.eduHistGb" :options="$gb.EDU_HIST_GB"/>
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.eduHistGb }}</small>
                                        </div>
                                    </div>
                                    <div class="text-box">
                                        <p class="label mark">거주 지역</p>
                                        <KSASelectResidenceArea ref="residenceAreaRef" class="select" @selectOption1="onSelectedArea1Option" @selectOption2="onSelectedArea2Option"/>
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.residenceArea }}</small>
                                        </div>
                                    </div>
                                    <div class="text-box">
                                        <p class="label" :class="{'mark': false}" style="margin: 0">희망 직무 (최대 3개)</p>
                                        <ul class="grid category-wrap">
                                            <li :class="{'active': desiredJob.on}" v-for="desiredJob in data_desiredJob" @click="onClickDesiredJob(desiredJob)">
                                                {{ desiredJob.label }}
                                            </li>
                                        </ul>
                                        <p>
                                            <small style="color:#E71414;">{{ inputError.desiredJob }}</small>
                                        </p>
                                    </div>
                                    <div class="text-box">
                                        <p class="label" style="margin: 0">관심 분야 (최대 3개)</p>
                                        <ul class="grid category-wrap">
                                            <li :class="{'active': interest.on}" v-for="interest in data_interests" @click="onClickInterest(interest)">
                                                {{ interest.label }}
                                            </li>
                                        </ul>
                                        <p>
                                            <small style="color:#E71414;">{{ inputError.interests }}</small>
                                        </p>
                                    </div>
                                </template>

                                <!-- 재직자/기업 -->
                                <template v-else-if="user.userGb === 'COMPANY'">
                                    <KSAInput ref="userNameRef" label="이름" class="text-box mark" v-model="user.userName" placeholder="주민등록상의 실명(한글 본명)을 입력해 주세요. ex)홍길동" :msg="inputError.userName" max-length="16"/>
                                    <div class="text-box">
                                        <p class="label mark">핸드폰 번호</p>
                                        <div class="grid phone">
                                            <KSAInputPhoneNum ref="phoneNumInput" class="input" v-model="user.phoneNum" label="" :disabled="!isEditPhoneNum"/>
                                            <KSASecondaryButton v-if="!isEditPhoneNum" class="ml-xs" text="핸드폰 번호 변경" @click="isEditPhoneNum = true" />
                                            <KSAPrimaryButton v-if="isEditPhoneNum" class="ml-xs" text="인증번호 보내기" @click="sendAuthCode" />
                                        </div>
                                        <KSAInputVerifyCheck v-if="isEditPhoneNum" class="mt-xs" ref="verifyCheckInputRef" v-model="user.authCode" @update:msg="onUpdateErrorMsg" :msg="inputError.authCode" :msg-color="isPhoneAuthenticated && 'black'"/>
                                        <KSAPrimaryButton v-if="isEditPhoneNum && !isPhoneAuthenticated" class="mt-xs" text="인증번호 확인" @click="verifyCheck" :disabled="user.authCode && user.authCode.length !== 6" />
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.phoneNum }}</small>
                                        </div>
                                    </div>
                                    <div class="text-box">
                                        <p class="label mark">이메일</p>
                                        <KSAFormEmail
                                            ref="formEmailRef"
                                            :email1="user.email1"
                                            :email2="user.email2"
                                            @update:email1="(value)=>{user.email1 = value}"
                                            @update:email2="(value)=>{user.email2 = value}"
                                            @blur="onBlurEmail"
                                        />
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.email }}</small>
                                        </div>
                                    </div>
                                    <KSAInput ref="birthYmdRef" label="생년월일" class="text-box mark" v-model="user.birthYmd" placeholder="YYYYMMDD 8자리 숫자 입력" :msg="inputError.birthYmd" max-length="8"/>
                                    <div class="text-box">
                                        <p class="label">성별</p>
                                        <KSARadioButton class="mr-lg" name="sex" label="남자" v-model="user.sex" value="01" />
                                        <KSARadioButton class="mr-lg" name="sex" label="여자" v-model="user.sex" value="02" />
                                    </div>

                                    <KSAInputCompNum
                                        ref="companyNumRef"
                                        class="text-box mark"
                                                     v-model="user.corporation.bizNum"
                                                     @update:corp="onUpdateCorp"
                                                     @blur="onBlurCompNum"
                                                     :msg="inputError.companyNum"
                                                     placeholder="10자리 숫자 입력"
                                    />
                                    <KSAInput label="기업명" class="text-box" v-model="user.corporation.corpName" :msg="inputError.corpName" :disabled="true" />

                                    <div class="text-box">
                                        <p class="label mark">거주 지역</p>
                                        <KSASelectResidenceArea ref="residenceAreaRef" class="select" @selectOption1="onSelectedArea1Option" @selectOption2="onSelectedArea2Option"/>
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.residenceArea }}</small>
                                        </div>
                                    </div>

                                    <div class="text-box">
                                        <p class="label" :class="{'mark': false}" style="margin: 0">본인 직무 (최대 3개)</p>
                                        <ul class="grid category-wrap">
                                            <li :class="{'active': desiredJob.on}" v-for="desiredJob in data_desiredJob" @click="onClickDesiredJob(desiredJob)">
                                                {{ desiredJob.label }}
                                            </li>
                                        </ul>
                                        <p>
                                            <small style="color:#E71414;">{{ inputError.desiredJob }}</small>
                                        </p>
                                    </div>
                                    <div class="text-box">
                                        <p class="label" style="margin: 0">관심 분야 (최대 3개)</p>
                                        <ul class="grid category-wrap">
                                            <li :class="{'active': interest.on}" v-for="interest in data_interests" @click="onClickInterest(interest)">
                                                {{ interest.label }}
                                            </li>
                                        </ul>
                                        <p>
                                            <small style="color:#E71414;">{{ inputError.interests }}</small>
                                        </p>
                                    </div>

                                    <hr />

                                    <h5>기업 정보</h5>
                                    <div class="text-box">
                                        <p class="label mark">법인등록번호</p>
                                        <div class="grid half">
                                            <KSAInput ref="corpNum1Ref" class="input" v-model="user.corporation.corpNum1" @blur="onBlurCorpNum" placeholder="6자리 숫자 입력" max-length="6" :disabled="user.corpMasterYn !== 'Y'"/>
                                            <span class="ml-xs mr-xs">-</span>
                                            <KSAInput ref="corpNum2Ref" class="input" v-model="user.corporation.corpNum2" @blur="onBlurCorpNum" placeholder="7자리 숫자 입력" max-length="7" :disabled="user.corpMasterYn !== 'Y'"/>
                                        </div>
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.corpNum }}</small>
                                        </div>
                                    </div>
                                    <KSAInput class="text-box" v-model="user.corporation.ceoName" label="대표명" placeholder="대표명을 입력하세요." :disabled="user.corpMasterYn !== 'Y'" />
                                    <KSAInputDateYYYYMM class="text-box" v-model="user.corporation.expireYmd" label="창립연월" placeholder="YYYYMMDD 6자리 숫자 입력" format="YYYYMMDD" max-length="8" :disabled="user.corpMasterYn !== 'Y'"/>
                                    <div class="text-box">
                                        <p class="label">인원</p>
                                        <template v-for="option in $gb.EMPLOYEE_NUM_GB">
                                            <KSARadioButton class="check-box mr-lg" name="employeeNumGb" :label="option.label" v-model="user.corporation.employeeNumGb" :value="option.value" :disabled="user.corpMasterYn !== 'Y'"/>
                                        </template>
                                    </div>
                                    <div class="text-box">
                                        <p class="label">산업군</p>
                                        <template v-for="option in $gb.INDUSTRY_GB">
                                            <KSARadioButton class="check-box mr-lg" name="industryGb" :label="option.label" v-model="user.corporation.industryGb" :value="option.value" :disabled="user.corpMasterYn !== 'Y'"/>
                                        </template>
                                    </div>
                                </template>

                                <!-- 대학/연구실 -->
                                <template v-if="user.userGb === 'SCHOOL'">
                                    <KSAInputSchoolSelect ref="schoolRef" label="학교" class="text-box mark" v-model="user.school" placeholder="학교를 입력하세요." :msg="inputError.school"  msg-color="#E71414" />
                                    <div class="text-box">
                                        <p class="label mark">학력</p>
                                        <KSASelect ref="eduHistGbRef" class="select" v-model="user.eduHistGb" :options="$gb.EDU_HIST_GB"/>
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.eduHistGb }}</small>
                                        </div>
                                    </div>
                                    <KSAInput ref="userNameRef" label="담당자 이름" class="text-box mark" v-model="user.userName" placeholder="주민등록상의 실명(한글 본명)을 입력해 주세요. ex)홍길동"  :msg="inputError.userName" max-length="16"/>
                                    <div class="text-box">
                                        <p class="label mark">핸드폰 번호</p>
                                        <div class="grid phone">
                                            <KSAInputPhoneNum ref="phoneNumInput" class="input" v-model="user.phoneNum" label="" :disabled="!isEditPhoneNum"/>
                                            <KSASecondaryButton v-if="!isEditPhoneNum" class="ml-xs" text="핸드폰 번호 변경" @click="isEditPhoneNum = true" />
                                            <KSAPrimaryButton v-if="isEditPhoneNum" class="ml-xs" text="인증번호 보내기" @click="sendAuthCode" />
                                        </div>
                                        <KSAInputVerifyCheck v-if="isEditPhoneNum" class="mt-xs" ref="verifyCheckInputRef" v-model="user.authCode" @update:msg="onUpdateErrorMsg" :msg="inputError.authCode" :msg-color="isPhoneAuthenticated && 'black'"/>
                                        <KSAPrimaryButton v-if="isEditPhoneNum && !isPhoneAuthenticated" class="mt-xs" text="인증번호 확인" @click="verifyCheck" :disabled="user.authCode && user.authCode.length !== 6" />
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.phoneNum }}</small>
                                        </div>
                                    </div>
                                    <div class="text-box">
                                        <p class="label mark">이메일</p>
                                        <KSAFormEmail
                                            ref="formEmailRef"
                                            :email1="user.email1"
                                            :email2="user.email2"
                                            @update:email1="(value)=>{user.email1 = value}"
                                            @update:email2="(value)=>{user.email2 = value}"
                                            @blur="onBlurEmail"
                                        />
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.email }}</small>
                                        </div>
                                    </div>
                                    <KSAInput ref="birthYmdRef" label="생년월일" class="text-box mark" v-model="user.birthYmd" placeholder="YYYYMMDD 8자리 숫자 입력" :msg="inputError.birthYmd" max-length="8"/>
                                    <div class="text-box">
                                        <p class="label">성별</p>
                                        <KSARadioButton class="mr-lg" name="sex" label="남자" v-model="user.sex" value="01" />
                                        <KSARadioButton class="mr-lg" name="sex" label="여자" v-model="user.sex" value="02" />
                                    </div>
                                    <div class="text-box">
                                        <p class="label mark">거주 지역</p>
                                        <KSASelectResidenceArea ref="residenceAreaRef" class="select" @selectOption1="onSelectedArea1Option" @selectOption2="onSelectedArea2Option"/>
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.residenceArea }}</small>
                                        </div>
                                    </div>
                                    <KSAInput label="교수님 이름" class="text-box" v-model="user.professorName" placeholder="교수님 이름을 입력하세요." max-length="30" />
                                    <KSAInput label="연구실 이름" class="text-box" v-model="user.labName" placeholder="연구실 이름을 입력하세요." max-length="30" />
                                </template>

                                <!-- 강사/전문가 -->
                                <template v-if="user.userGb === 'TEACHER'">
                                    <KSAInput ref="userNameRef" label="이름" class="text-box mark" v-model="user.userName" placeholder="주민등록상의 실명(한글 본명)을 입력해 주세요. ex)홍길동" :msg="inputError.userName" max-length="16"/>
                                    <div class="text-box">
                                        <p class="label mark">핸드폰 번호</p>
                                        <div class="grid phone">
                                            <KSAInputPhoneNum ref="phoneNumInput" class="input" v-model="user.phoneNum" label="" :disabled="!isEditPhoneNum"/>
                                            <KSASecondaryButton v-if="!isEditPhoneNum" class="ml-xs" text="핸드폰 번호 변경" @click="isEditPhoneNum = true" />
                                            <KSAPrimaryButton v-if="isEditPhoneNum" class="ml-xs" text="인증번호 보내기" @click="sendAuthCode" />
                                        </div>
                                        <KSAInputVerifyCheck v-if="isEditPhoneNum" class="mt-xs" ref="verifyCheckInputRef" v-model="user.authCode" @update:msg="onUpdateErrorMsg" :msg="inputError.authCode" :msg-color="isPhoneAuthenticated && 'black'"/>
                                        <KSAPrimaryButton v-if="isEditPhoneNum && !isPhoneAuthenticated" class="mt-xs" text="인증번호 확인" @click="verifyCheck" :disabled="user.authCode && user.authCode.length !== 6" />
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.phoneNum }}</small>
                                        </div>
                                    </div>
                                    <div class="text-box">
                                        <p class="label mark">이메일</p>
                                        <KSAFormEmail
                                            ref="formEmailRef"
                                            :email1="user.email1"
                                            :email2="user.email2"
                                            @update:email1="(value)=>{user.email1 = value}"
                                            @update:email2="(value)=>{user.email2 = value}"
                                            @blur="onBlurEmail"
                                        />
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.email }}</small>
                                        </div>
                                    </div>
                                    <KSAInput ref="birthYmdRef" label="생년월일" class="text-box mark" v-model="user.birthYmd" placeholder="YYYYMMDD 8자리 숫자 입력" :msg="inputError.birthYmd" max-length="8"/>
                                    <div class="text-box">
                                        <p class="label">성별</p>
                                        <KSARadioButton class="mr-lg" name="sex" label="남자" v-model="user.sex" value="01" />
                                        <KSARadioButton class="mr-lg" name="sex" label="여자" v-model="user.sex" value="02" />
                                    </div>
                                    <div class="text-box">
                                        <p class="label mark">거주 지역</p>
                                        <KSASelectResidenceArea ref="residenceAreaRef" class="select" @selectOption1="onSelectedArea1Option" @selectOption2="onSelectedArea2Option"/>
                                        <div>
                                            <small style="color:#E71414;">{{ inputError.residenceArea }}</small>
                                        </div>
                                    </div>
                                    <div class="text-box">
                                        <p class="label" style="margin: 0">교육 가능 과목 (최대 3개)</p>
                                        <ul class="grid category-wrap">
                                            <li :class="{'active': interest.on}" v-for="interest in data_interests" @click="onClickInterest(interest)">
                                                {{ interest.label }}
                                            </li>
                                        </ul>
                                        <p>
                                            <small style="color:#E71414;">{{ inputError.interests }}</small>
                                        </p>
                                    </div>
                                    <div class="text-box">
                                        <p class="label" :class="{'mark': false}" style="margin: 0">전문 분야 (최대 3개)</p>
                                        <ul class="grid category-wrap">
                                            <li :class="{'active': desiredJob.on}" v-for="desiredJob in data_desiredJob" @click="onClickDesiredJob(desiredJob)">
                                                {{ desiredJob.label }}
                                            </li>
                                        </ul>
                                        <p>
                                            <small style="color:#E71414;">{{ inputError.desiredJob }}</small>
                                        </p>
                                    </div>
                                </template>
                            </div>
                            <div :class="{'btn-wrap': !isAdmin}">
                                <KSASecondaryButton class="full-btn" text="취소" @click="cancel" />
                                <KSAPrimaryButton v-if="!isAdmin" class="full-btn" text="저장" @click="save" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .sign-wrap {
        padding: 0;
    }

    .sub-title {
        justify-content: space-between;
        .btn {
            margin-bottom: -10px;
        }

    }

    .check-box {
        &:last-child {
            margin-right: 0 !important;
        }
    }

    hr {
        height: 1px;
        border: 0;
        background-color: $gray-light_color;
        margin: 40px 0;
    }

    h5 {
        color: $gray_color;
        @include font-size_lg;
        margin-bottom: -10px;
    }
</style>