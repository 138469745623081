<script setup>
    import { ref, computed, onMounted, getCurrentInstance } from 'vue'
    import { useRouter, RouterLink } from 'vue-router'
    import SNBAdmin from '../../../../../layouts/nav/SNBAdmin.vue'
    import { usePageLoadingStore } from "../../../../../stores/pageLoading";
    import _ from 'lodash'
    import insightsNotifyService from "../../../../user/services/insights/insightsNotifyService";

    const router = useRouter();
    const loadingStore = usePageLoadingStore()
    const { $pageListBoardNo } = getCurrentInstance().appContext.config.globalProperties;

    onMounted(()=>{
        retrieveList()
    })

    const searchOpen = ref(true)
    const list = ref([]);

    const _searchInput = ref({
        searchType: 'name', // name 이름, id 아이디, title 제목
        searchInput: '',
        searchDurationType: 'all',
        startYmd: '',
        endYmd: '',
    })

    const searchTypeRef = ref(null)

    const currentPage = ref(1);
    const pageSize = ref(30);
    const totalPages = ref(0);
    const totalCount = ref(0);

    const searchTypeGroup = ref([
        { label:"작성자", value: "name", placeholder: "작성자명을 입력해주세요."  },
        { label:"아이디", value: "id", placeholder: "아이디를 입력해주세요."  },
        { label:"제목", value: "title", placeholder: "제목을 입력해주세요."  },
    ]);

    const searchInputPlaceholder = computed(()=>{
        const option = _.find(searchTypeGroup.value, { value: _searchInput.value.searchType });
        if(option) {
            return option.placeholder;
        } else {
            return '';
        }
    })

    const searchDurationTypeGroup = ref([
        { label:"모든 기간", value: "all" },
        { label:"기간 검색", value: "term" },
    ])

    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ])

    const retrieveList = () => {
        let paramObj = {
            searchType: _searchInput.value.searchType,
            searchInput: _searchInput.value.searchInput,
            startYmd: _searchInput.value.searchDurationType === 'all' ? "" : _searchInput.value.startYmd,
            endYmd: _searchInput.value.searchDurationType === 'all' ? "" : _searchInput.value.endYmd,
            pageNo: currentPage.value,
            size: pageSize.value,
        }

        loadingStore.loading = true;
        insightsNotifyService.retrieveAllPaging(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const onSelectSearchTypeGroup = (option) => {
        _searchInput.value.searchType = option.value;
    }

    const clearSearchInput = () => {
        _searchInput.value.searchType = 'name';
        _searchInput.value.searchInput = '';
        _searchInput.value.searchDurationType = 'all';

        searchTypeRef.value.initSelectedOption(_searchInput.value.searchType)
    }

    const onClickSearch = () => {
        currentPage.value = 1;
        retrieveList();
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }

    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;
        retrieveList();
    }

</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>공지사항</h3>
                </div>
                <div class="filter-wrap" :class="{'open': searchOpen}">
                    <p @click="searchOpen = !searchOpen">검색</p>
                    <div class="filter">
                        <dl>
                            <dt>
                                <KSASelect ref="searchTypeRef" :options="searchTypeGroup" class="select" @selectOption="onSelectSearchTypeGroup"/>
                            </dt>
                            <dd>
                                <KSAInput class="text-box" v-model="_searchInput.searchInput" :placeholder="searchInputPlaceholder" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                작성일
                            </dt>
                            <dd class="text-box">
                                <div class="grid">
                                    <template v-for="group in searchDurationTypeGroup" key="item.value" >
                                        <KSARadioButton class="mr-md" name="searchDurationType" :label="group.label" v-model="_searchInput.searchDurationType" :value="group.value" />
                                    </template>
                                    <div style="display: inline-block;" v-show="_searchInput.searchDurationType !== 'all'">
                                        <KSADatepickerTerms
                                                :startDate="_searchInput.startYmd"
                                                :endDate="_searchInput.endYmd"
                                                @update:startDate="(value)=> _searchInput.startYmd = value"
                                                @update:endDate="(value)=> _searchInput.endYmd = value"
                                        />
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <div class="btn-wrap">
                            <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                            <KSAPrimaryButton text="검색" @click="onClickSearch" />
                        </div>
                    </div>
                </div>
                <div class="list-top">
                    <div class="grid">
                        <KSASecondaryButton class="gray_button" text="공지사항 작성" @click="router.push({name: 'CSNotifyCreate'})" />
                    </div>
                </div>
                <div class="list-top">
                    <p>총 게시물 <span>{{ $number(totalCount) }}</span>개</p>
                    <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                </div>
                <div class="table-form board-list">
                    <table class="horizontal">
                      <thead>
                        <tr>
                            <th>No</th>
                            <th>제목</th>
                            <th>작성자</th>
                            <th>아이디</th>
                            <th>작성일</th>
                        </tr>
                      </thead>
                        <tr v-for="notice in list">
                            <td>{{notice.boardNo}}</td>
                            <td v-if="notice.notifyYn === 'Y'" class="link text-left">
                                <RouterLink :to="{name: 'CSNotifyDetail', params:{id: notice.boardId}}">
                                    {{notice.title}}📣 <img v-if="notice.attachList.length > 0 && notice.attachList[0].fileName" src="/src/assets/images/icon-file.png" class="icon-file" />
                                </RouterLink></td>
                            <td v-else class="link text-left">
                              <RouterLink :to="{name: 'CSNotifyDetail', params:{id: notice.boardId}}">
                                {{notice.title}} <img v-if="notice.attachList.length > 0 && notice.attachList[0].fileName" src="/src/assets/images/icon-file.png" class="icon-file" />
                              </RouterLink></td>
                            <td>{{notice.createUserName}}</td>
                            <td>{{notice.createUserLoginId}}</td>
                            <td>{{ $yyyymmddOrHHmm(notice.createDate) }}</td>
                        </tr>
                        <tr v-if="list.length === 0">
                            <td colspan="5">검색 결과가 없습니다.</td>
                        </tr>
                    </table>
                </div>
                <Paginate
                    v-if="list.length > 0"
                    v-model="currentPage"
                    :page-count="totalPages"
                    :click-handler="onSelectPage"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

</style>