<template>
    <!-- teleport: 렌더링 시 위치를 지정합니다. -->
    <teleport to="body">
        <!-- Modal의 열리고 닫힘을 관리합니다. -->
        <div class="backdrop" :class="{'admin-wrap': isAdminWrap}" v-if="isVisible">
            <div class="modal-container">
                <!-- slot을 통해 BaseComponent를 확장시킵니다. -->
                <slot></slot>
            </div>
        </div>
    </teleport>
</template>

<script>
import { ref } from "vue";
export default {
    name: "Modal",
    setup() {
        const isVisible = ref(false);
        const isAdminWrap = ref(false);

        // 부모 컴포넌트에서 접근하기 위한 함수를 선언합니다.
        const open = () => {
            isVisible.value = true;
        };

        const close = () => {
            isVisible.value = false;
        };

        // setup 함수에서 리턴해주어야, 부모 컴포넌트에서 접근이 가능합니다.
        return {
            isVisible,
            isAdminWrap,
            open,
            close,
        };
    },
};
</script>

<style lang="scss">
@import "../../../assets/scss/theme";

.backdrop {
    z-index: 999;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.4);

    .modal-container {
        width: fit-content;
        background: #fff;
        text-align: center;
        position:relative;
        .modal-inner {
          h1{
            margin-bottom:10px;
          }
          p{
            line-height:28px;
            color:#1e1e1e;
            margin-bottom:24px;
            em{
              color: #3843AF;
              font-style:normal;;
            }
          }
            .img-container p img {
              max-width: 100%;
            }
            position: relative;
            max-width: 564px;
            min-width: 360px;
            width: 100%;
            padding: 30px 0;

          .status-container{
            border:1px solid #EFEFEF;
            padding : 24px;
            border-radius: 24px;
            box-shadow : 0px 5px 5px rgb(0,0,0,0.05);
            margin-bottom:30px;

            .status{
              background: #F5F6FD;
              padding:11px 15px;
              border-radius: 10px;
              text-align: left;
              margin-bottom:8px;
              h4{ display: inline-block; color: #3843AF; width:84px; font-size:16px;}
              span.status-arrow{
                width:16px;
                height:16px;
                display:inline-block;
                margin-left:6px;
                margin-right:6px;
                position:relative;
                top:2px;
                background-image:url(/src/assets/images/status-arrow.svg);
              }
              p{margin-bottom:0;}
            }
            .warning{
              text-align: left;
              color:$red_color;
              font-size:13px;
            }
            .footnote{
              font-size:13px;
              font-weight:300;
              color:$gray_color;
            }
          }
          .status-container-user-gb{
            border:1px solid #3843AF;
            padding : 20px 0px;
            border-radius: 4px;
            //box-shadow : 0px 5px 5px rgb(0,0,0,0.05);
            margin-bottom:30px;

            .status{
              //background: #F5F6FD;
              padding:16px 30px;
              border-radius: 4px;
              margin-bottom:6px;
              h4{ display: inline-block; color: #3843AF; margin:3px 0px; font-size:17px;}

              p{ margin:3px 0px; font-size: 18px;}
              .left{
                width: 124px;
                display: inline-block;
                text-align: center;
              }
              .center{
                width: 42px;
                display: inline-block;
                text-align: center;
                span.status-arrow{
                  width:34px;
                  height:34px;
                  display:inline-block;
                  background-image:url(/src/assets/images/status-arrow-light.svg);
                }
              }
              .center-red{
                width: 42px;
                display: inline-block;
                text-align: center;
                span.status-arrow{
                  width:34px;
                  height:34px;
                  display:inline-block;
                  background-image:url(/src/assets/images/status-arrow-red.svg);
                }
              }
              .right{
                width: 124px;
                display: inline-block;
                text-align: center;
              }
            }
          }
            .vertical-process-container{
              background:#F8F8FA;
              border-radius: 14px;
              padding:18px;

              .step{
                background:#fff;
                border:1px solid #E4E5F0;
                padding:10px;
                border-radius: 10px;
                position:relative;
                z-index: 1;
                margin-top:22px;
                height: 76px;
                color:#5494CE;
                font-weight:bold;
                font-size:18px;
                line-height:28px;
                display:flex;
                flex-flow: wrap;
                align-content: center;
                justify-content: center;
                div{

                }
                &::before{
                  content:'';
                  background-image:url(/src/assets/images/icon-vertical-arrow.png);
                  width:30px;
                  height:26px;
                  display:block;
                  position:absolute;
                  left:50%;
                  top:0;
                  margin-left:-15px;
                  margin-top:-24px;
                  z-index: 2;
                }
                &.first{
                  margin-top:0;
                }
                &.first::before{
                  background-image:none;
                }
                &.important{
                  color:#3843AF;
                }
                &.last{
                  color:#1e1e1e;
                }
              }
            }

            &.m-size{
              width: 440px !important;
              padding : 0 0 30px 0;
              margin-right:50px;
              margin-left:50px;
            }
            &.xl-size, &.xxl-size {
                text-align: left;
                max-width: inherit !important;
                max-height: calc(100vh - 80px);
                .modal-body {
                    overflow-y: auto;
                    max-height: calc(100vh - 250px);
                }
            }
            &.xl-size {
                width: 1148px !important;
            }
            &.xxl-size {
                width: 1650px !important;
            }
            &.request-modal { // 만족도 평가, TEST 팝업
                padding: 0 !important;
                .modal-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: $gray-light_color;
                    border-bottom: 1px solid $gray-regular_color;
                    margin-bottom: 0 !important;
                    h3 {
                        @include font-size_md;
                        padding: 18px;
                        span {
                            position: relative;
                            font-weight: 400;
                            padding-left: 20px;
                            &:before {
                                content: "";
                                position: absolute;
                                left: 8px;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 1px;
                                height: 20px;
                                background-color: $gray_color;
                            }
                        }
                    }
                    p {
                        span {
                            color: $red_color;
                            font-weight: 700;
                        }
                    }
                }

                .modal-body {
                    padding: 40px 60px 0 !important;
                    h4 {
                        @include font-size_lg;
                        margin: 40px 0 20px;
                        &:first-child {
                            margin-top: 0;
                        }
                    }

                    .guide-wrap {
                        max-width: 545px;
                        width: 100%;
                        margin: auto;
                        .guide-list {
                            border: $border_line;
                            border-radius: 4px;
                            padding: 20px;
                            margin-bottom: 60px;
                            ol {
                                padding: 0 18px;
                                list-style: decimal;
                            }
                        }

                        .table-form {
                            margin-bottom: 20px !important;
                        }
                        button {
                            width: 100%;
                        }
                    }
                }

                .modal-footer {
                    display: block !important;
                    text-align: center;
                    margin: 0;
                    padding: 20px 30px;
                    button {
                        max-width: 545px;
                        width: 100%;
                        margin: auto;
                    }
                }
            }
            .close {
                position: absolute;
                top: 42px;
                right: 30px;
                background-color: transparent;
                border: 0;
                cursor: pointer;
                padding: 0;
                &:after {
                    content: "";
                    display: block;
                    width: 22px;
                    height: 23px;
                    background-image: url(/src/assets/images/icon-main.png);
                    background-size: 200px 200px;
                    background-position: -55px -80px;
                }
            }
            .modal-title {
                text-align: left;
                margin-bottom: 20px;
                padding: 0 30px;
                h3 {
                    @include font-size_xxl;
                    font-weight: 700;
                }
            }
            .modal-body {
                padding: 0 30px;
                .filter-wrap {
                    p {
                        border-bottom: 0;
                        &:before {
                            display: none;
                        }
                    }
                    .filter {
                        border-top: $border_line;
                        dl {
                            &:first-of-type {
                                padding-top: 0;
                            }
                        }
                    }
                }
                h3 {
                    @include font-size_md;
                    font-weight: 700;
                }
                .table-form {
                    margin-bottom: 10px;
                }
            }
            .modal-footer {
                display: flex;
                justify-content: space-between;
                padding: 0 30px;
                margin-top: 40px;
                &.btn-wrap {
                    margin-top: 10px;
                    margin-bottom: 0;
                }
                button {
                  height: 48px;
                  border: none;
                  cursor: pointer;
                  border-radius: 4px;
                  padding: 10px 20px;
                  background: #3843AF;
                  font-size: 16px;
                  line-height: 28px;
                  color: #fff;
                    &.full-btn {
                        width: 100%;
                        white-space: nowrap;
                        margin-left: 8px;
                        height:50px;
                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
      .check-not-today{
        position:absolute;
        right:0;
        bottom:-30px;
        color:#ffffff;
        font-weight:300;
        font-size:16px;
        .check{
          display:inline-block;
          width:20px;
          height:20px;
          position:relative;
          top:4px;
          right:4px;
          background-image:url('/src/assets/images/not-today.svg');
          background-position: left;
        }

      }
    }
}
@media (max-width: 1199px) {
  .backdrop .modal-container .modal-inner.xl-size,
  .backdrop .modal-container .modal-inner.xxl-size {
    width : 94vw !important;
  }

  .modal-container {
    .modal-inner {
      &.xl-size, &.xxl-size {



        .modal-body {
          overflow-y: auto;
          max-height: calc(100vh - 250px);
        }
      }
    }
  }
}
</style>