<script setup>
    import { ref, watch, computed, onMounted, getCurrentInstance } from 'vue'
    import { RouterLink } from 'vue-router'
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import SiteMainBannerModal from '../../components/modal/site/SiteMainBannerModal.vue'
    import mainBannerService from "../../../user/services/banner/mainBannerService";
    import _ from 'lodash'

    const loadingStore = usePageLoadingStore()
    const { $toast } = getCurrentInstance().appContext.config.globalProperties;

    const MAX_LEN = 10;
    const list = ref([]);
    const deleteFailedIds = ref([])

    onMounted(()=>{
        retrieveList()
    })

    let disabledWatchIsSelectedAll = false;
    let disabledWatchList = false;

    const isSelectedAll= ref(false);
    watch(isSelectedAll, (newValue)=>{
        if(!disabledWatchIsSelectedAll) {
            const newList = _.cloneDeep(list.value);
            _.each(newList, (el)=>{
                el.checked = newValue;
            });
            disabledWatchList = true;
            list.value = newList;
        } else {
            disabledWatchIsSelectedAll = false;
        }
    })

    watch(list, (newValue)=>{
        if(!disabledWatchList) {
            const checkedList = _.filter(newValue, { checked: true});
            if(checkedList.length === newValue.length) {
                disabledWatchIsSelectedAll = true;
                isSelectedAll.value = true;
            } else {
                if(isSelectedAll.value) {
                    disabledWatchIsSelectedAll = true;
                    isSelectedAll.value = false;
                }
            }
        } else {
            disabledWatchList = false;
        }
    }, { deep: true})

    const modal = ref(null)
    const confirmModal = ref(null)
    const siteMainBannerModal = ref(null)
    const modalContent = ref([])

    const retrieveList = () => {
        let paramObj = {
            pageNo: 1,
            size:  100,
            searchType: '',
            searchInput: '',
        }

        loadingStore.loading = true;
        mainBannerService.retrieveAllBanner(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    list.value = _.orderBy(result, 'order');
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const addNewBanner = async () => {
        if(list.value.length >= MAX_LEN) {
            modalContent.value = [`메인 배너는 최대 ${MAX_LEN}개까지 설정할 수 있습니다.`];
            modal.value.show();
            return false;
        } else {
            const result = await siteMainBannerModal.value.show();
            if(result) {
                $toast('', '배너를 추가하였습니다.');
                retrieveList();
            }
        }
    }

    const updateBanner = async (banner) => {
        let paramObj = {
            bannerGb: banner.bannerGb,
            bannerId: banner.bannerId,
            fileName: banner.fileName,
            fileSize: banner.fileSize,
            fileDir: banner.fileDir,
            order: banner.order,
        };

        siteMainBannerModal.value.init(paramObj);
        const result = await siteMainBannerModal.value.show();
        if(result) {
            $toast('', '배너를 저장하였습니다.');
            retrieveList();
        }
    }

    const removeBanner = async () => {
        const checkedList = _.filter(list.value, {checked: true});
        if(checkedList.length === 0) {
            modalContent.value = ["삭제할 메인 배너를 선택하세요."];
            modal.value.show();
            return false;
        } else {
            modalContent.value = ["선택한 메인 배너를 삭제할까요?", "삭제 시 사용자 사이트에 즉시 적용되며 되돌릴 수 없습니다."];
            const ok = await confirmModal.value.show();
            if(ok) {
                const bannerIds = _.map(checkedList, 'bannerId');
                _removeBanner(bannerIds);
            }
        }
    }

    const _removeBanner = async (bannerIds) => {
        if(bannerIds) {
            loadingStore.loading = true;
            for (let i=0; i<bannerIds.length; i++) {
                try {
                    await requestDeleteBanner(bannerIds[i])
                    if(i === bannerIds.length-1) {
                        loadingStore.loading = false;
                    }
                } catch (err) {
                    // console.log('failed bannerId: ' + err)
                    deleteFailedIds.value.push(err);
                    if(i === bannerIds.length-1) {
                        loadingStore.loading = false;
                    }
                }
            }
            retrieveList();
            $toast('', '배너를 삭제하였습니다.');
            if(deleteFailedIds.value.length > 0) {
                modalContent.value = ["일부 배너 삭제를 실패하였습니다."];
                modal.value.show();
            }
        }

    }

    function requestDeleteBanner(bannerId){
        return new Promise((resolve, reject) => {
            mainBannerService.deleteBanner(bannerId)
                .then((res) => {
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        resolve(bannerId)
                    } else {
                        reject(bannerId)
                    }
                })
                .catch( (res) =>  {
                    reject(bannerId)
                });
        })

    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>메인 배너 관리</h3>
                </div>
                <div class="btn-wrap space-between mb-xs">
                    <div>
                        <KSASecondaryButton text="배너 추가" @click="addNewBanner" />
                    </div>
                    <KSASecondaryButton class="red_button mr-xs" text="삭제" @click="removeBanner" />
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th><KSACheckBox v-model="isSelectedAll" /></th>
                            <th>No</th>
                            <th>배너</th>
                            <th>노출 순서</th>
                            <th>작성자</th>
                            <th>아이디</th>
                            <th>등록일</th>
                            <th>수정</th>
                        </tr>
                        <tr v-for="(banner, index) in list">
                            <td><KSACheckBox v-model="banner.checked" /></td>
                            <td>{{ list.length - index}}</td>
                            <td><KSAImage class="img" :src="banner.fileDir"/></td>
                            <td>{{ banner.order }}</td>
                            <td>{{ banner.createUserName }}</td>
                            <td>{{ banner.createUserLoginId }}</td>
                            <td>{{ $yyyymmdd(banner.createDate) }}</td>
                            <td>
                                <KSATextButton class="ml-xs" text="배너 수정" @click="updateBanner(banner)" />
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="삭제" />
    <SiteMainBannerModal ref="siteMainBannerModal" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .img {
        width: 400px;
        height: 160px;
    }

</style>