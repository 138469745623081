<script setup>
  import SNB from "../../../../../layouts/nav/SNB.vue";
  import { ref, onMounted, getCurrentInstance } from 'vue'
  import { useRouter } from 'vue-router'
  import {usePageLoadingStore} from "../../../../../stores/pageLoading";
  import mypageProposalService from "../../../services/mypage/mypageProposalService";
  import KSASecondaryButton from "../../../../../components/ui-components/button/KSASecondaryButton.vue";
  import ConfirmModal from '../../../../../components/ui-components/modal/ConfirmModal.vue'
  import KSATextButton from "../../../../../components/ui-components/button/KSATextButton.vue";
  import MyPageResumeModal from "../../../components/modal/mypage/MyPageResumeModal.vue";

  const globals = getCurrentInstance().appContext.config.globalProperties;
  const { $toast, $pageListBoardNo } = globals;
  const interests = ref(globals.$interests)

  const router = useRouter()
  const loadingStore = usePageLoadingStore()

  onMounted(()=>{
    retrieveList()
  })

  const userMatchingResumeModal = ref(null);

  const modal = ref(null);
  const confirmModal = ref(null);
  const modalContent = ref([]);
  const okButtonText = ref("확인");

  const list = ref([])
  const currentPage = ref(1);
  const pageSize = ref(30);
  const totalPages = ref(1);
  const totalCount = ref(0);

  const searchOpen = ref(true)

  const searchInputs = ref({
    interestsList: [],
  })
  const interestsListRef = ref(null)

  const pageSizeGroup = ref([
    { label:"30개씩 보기", value: "30" },
    { label:"50개씩 보기", value: "50" },
    { label:"100개씩 보기", value: "100" },
  ])

  const getParamObj = () => {
    return {
      pageNumber: currentPage.value || 0,
      size: pageSize.value || 30,
      searchInterests: searchInputs.value.interestsList.toString(),
    };
  }

  // 조건에 맞는 데이터 불러오는 함수
  const retrieveList = () => {
    let paramObj = getParamObj();
    loadingStore.loading = true;
    mypageProposalService.retrieveResumeLikeList(paramObj)
        .then( async (res) => {
          loadingStore.loading = false;
          if(res.data.code.toLowerCase() === 'success') {
            const result = res.data.result;
            // console.log(result);
            totalPages.value = result.totalPages;
            totalCount.value = result.totalElements;
            list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
          } else {
          }
        }).catch( (error) =>  {
          modalContent.value = ["데이터를 받아오지 못했습니다.기업코드 등록여부를 확인해주세요."];
          modal.value.show();
          console.log(error);
      loadingStore.loading = false;
    });
  }

  // 페이지당 개수 변경
  const onSelectPageSize = (option) => {
    pageSize.value = option.value;
    currentPage.value = 1;
    retrieveList();
  }

  // 페이지 클릭
  const onSelectPage = (pageNo) => {
    currentPage.value = pageNo;
    retrieveList();
  }

  // 검색 버튼
  const onClickSearch = () => {
    currentPage.value = 1;
    retrieveList();
  }

  // 초기화 버튼
  const onClickClearSearchInput = () => {
    searchInputs.value = {
      interestsList: ['설계','장비','소재','제조공정','패키지','테스트','SW','기타'],
    };
    interestsListRef.value.initOptions()
  }

  // 채용 제안
  const onClickResumeCorpYn = async (resumeId) => {
    modalContent.value = ["지원자에게 채용을 제안할까요?"];
    okButtonText.value = "채용 제안";
    const ok = await confirmModal.value.show();
    if(!ok) {
      return -1;
    } else {
      let paramObj2 = {
        resumeId: resumeId,
      }
      mypageProposalService.createResumeCorporationByResumeId(paramObj2)
          .then( (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
              // const result = res.data.result;
              modalContent.value = ["채용을 제안하였습니다."];
              modal.value.show();
              //$toast('', "채용을 제안하였습니다.")
              retrieveList();
            } else {
              modalContent.value = ["제안을 실패하였습니다."];
              modal.value.show();
              console.log(res);
            }
          }).catch( (res) =>  {
            loadingStore.loading = false;
            modalContent.value = ["제안을 실패하였습니다."];
            modal.value.show();
            console.log(res);
          });
    }
  }

  // 관심 인재
  const onClickResumeLikeYn = async (resumeId) => {
    modalContent.value = ["관심인재를 삭제하시겠습니까?"];
    okButtonText.value = "관심인재 삭제";
    const ok = await confirmModal.value.show();
    if(!ok) {
      return -1;
    } else {
      let paramObj3 = {
        resumeId: resumeId,
        likeState: 1,
      }
      mypageProposalService.changeResumeLikeByResumeId(paramObj3)
          .then( (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
              //const result = res.data.result;
              modalContent.value = ["삭제를 하였습니다."];
              modal.value.show();
              //$toast('', "삭제를 하였습니다.")
              retrieveList();
            } else {
              modalContent.value = ["삭제를 실패하였습니다."];
              modal.value.show();
              console.log(res);
            }
          }).catch( (res) =>  {
            loadingStore.loading = false;
            modalContent.value = ["삭제를 실패하였습니다."];
            modal.value.show();
            console.log(res);
          });
    }
  }

  // 채용 이력서 보기
  const onClickViewResume = async (resumeId, resumeCorpYn) => {
    userMatchingResumeModal.value.init(false, true, resumeCorpYn, 1);
    userMatchingResumeModal.value.retrieveUserMatchingResumeDetail(resumeId, 1);
    const res = await userMatchingResumeModal.value.show();
    if(!res) {
      retrieveList();
    }
  }

</script>
<!-- MailSMSHistoryView.vue 참고-->
<template>
  <div class="container">
    <div class="inner">
      <div class="flex-wrap admin">
        <SNB class="snb" />
        <div class="content">
          <div class="sub-title">
            <h3>관심 인재</h3>
          </div>
          <div class="filter-wrap" :class="{'open': searchOpen}">
            <p @click="searchOpen = !searchOpen">검색</p>
            <div class="filter">
              <dl>
                <dt>관심 카테고리</dt>
                <dd>
                  <KSACheckBoxGroup
                      ref="interestsListRef"
                      className="mr-md"
                      name="interestsList"
                      v-model="searchInputs.interestsList"
                      :options="interests" />
                </dd>
              </dl>
              <div class="btn-wrap">
                <KSASecondaryButton class="gray_button" text="초기화" @click="onClickClearSearchInput" />
                <KSAPrimaryButton text="검색" @click="onClickSearch" />
              </div>
            </div>
          </div>
          <div class="list-top">
            <p>총 <span>{{ $number(totalCount) }}</span>개</p>
            <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
          </div>
          <div class="table-form board-mobile-vertical">
            <table>
              <tr>
                <th>번호</th>
                <th>이름</th>
                <th>관심 카테고리</th>
                <th>변경일</th>
                <th>이력서 보기</th>
                <th>채용 제안</th>
                <th>관심 인재</th>
              </tr>
              <tr v-for="likeList in list">
                <td data-title="번호 : ">{{ likeList.boardNo }}</td>
                <td data-title="이름 : ">{{ likeList.userName }}</td>
                <td data-title="관심 카테고리 : ">{{ likeList.interests }}</td>
                <td data-title="변경일 : ">{{ $yyyymmddOrHHmm(likeList.modifyDate) }}</td>

                <td v-if="likeList.resOpenYn === 'N'" style="color: #b3b3b3;" data-title="이력서 보기 : ">
                  이력서 보기
                </td>
                <td v-else data-title="이력서 보기 : ">
                  <KSATextButton class="clickable resume" text="이력서 보기" @click="onClickViewResume(likeList.resumeId, likeList.resumeCorpYn)"/>
                </td>
                <td v-if="likeList.resumeCorpYn === 1" style="color: #b3b3b3;" data-title="채용 제안 : ">
                  채용 제안
                </td>
                <td v-else-if="likeList.resOpenYn === 'N'" style="color: #b3b3b3;" data-title="채용 제안 : ">
                  매칭 중단
                </td>

<!--
                <td data-title="이력서 보기 : ">
                  <KSATextButton class="clickable resume" text="이력서 보기" @click="onClickViewResume(likeList.resumeId, likeList.resumeCorpYn)"/>
                </td>
                <td v-if="likeList.resumeCorpYn === 1" style="color: #b3b3b3;" data-title="채용 제안 : ">
                  채용 제안
                </td>-->


                <td v-else data-title="채용 제안 : ">
                  <KSATextButton class="clickable resume" text="채용 제안" @click="onClickResumeCorpYn(likeList.resumeId)"/>
                </td>
                <td data-title="관심 인재 : ">
                  <KSATextButton text="♥" @click="onClickResumeLikeYn(likeList.resumeId)" class="red_button"></KSATextButton>
                </td>
              </tr>
              <tr v-if="list.length === 0">
                <td colspan="7">검색 결과가 없습니다.</td>
              </tr>
            </table>
          </div>
          <Paginate
              v-if="list.length > 0"
              v-model="currentPage"
              :page-count="totalPages"
              :click-handler="onSelectPage"
          />
        </div>
      </div>
    </div>
  </div>
  <AlertModal ref="modal" :content="modalContent" />
  <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText"/>
  <MyPageResumeModal ref="userMatchingResumeModal"/>
</template>

<style scoped lang="scss">
  @import "../../../../../assets/scss/theme";

  .tab {
    grid-template-columns: repeat(2, 1fr);
  }

  .clickable {
    cursor: pointer;
    &.resume {
      color: #3C72FF;
      &:hover {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
</style>

