<script setup>
    import { ref, onMounted, computed, getCurrentInstance, watch } from 'vue'
    import { useRouter } from 'vue-router'
    import mypageProposalService from "../../../services/mypage/mypageProposalService";
    import { usePageLoadingStore } from "../../../../../stores/pageLoading";
    import SNB from "../../../../../layouts/nav/SNB.vue";
    import { useMypageProposalStore } from "../../../stores/mypage/mypageProposalStore";
    import _ from 'lodash'
    import KSATextButton from "../../../../../components/ui-components/button/KSATextButton.vue";
    import MyPageResumeModal from "../../../components/modal/mypage/MyPageResumeModal.vue";

    const router = useRouter();
    const loadingStore = usePageLoadingStore()
    const proposalStore = useMypageProposalStore()

    const globals = getCurrentInstance().appContext.config.globalProperties;
    //const { $pageListUserId } = globals;
    const data_interests = ref(globals.$interests)
    const searchMonths = ref(globals.$searchMonths)

    const wizardStep = ref(['1', '0', '0']);
    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ]);

    const interestGbListRef = ref(null)
    const list = ref([]);
    const currentPage = ref(1);
    const pageSize = ref(30);
    const totalPages = ref(1);
    const totalCount = ref(0);
    const searchOpen = ref(true);
    const isSelectedAll = ref(false)
    const resumeModal = ref(null)

    const sendResult = ref(true); // 채용제안서 전송 결과값에 따른 result message 변경.

    let disabledWatchIsSelectedAll = false;
    let disabledWatachList = false;

    watch(isSelectedAll, (newValue)=>{
        if(!disabledWatchIsSelectedAll) {
            const newList = _.cloneDeep(list.value);
            _.each(newList, (el)=>{
                el.checkYn = newValue;
            });
            disabledWatachList = true;
            list.value = newList;
            proposalStore.setProposalUserList(list.value);

            const valuesArray = _.map(newList, 'userId');
            let userIds = input.value.userIds;
            if ( newValue ) {
                input.value.userIds = _.uniq(userIds.concat(valuesArray));
            } else {
                // 전체 체크해제 시 기본 리스트에서 값 없애주기
                input.value.userIds = _.uniq(userIds.filter(n => !valuesArray.includes(n)));
            }
        } else {
            disabledWatchIsSelectedAll = false;
        }
    });

    watch(list, (newValue)=>{
        if(!disabledWatachList) {
            const checkedList = _.filter(newValue, { checkYn: true});
            if(checkedList.length === newValue.length) {
                disabledWatchIsSelectedAll = true;
                isSelectedAll.value = true;
            } else {
                if(isSelectedAll.value) {
                    disabledWatchIsSelectedAll = true;
                    isSelectedAll.value = false;
                }
            }
            const valuesArray = _.map(checkedList, 'userId');
            let userIds = input.value.userIds;
            userIds = _.uniq(userIds.concat(valuesArray));

            const ucheckedList = _.filter(newValue, { checkYn: false});
            const uvaluesArray = _.map(ucheckedList, 'userId');
            input.value.userIds = _.uniq(userIds.filter(n => !uvaluesArray.includes(n)));

        } else {
            disabledWatachList = false;
        }

    }, { deep: true})


    const input = ref({
        title: '',
        interests: '',
        content:
          '<span style="color:blue;">※ 채용 제안을 받으신 지 2주 내에 수락하셔야 구직자/구인자 간 매칭이 이루어집니다.</span><br><br>' +
          '<strong>1. 모집 분야 및 자격요건</strong><br>' +
          '<strong>[회사명]</strong><br>' +
            '<span style="color:hsl(0, 0%, 70%);">예시) 한국반도체산업협회 (https://www.ksia.or.kr/)</span><br>' +
          '<strong>[부서]</strong><br>' +
            '<span style="color:hsl(0, 0%, 70%);">예시) 반도체아카데미운영실</span><br>' +
          '<strong>[직무소개]</strong><br>' +
            '<span style="color:hsl(0, 0%, 70%);">예시) 반도체 교육 개발 및 운영</span><br>' +
          '<strong>[지원자격]</strong><br>' +
            '<span style="color:hsl(0, 0%, 70%);">예시)</span><br>' +
            '<span style="color:hsl(0, 0%, 70%);">-4년제 학사 졸업자 또는 졸업예정자 (전공무관)</span><br>' +
            '<span style="color:hsl(0, 0%, 70%);">-해외여행에 결격사유가 없는 분</span><br>' +
          '<strong>[우대사항]</strong><br>' +
            '<span style="color:hsl(0, 0%, 70%);">예시)</span><br>' +
            '<span style="color:hsl(0, 0%, 70%);">-반도체 공학 관련 전공자</span><br>' +
            '<span style="color:hsl(0, 0%, 70%);">-비즈니스 영어가 가능하신 분</span><br>' +
          '<strong>[전형단계]</strong><br>' +
            '<span style="color:hsl(0, 0%, 70%);">예시)</span><br>' +
            '<span style="color:hsl(0, 0%, 70%);">①지원서 접수</span><br>' +
            '<span style="color:hsl(0, 0%, 70%);">②서류전형</span><br>' +
            '<span style="color:hsl(0, 0%, 70%);">③1차면접</span><br>' +
            '<span style="color:hsl(0, 0%, 70%);">④2차면접</span><br>' +
            '<span style="color:hsl(0, 0%, 70%);">⑤채용검진</span><br>' +
            '<span style="color:hsl(0, 0%, 70%);">⑥최종합격</span><br>' +
          '<br>' +
          '<strong>2. 근무 조건</strong><br>' +
          '<strong>[근무지]</strong><br>' +
            '<span style="color:hsl(0, 0%, 70%);">예시) 반도체회관(경기도 성남시 분당구 판교역로 182)</span><br>' +
          '<strong>[고용형태]</strong><br>' +
            '<span style="color:hsl(0, 0%, 70%);">예시) 정규직</span><br>' +
          '<strong>[급여]</strong><br>' +
            '<span style="color:hsl(0, 0%, 70%);">예시) 상세 근무 조건은 면접 합격자에 한해 개별 안내 및 협의 예정</span><br>' +
          '<strong>[기타]</strong><br>' +
            '<span style="color:hsl(0, 0%, 70%);">예시)</span><br>' +
            '<span style="color:hsl(0, 0%, 70%);">-최종 합격 후, 회사가 지정하는 입사일에 입사 불가할 경우 합격이 취소되거나 전형상의 불이익이 있을 수 있습니다.</span><br>' +
            '<span style="color:hsl(0, 0%, 70%);">-지원서를 포함하여 채용 전형 진행 중 모든 과정에서 제출한 내용이 사실과 다르거나 문서로 증빙이 불가할 경우 합격이 취소되거나 전형상의 불이익이 있을 수 있습니다.</span><br>',
        userIds: []
    });
    const _searchInput = ref({
        searchInterests: '',
        searchMonthType: 1
    });
    const inputError = ref({
        interests: '관심 카테고리를 선택하세요',
    })

    onMounted(() => {
        _.each(data_interests.value, el => {el.on = false;})
    })

    const modal = ref(null)
    const confirmModal = ref(null);
    const modalContent = ref([])
    const okButtonText = ref("확인");

    const isInitView = computed(()=> {
        return currentPage.value === 1;
    });

    const interestCount = computed(()=>{
        return _.filter(data_interests.value, {'on': true}).length || 0;
    });

    const userIdsCount = computed(()=>{
        return input.value.userIds.length || 0;
    });

    const onClickInterest = (interest) => {
        inputError.value.interests = '';
        if(interest.on) {
            interest.on  = false;
        } else {
            if(interestCount.value < 3) {
                interest.on = true;
            } else {
                inputError.value.interests = '최대 3개까지 선택할 수 있습니다.';
            }
        }

    }

    const isDisabled1 = computed(()=> {
        return interestCount.value < 1 || !input.value.content || !input.value.title;
    });
    const isDisabled2 = computed(()=> {
        return userIdsCount.value < 1;
      // return interestCount.value < 1 || !input.value.content;
    });

    const next2 = () => {
        const interests = _.map(_.filter(data_interests.value, {'on': true}), 'value').join(',');
        const { content } = input.value;
        // 인재 검색 시, 채용 제안서에서 선택한 관심 카테고리로 초기 셋팅
        _searchInput.value.searchInterests = interests.split(',')
        interestGbListRef.value.initOptionsWithValues(interests.split(','));
        wizardStep.value = ['0', '1', '0'];

        retrieveUserList();
    };

    const retrieveUserList = () => {
        //console.log(_searchInput);
        const interests = _.map(_searchInput.value.searchInterests).join(',');

        let paramObj = {
            pageNumber: currentPage.value,
            size: pageSize.value,
            searchInterests: interests,
            searchMonths: _searchInput.value.searchMonthType
        }

        // console.log(paramObj);
        loadingStore.loading = true;
        mypageProposalService.retrieveProposalSearchUserList(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                    //list.value = $pageListUserId(result.content, totalCount.value, pageSize.value, currentPage.value);
                    const resultContent = result.content;
                    // 체크로직
                    const userIds = input.value.userIds;
                    for( const index in resultContent ) {
                        if ( userIds.indexOf(resultContent[index].userId) < 0 ) {
                            resultContent[index].checkYn = false;
                        } else {
                            resultContent[index].checkYn = true;
                        }
                    }
                    list.value = resultContent;
                    if(isInitView.value) {
                        proposalStore.setProposalUserList(list.value);
                    }
                    // sleep(3000);
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            console.log(error);
        });
    }

    const clearSearchInput = () => {
      _searchInput.value = {
        searchInterests: [],
        searchMonthType: 1,
      };
      interestGbListRef.value.initOptions();
    }
    const next1 = () => {
        // 관심카테고리 모든 체크 해제 시키기
        interestGbListRef.value.initOptionsWithValues([]);
        wizardStep.value = ['1', '0', '0'];
    };

    const next3 = async () =>  {
        const interests = _.map(_.filter(data_interests.value, {'on': true}), 'value').join(',');
        const { title, content, userIds } = input.value;

        if(isDisabled2.value) {
            return false;
        }

        modalContent.value = ["선택한 "+userIdsCount.value+"명의 인재에게 채용 제안서를 전송할까요?"];
        okButtonText.value = "전송";
        const ok = await confirmModal.value.show();
        if(!ok) {
          return -1;
        }

        let paramObj = {
            title,
            interests,
            content,
            userIds
        };
        console.log(paramObj);

        loadingStore.loading = true;
        mypageProposalService.createProposalUser(paramObj)
            .then( (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    sendResult.value = true;
                } else {
                    sendResult.value = false;
                }
            }).catch( (error) =>  {
            loadingStore.loading = false;
            sendResult.value = false;
            console.log(error);
        });
        wizardStep.value = ['0', '0', '1'];
    };


    const onSelectedPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveUserList();
    }
    // 페이지당 개수 변경
    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;
        retrieveUserList();
    }

    const cancel = () => {
        router.push({ name: 'MypageProposalListView'});
    };

    // 기본 이력서 보기
    const onClickViewBasicResume = (userId) => {
      resumeModal.value.init(false);
      resumeModal.value.retrieveMatchingRecordDetail(userId, 0);
      resumeModal.value.show();
    }

</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="flex-wrap admin">
                <SNB class="snb" />
                <div class="content">
                    <div class="sub-title">
                        <h3>채용 제안</h3>
                        <div class="proposal-wizard" >
                            <ul class="proposal-steps">
                                <li class="step" :class="{'step-active': wizardStep[0] === '1', 'step-success': (wizardStep[1] === '1' || wizardStep[2] === '1')}"> <!-- step-success -->
                                    <div class="step-content">
                                        <span class="step-circle">1</span>
                                        <span class="step-text">채용 제안서 작성</span>
                                    </div>
                                </li>
                                <li class="step" :class="{'step-active': wizardStep[1] === '1'}">
                                    <div class="step-content">
                                        <span class="step-circle">2</span>
                                        <span class="step-text">인재 검색</span>
                                    </div>
                                </li>
                                <li class="step" :class="{'step-active': wizardStep[2] === '1'}">
                                    <div class="step-content">
                                        <span class="step-circle">3</span>
                                        <span class="step-text">전송 완료</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-show="wizardStep[0] === '1'">
                        <div class="filter-wrap" >
                            <br>
                            <span>
                            인재에게 전송할 채용 제안서를 작성하세요. (모든 입력 사항은 필수 입력사항 입니다. )
                        </span>
                        </div>
                        <div class="table-form">
                            <table class="vertical">
                                <colgroup>
                                    <col width="150px" />
                                    <col />
                                </colgroup>
                                <tr>
                                    <th>관심 카테고리<br>(최대 3개)</th>
                                    <td>
                                        <ul class="grid category-wrap">
                                            <li :class="{'active': interest.on}" v-for="interest in data_interests" @click="onClickInterest(interest)">
                                                {{ interest.label }}
                                            </li>
                                            <p>
                                                <small style="color:#E71414;">{{ inputError.interests }}</small>
                                            </p>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                  <th>제목</th>
                                  <td>
                                    <KSAInput class="text-box" v-model="input.title" placeholder="제목을 입력하세요."/>
                                  </td>
                                </tr>
                                <tr>
                                    <th>제안서</th>
                                    <td>
                                        <!-- <KSATextArea class="textarea-box" v-model="input.content" placeholder="내용을 입력하세요."/>-->
                                        <KSACKEditor v-model="input.content" />
<!--                                        <small style="color:#E71414;">제안서는 필수입력 사항입니다.</small>-->
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="btn-wrap space-between">
                            <KSASecondaryButton class="gray_button" text="취소" @click="cancel" />
                            <KSAPrimaryButton text="인재 검색" @click="next2" :disabled="isDisabled1"/>
                        </div>
                    </div>
                    <div v-show="wizardStep[1] === '1'">
                        <div class="filter-wrap" :class="{'open': searchOpen}">
                            <p @click="searchOpen = !searchOpen">검색</p>
                            <div class="filter">
                                <dl>
                                    <dt>관심 카테고리</dt>
                                    <dd>
                                        <KSACheckBoxGroup
                                            ref="interestGbListRef"
                                            className="mr-md"
                                            name="searchInterests"
                                            v-model="_searchInput.searchInterests"
                                            :options="data_interests" />
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>이력서 전송일</dt>
                                    <dd>
                                        <template v-for="group in searchMonths">
                                            <KSARadioButton class="mr-md" name="searchMonthGb" :label="group.label" v-model="_searchInput.searchMonthType" :value="group.value" />
                                        </template>
                                    </dd>
                                </dl>
                                <div class="btn-wrap">
                                    <KSASecondaryButton class="gray_button" text="초기화" @click="clearSearchInput" />
                                    <KSAPrimaryButton text="검색" @click="retrieveUserList" />
                                </div>
                            </div>
                        </div>
                        <div class="list-top">
                            <p>제안 <span>{{ $number(totalCount) }}</span>건</p>
                            <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                        </div>
                        <div class="table-form">
                            <table>
                                <colgroup>
                                    <col width="80px" />
                                    <col width="100px" />
                                    <col width="100px" />
                                    <col width="170px" />
                                    <col width="100px" />
                                    <col width="100px" />
                                </colgroup>
                                <tr>
                                    <th><KSACheckBox v-model="isSelectedAll" /></th>
                                    <th>번호</th>
                                    <th>이름</th>
                                    <th>관심 카테고리</th>
                                    <th>이력서보기</th>
                                    <th>이력서 수정일</th>
                                </tr>
                                <tr v-for="(proposalUser, index) in (isInitView ? proposalStore.proposalUserList : list)">
                                    <td><KSACheckBox v-model="proposalUser.checkYn" /></td>
                                    <td>{{ proposalUser.userId }}</td>
                                    <td>{{ proposalUser.userName }}</td>
                                    <td>{{ proposalUser.interests }}</td>
                                    <td>
                                      <KSATextButton text="이력서 보기" @click="onClickViewBasicResume(proposalUser.userId)"/>
                                    </td>
                                    <td>{{ $yyyymmddOrHHmm(proposalUser.modifyDate)}}</td>
                                </tr>
                            </table>
                            <div class="result-none" v-if="list.length === 0">
                                전송할 인재가 없습니다.
                            </div>
                        </div>
                        <Paginate
                            v-if="list.length > 0"
                            v-model="currentPage"
                            :page-count="totalPages"
                            :click-handler="onSelectedPage"
                        />
                        <div class="btn-wrap space-between">
                            <KSASecondaryButton class="gray_button" text="이전" @click="next1" />
                            <KSAPrimaryButton text="채용 제안서 전송" @click="next3" :disabled="isDisabled2"/>
                        </div>
                    </div>
                    <div v-show="wizardStep[2] === '1'">
                        <div style="text-align: center; margin-top: 50px;">

                            <span v-if="sendResult">총 {{userIdsCount}}명에게 채용 제안서를 전송하였습니다.</span>
                            <span v-else class="red">채용 제안서 전송 과정에서 문제가 발생했습니다.<br>잠시 후 다시 시도해 주세요.</span>
                            <br><br>

                            <KSAPrimaryButton text="채용 제안 현황 보기" @click="router.push({name: 'MypageProposalListView'})" />

                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText"/>
    <MyPageResumeModal ref="resumeModal"/>
</template>

<style lang="scss" scoped>
    .proposal-wizard {
        float: left;
        position: absolute;
        right: 0;
    }

    .proposal-steps {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        overflow-x: auto;
        li {
            display: list-item;
            text-align: -webkit-match-parent;
        }
        .step {
            margin-left: auto;
            .step-content {
                box-sizing: content-box;
                display: flex;
                align-items: center;
                flex-direction: row;
                padding-right: 1rem;
                line-height: 32px;
                height: 32px;
                .step-circle {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 1.5rem;
                    height: 1.5rem;
                    color: #adb5bd;
                    border: 2px solid #adb5bd;
                    border-radius: 100%;
                    background-color: #fff;
                }
                .step-text {
                    display: none;
                }

            }
            &:first-child {
              margin-left: auto;
            }
            &.step-success {
                color: #28a745;
                background-color: #fff;
                border-color: #28a745;
                .step-circle {
                    color: #28a745;
                    background-color: #fff;
                    border-color: #28a745;
                }
                .step-text {
                  display: none;
                  color: #28a745;
                }
            }
            &.step-active {
                .step-circle {
                    color: #fff;
                    background-color: #007bff;
                    border-color: #007bff;
                }
                .step-text {
                  display: inline;
                  padding-left: 5px;
                  color: #007bff;
                  position: relative;
                  bottom: 1px;
                }
            }
        }

    }
    .title {
        hr {
            clear: both;
        }
    }

</style>
