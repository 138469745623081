<script setup >
    import { ref, computed, defineEmits, defineExpose, onMounted, toRefs } from "vue";
    import _ from 'lodash'

    const props = defineProps({
        className: { type: String},
        modelValue: { type: String },
        options: { type: Array },
        name: { type: String },
        disabled: { type: Boolean, default: false, }
    });


    onMounted(()=>{
        checkedValues.value = _.map(options.value, 'value');
        isAllChked.value = true;
        emit('update:modelValue', checkedValues.value);
    })

    const { className, modelValue, options, name, disabled, allUnchecked} = toRefs(props)

    const isAllChked = ref(true);
    const checkedValues = ref(_.map(options.value, 'value'));

    const showFlag = ref(true);

    const emit = defineEmits(["update:modelValue"]);
    const model = computed({
        get() {
            return props.modelValue;
        },
        set(value) {
            emit("update:modelValue", value);
        },
    });

    const onChangeAllChked = () => {
        if(isAllChked.value) {
            checkedValues.value = _.map(options.value, 'value');
        } else {
            checkedValues.value = [];
        }
        emit('update:modelValue', checkedValues.value);
    }

    const onClick = (e) => {
        // console.log(e);
        isAllChked.value = checkedValues.value.length === options.value.length;
        emit('update:modelValue', checkedValues.value);
    }

    const initOptions = (modelUpdateYn) => {
        showFlag.value = false;
        checkedValues.value = _.map(options.value, 'value');
        isAllChked.value = true;
        if(modelUpdateYn === 'Y') {
            emit('update:modelValue', checkedValues.value);
        }
        showFlag.value = true;
    }

    const initOptionsWithValues = (values) => {
        showFlag.value = false;
        // console.log(values);
        // console.log(isAllChked.value);
        setTimeout(()=>{
            checkedValues.value = _.map(_.filter(options.value, function (o){ return values.indexOf(o.value)> -1 }), 'value');
            isAllChked.value = checkedValues.value.length === options.value.length;
            emit('update:modelValue', checkedValues.value);
            showFlag.value = true;
        }, 500)
    }

    defineExpose({
        initOptions,
        initOptionsWithValues,
    })
</script>

<template>
    <KSACheckBox
        :class="className"
        v-model="isAllChked"
        :name="name"
        label="전체"
        value="all"
        :disabled="disabled"
        v-show="showFlag"
        @change="onChangeAllChked"/>
    <KSACheckBox
        :class="className"
        v-for="option in options"
        v-model="checkedValues"
        :name="name"
        :label="option.label"
        :value="option.value"
        :disabled="disabled"
        v-show="showFlag"
        @change="onClick"/>
</template>

<style lang="scss" scoped>
</style>