<script setup>
    import { ref, onMounted, computed, getCurrentInstance, watch } from 'vue'
    import { useRouter } from 'vue-router'
    import {useUserStore} from "../../../stores/user";
    import { usePageLoadingStore } from "../../../../../stores/pageLoading";
    import SNB from "../../../../../layouts/nav/SNB.vue";
    import _ from 'lodash'
    import mypageUserService from "../../../services/mypage/mypageUserService";

    const router = useRouter();
    const userStore = useUserStore()
    const loadingStore = usePageLoadingStore()

    const globals = getCurrentInstance().appContext.config.globalProperties;
    const { $gb, $toast, $regex, $userGbKR } = globals;
    const data_interests = ref(globals.$interests)
    const data_desiredJob = ref(globals.$desiredJob)

    const modal = ref(null)
    const modalContent = ref([])
    const confirmModal = ref(null)

    // 고졸일 경우, 대학 비활성화
    const isSchoolSelectDisabled = ref(false);

    onMounted(() => {
      const { userLoginId } = userStore.user;
      if(userLoginId) {
        input.value.userLoginId = userLoginId;
      }
      _.each(data_interests.value, el => {el.on = false;})
      _.each(data_desiredJob.value, el => {el.on = false;})
    })

    const user = ref({});
    const afterUserGb = ref('');
    const eduHistGbRef = ref(null);
    const schoolRef = ref(null)
    const companyNumRef = ref(null)
    const professorNameRef = ref(null)
    const labNameRef = ref(null)
    const isAuthenticated = ref(false);


    const input = ref({
      userLoginId: '',
      password: '',
      school:'', // 학교
      eduHistGb: '', // 학력
      corpId:0, // 기업아이디
      corpName: '', // 기업명
      companyNum: '', // 사업자등록번호
      interests:'', // 관심카테고리
      desiredJob: '', // 학생 희망직무 재직자 본인직무
      professorName: '', // 교수님이름
      labName: '', // 연구실이름
      ableClass: '', // 교육가능과목
      expertField: '' // 전문분야
    })

    const inputError = ref({
      userLoginId: '',
      password: '',
      school:'', // 학교
      eduHistGb: '', // 학력
      corpId:0, // 기업아이디
      corpName: '', // 기업명
      companyNum: '', // 사업자등록번호
      interests:'', // 관심카테고리
      desiredJob: '', // 학생 희망직무 재직자 본인직무
      professorName: '', // 교수님이름
      labName: '', // 연구실이름
      ableClass: '', // 교육가능과목
      expertField: '' // 전문분야
    })

    // step 1,2,3
    const wizardStep = ref(['1', '0', '0']);

    // s: step1 회원 유형 선택
    // 로그인 처리.
    const auth = () => {
      const { password } = input.value;
      let isInvalid = false;

      inputError.value.password = ''
      if(!password) {
        inputError.value.password = '비밀번호를 입력해주세요.';
        isInvalid = true;
      }

      if(!isInvalid) {
        loadingStore.loading = true;
        mypageUserService.checkPassword(password)
            .then( async (res) => {
              loadingStore.loading = false;
              if(res.data.code.toLowerCase() === 'success') {
                const result = res.data.result;
                // console.log(result);
                isAuthenticated.value = true;
                retrieveUserInfo();
              } else {
                modalContent.value = ["비밀번호가 올바르지 않습니다."];
                modal.value.show();
              }
            }).catch( (res) =>  {
          loadingStore.loading = false;
          // console.log(res);
          modalContent.value = [res.response.data.result.message];
          modal.value.show();
        });
      }
    }

    // 회원 유형 선택
    const onClickMemberType = (userGbValue) => {
      // 현재 사용자 유형과 같은 유형을 선택하지 않았을 경우에만, afterUserGb에 value 값 할당.
      if(user.value.userGb !== userGbValue) {
        afterUserGb.value = userGbValue;
      } else {
        // 현재 사용자 유형과 같은 유형을 선택할 경우, 아무 액션 없음.
      }
    }
    // e: step1 회원 유형 선택

    // s: step2 추가 필요 정보 작성
    // 사용자 정보 조회
    const retrieveUserInfo = () => {
      loadingStore.loading = true;
      mypageUserService.retrieveUserInfo()
          .then( async (res) => {
            loadingStore.loading = false;
            if(res.data.code.toLowerCase() === 'success') {
              const result = res.data.result;
              // console.log(result);

              const { eduHistGb, corporation } = result;

              if(result.interests !== null && result.interests !== '') {
                _.each(data_interests.value, el => {
                  el.on = result.interests.indexOf(el.label) > -1;
                })
              }

              if(result.desiredJob !== null && result.desiredJob !== '') {
                _.each(data_desiredJob.value, el => {
                  el.on = result.desiredJob.indexOf(el.label) > -1;
                })
              }

              if(result.ableClass !== null && result.ableClass !== '') {
                _.each(data_interests.value, el => {
                  el.on = result.ableClass.indexOf(el.label) > -1;
                })
              }

              if(result.expertField !== null && result.expertField !== '') {
                _.each(data_desiredJob.value, el => {
                  el.on = result.expertField.indexOf(el.label) > -1;
                })
              }

              if(eduHistGb) {
                setTimeout(()=>{
                  eduHistGbRef.value.initSelectedOption(eduHistGb);
                },1000)
              }

              if(corporation !== null ) {
                const { bizNum, corpNum } = corporation;
                if(bizNum) {
                  result.corporation.bizNum = bizNum.substring(0,3) + '-' + bizNum.substring(3,5) + '-' + bizNum.substring(5);
                }
                if(corpNum) {
                  result.corporation.corpNum1 = corpNum.substring(0,6);
                  result.corporation.corpNum2 = corpNum.substring(6);
                }
              }
              user.value = result;
              // input 데이터에 할당
              input.value.school = user.value.school;
              input.value.eduHistGb = user.value.eduHistGb;
              input.value.companyNum = user.value.corporation.bizNum;
              input.value.corpName = user.value.corporation.corpName;
              input.value.professorName = user.value.professorName;
              input.value.labName = user.value.labName;
            }
          }).catch( (error) =>  {
        loadingStore.loading = false;
        console.log(error);
      });
    }

    // 사업자 번호
    const onBlurCompNum = () => {
      const { companyNum } = input.value;
      if(!$regex.compNum.test(companyNum)) {
        inputError.value.companyNum = '올바른 사업자등록번호 형식이 아닙니다. 다시 입력해 주세요.';
      } else {
        inputError.value.companyNum = '';
      }
    }

    const onUpdateCorp = (corp) => {
      input.value.corpId = corp.corpId;
      input.value.corpName = corp.corpName;

      inputError.value.corpName = '';
      if(corp.corpId === 0) {
        inputError.value.corpName = '사업자등록번호가 올바르지 않습니다. 다시 입력하세요.';
      }
    }

    // 관심분야는 최대 3개까지 선택 가능
    const interestCount = computed(()=>{
        return _.filter(data_interests.value, {'on': true}).length || 0;
    });

    const onClickInterest = (interest) => {
        inputError.value.interests = '';
        if(interest.on) {
            interest.on  = false;
        } else {
            if(interestCount.value < 3) {
                interest.on = true;
            } else {
                inputError.value.interests = '최대 3개까지 선택할 수 있습니다.';
            }
        }
    }

    // 희망직무 / 본인직무 최대 3개까지 선택 가능
    const desiredJobCount = computed(()=>{
      return _.filter(data_desiredJob.value, {'on': true}).length || 0;
    })

    const onClickDesiredJob = (desiredJob) => {
      inputError.value.desiredJob = '';
      if(desiredJob.on) {
        desiredJob.on  = false;
      } else {
        if(desiredJobCount.value < 3) {
          desiredJob.on = true;
        } else {
          inputError.value.desiredJob = '최대 3개까지 선택할 수 있습니다.';
        }
      }
    }

    // 고졸(10)일 경우, 학교(대학) 필수표시(*) 제거.
    const schoolInputClass = computed(() => {
      return input.value.eduHistGb === '10' ? 'text-box' : 'text-box mark';
    });

    // 고졸(10)일 경우, 학교(대학) 비활성화.
    watch( () => input.value.eduHistGb, (newValue) => {
      inputError.value.eduHistGb = '';
      if( newValue === '10') {
        isSchoolSelectDisabled.value = true;
        input.value.school = '고졸';
      } else {
        isSchoolSelectDisabled.value = false;
        //input.value.school = '';
      }
    });

    // e: step2 추가 필요 정보 작성


    // step1 에서 "취소" 버튼
    const cancel = () => {
      router.push({name: 'MyPageUserGbHistory'});
    };

    // step1 에서 "다음" 버튼
    const next2 = () => {
      wizardStep.value = ['0', '1', '0'];
    };

    // step2에서 "이전" 버튼
    const next1 = () => {
      wizardStep.value = ['1', '0', '0'];
    };

    // step2에서 "다음" 버튼. 회원 유형 변경 신청 처리
    const next3 = async () =>  {
      const req = input.value;
      let isInvalid = false;
      let focused = false;

      if(afterUserGb.value === 'STUDENT'){
        if(!req.eduHistGb) {
          inputError.value.eduHistGb = '학력을 선택하세요.';
          isInvalid = true;
        }
        if(req.eduHistGb !== '10' && !req.school) {
          inputError.value.school = '학교를 입력하세요..';
          isInvalid = true;
          if(!focused) {
            schoolRef.value.focus();
            focused = true;
          }
        }
        if(interestCount.value < 1) {
          inputError.value.interests = '하나 이상 선택하세요.';
          isInvalid = true;
        }
        if(desiredJobCount.value < 1) {
          inputError.value.desiredJob = '하나 이상 선택하세요.';
          isInvalid = true;
        }
      } else if(afterUserGb.value === 'SCHOOL') {
        if(!req.eduHistGb) {
          inputError.value.eduHistGb = '학력을 선택하세요.';
          isInvalid = true;
        }
        if(req.eduHistGb !== '10' && !req.school) {
          inputError.value.school = '학교를 입력하세요..';
          isInvalid = true;
          if(!focused) {
            schoolRef.value.focus();
            focused = true;
          }
        }
        if(!req.professorName) {
          inputError.value.professorName = '교수님 이름을 입력하세요..';
          isInvalid = true;
          if(!focused) {
            professorNameRef.value.focus();
            focused = true;
          }
        }if(!req.labName) {
          inputError.value.labName = '연구실 이름을 입력하세요..';
          isInvalid = true;
          if(!focused) {
            labNameRef.value.focus();
            focused = true;
          }
        }
      }
      else if(afterUserGb.value === 'COMPANY') {
        if(!req.companyNum || req.companyNum.length !== 12) {
          inputError.value.companyNum = !req.companyNum ? '사업자등록번호를 입력하세요.' : '10자리 숫자를 입력하세요.';
          isInvalid = true;
          if(!focused) {
            companyNumRef.value.focus();
            focused = true;
          }
        } else if(req.corpId === 0) {
          inputError.value.corpName = '사업자등록번호가 올바르지 않습니다. 다시 입력하세요.';
          isInvalid = true;
        }
        if(interestCount.value < 1) {
          inputError.value.interests = '하나 이상 선택하세요.';
          isInvalid = true;
        }
        if(desiredJobCount.value < 1) {
          inputError.value.desiredJob = '하나 이상 선택하세요.';
          isInvalid = true;
        }
      }
      else if(afterUserGb.value === 'TEACHER') {
        if(interestCount.value < 1) {
          inputError.value.interests = '하나 이상 선택하세요.';
          isInvalid = true;
        }
        if(desiredJobCount.value < 1) {
          inputError.value.desiredJob = '하나 이상 선택하세요.';
          isInvalid = true;
        }
      }
      const interests = _.map(_.filter(data_interests.value, {'on': true}), 'value').join(',');
      const desiredJob = _.map(_.filter(data_desiredJob.value, {'on': true}), 'value').join(',');

      if(afterUserGb.value === 'STUDENT' || afterUserGb.value === 'COMPANY'){
        req.interests = interests;
        req.desiredJob = desiredJob;
      } else if(afterUserGb.value === 'TEACHER') {
        req.ableClass = interests;
        req.expertField = desiredJob;
      }

      // console.log(req);
      if(isInvalid) {
        return;
      } else {
        let paramObj = {
          beforeUserGb: user.value.userGb,
          afterUserGb: afterUserGb.value,
          school: req.school,
          eduHistGb: req.eduHistGb,
          corpId: afterUserGb.value === 'COMPANY' ? req.corpId : undefined,
          interests: req.interests,
          desiredJob: req.desiredJob,
          professorName: req.professorName,
          labName: req.labName,
          ableClass: req.ableClass,
          expertField: req.expertField
        };
        //console.log(paramObj);

        loadingStore.loading = true;
        mypageUserService.createUserGbHistory(paramObj)
            .then( (res) => {
              loadingStore.loading = false;
            }).catch( (error) =>  {
          loadingStore.loading = false;
          console.log(error);
        });
      }
      wizardStep.value = ['0', '0', '1'];
    };

</script>

<template>
    <div class="container">
        <div class="inner">
            <div class="flex-wrap">
                <SNB class="snb" />
                <div class="content" v-if="!isAuthenticated">
                  <div class="sub-title">
                    <h3>회원 유형 변경</h3>
                  </div>
                  <div class="sign-wrap">
                    <div class="sign-inner">
                      <p class="text text-center">회원님의 정보를 안전하게 보호하기 위해 비밀번호를 입력해주세요</p>
                      <div class="sign-content">
                        <KSAInput class="text-box" v-model="input.userLoginId" label="아이디" placeholder="아이디 입력하세요." disabled />
                        <KSAInput type="password" class="text-box" v-model="input.password" label="비밀번호" placeholder="비밀번호 입력하세요." :msg="inputError.password" max-length="30"/>
                        <KSAPrimaryButton class="full-btn" text="확인" @click="auth" />
                      </div>
                      <ul class="member">
                        <li><RouterLink :to="{name:'MyPageProfilePWReset'}">비밀번호 변경</RouterLink></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="content" v-if="isAuthenticated">
                    <div class="sub-title">
                        <h3>회원 유형 변경</h3>
                        <div class="proposal-wizard" >
                            <ul class="proposal-steps">
                                <li class="step" :class="{'step-active': wizardStep[0] === '1', 'step-success': (wizardStep[1] === '1' || wizardStep[2] === '1')}"> <!-- step-success -->
                                    <div class="step-content">
                                        <span class="step-circle">1</span>
                                        <span class="step-text">회원 유형 선택</span>
                                    </div>
                                </li>
                                <li class="step" :class="{'step-active': wizardStep[1] === '1'}">
                                    <div class="step-content">
                                        <span class="step-circle">2</span>
                                        <span class="step-text">회원정보 확인</span>
                                    </div>
                                </li>
                                <li class="step" :class="{'step-active': wizardStep[2] === '1'}">
                                    <div class="step-content">
                                        <span class="step-circle">3</span>
                                        <span class="step-text">변경 신청 완료</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="user-gb-wrap">
                      <div class="sign-inner">
                        <div v-show="wizardStep[0] === '1'">
                          <div class="txt">
                            <h3>변경할 회원 유형을 선택해주세요.</h3><br>
                            재직자/기업 회원 유형으로 변경 후 구인활동 및<br>
                            장비 지원이 필요할 경우고객센터로 문의 바랍니다
                          </div>
                          <div class="member-type">
                            <div class="type" :class="{'on': afterUserGb === 'STUDENT', 'on-usergb': user.userGb === 'STUDENT'}" @click="onClickMemberType('STUDENT')">
                              <div class="img">
                                <img src="../../../../../assets/images/icon-student.png" />
                              </div>
                              <b>{{ $userGbKR('STUDENT')}}<span v-if="user.userGb === 'STUDENT'" class="user-gb">현재 회원유형</span></b>
                              <p>강의 수강 및 구직활동을<br> 하고 싶습니다.</p>
                            </div>
                            <div class="type" :class="{'on': afterUserGb === 'COMPANY', 'on-usergb': user.userGb === 'COMPANY'}" @click="onClickMemberType('COMPANY')">
                              <div class="img">
                                <img src="../../../../../assets/images/icon-company.png" />
                              </div>
                              <b>{{ $userGbKR('COMPANY')}}<span v-if="user.userGb === 'COMPANY'" class="user-gb">현재 회원유형</span></b>
                              <p>현재 재직중이며<br>강의를 수강하고 싶습니다.</p>
                            </div>
                            <div class="type" :class="{'on': afterUserGb === 'SCHOOL', 'on-usergb': user.userGb === 'SCHOOL'}" @click="onClickMemberType('SCHOOL')">
                              <div class="img">
                                <img src="../../../../../assets/images/icon-university.png" />
                              </div>
                              <b>{{ $userGbKR('SCHOOL')}}<span v-if="user.userGb === 'SCHOOL'" class="user-gb">현재 회원유형</span></b>
                              <p>장비를 지원받으려고 하는<br>대학/연구실의 담당자입니다.</p>
                            </div>
                            <div class="type" :class="{'on': afterUserGb === 'TEACHER', 'on-usergb': user.userGb === 'TEACHER'}" @click="onClickMemberType('TEACHER')">
                              <div class="img">
                                <img src="../../../../../assets/images/icon-teacher.png" />
                              </div>
                              <b>{{ $userGbKR('TEACHER')}}<span v-if="user.userGb === 'TEACHER'" class="user-gb">현재 회원유형</span></b>
                              <p>강좌를 개설하여<br>강의를 하고 싶습니다.</p>
                            </div>
                          </div>
                          <div class="btn-wrap">
                            <KSASecondaryButton class="full-btn" text="취소" @click="cancel" />
                            <KSAPrimaryButton class="full-btn" text="다음" @click="next2"  :disabled="afterUserGb === ''"/>
                          </div>
                        </div>
                        <div v-show="wizardStep[1] === '1'">
                          <div class="txt">
                            <h3>변경할 회원 유형의 추가 정보를 확인해 주세요.</h3><br>
                            <span class="red">*표시는 필수 입력사항</span> 입니다.
                          </div>
                          <div class="sign-content">
                            <!-- 학생/취업준비생 -->
                            <template v-if="afterUserGb === 'STUDENT'">
                              <div class="text-box">
                                <p class="label mark">학력</p>
                                <KSASelect ref="eduHistGbRef" class="select" v-model="input.eduHistGb" :options="$gb.EDU_HIST_GB"/>
                                <div>
                                  <small style="color:#E71414;">{{ inputError.eduHistGb }}</small>
                                </div>
                              </div>
                              <KSAInputSchoolSelect
                                  ref="schoolRef"
                                  label="학교"
                                  :class="schoolInputClass"
                                  v-model="input.school"
                                  placeholder="학교를 입력하세요."
                                  :msg="inputError.school"
                                  msg-color="#E71414"
                                  :disabled="isSchoolSelectDisabled"
                              />
                              <div class="text-box">
                                <p class="label mark" style="margin: 0">희망 직무 (최대 3개)</p>
                                <ul class="grid category-wrap">
                                  <li :class="{'active': desiredJob.on}" v-for="desiredJob in data_desiredJob" @click="onClickDesiredJob(desiredJob)">
                                    {{ desiredJob.label }}
                                  </li>
                                </ul>
                                <p>
                                  <small style="color:#E71414;">{{ inputError.desiredJob }}</small>
                                </p>
                              </div>
                              <div class="text-box">
                                <p class="label mark" style="margin: 0">관심 분야 (최대 3개)</p>
                                <ul class="grid category-wrap">
                                  <li :class="{'active': interest.on}" v-for="interest in data_interests" @click="onClickInterest(interest)">
                                    {{ interest.label }}
                                  </li>
                                </ul>
                                <p>
                                  <small style="color:#E71414;">{{ inputError.interests }}</small>
                                </p>
                              </div>
                            </template>

                            <!-- 재직자/기업 -->
                            <template v-else-if="afterUserGb === 'COMPANY'">
                              <KSAInputCompNum
                                  ref="companyNumRef"
                                  class="text-box mark"
                                  v-model="input.companyNum"
                                  @update:corp="onUpdateCorp"
                                  @blur="onBlurCompNum"
                                  :msg="inputError.companyNum"
                                  placeholder="10자리 숫자 입력"
                              />
                              <KSAInput label="기업명" class="text-box mark" v-model="input.corpName" :msg="inputError.corpName" :disabled="true" />
                              <div class="text-box">
                                <p class="label mark" style="margin: 0">본인 직무 (최대 3개)</p>
                                <ul class="grid category-wrap">
                                  <li :class="{'active': desiredJob.on}" v-for="desiredJob in data_desiredJob" @click="onClickDesiredJob(desiredJob)">
                                    {{ desiredJob.label }}
                                  </li>
                                </ul>
                                <p>
                                  <small style="color:#E71414;">{{ inputError.desiredJob }}</small>
                                </p>
                              </div>
                              <div class="text-box">
                                <p class="label mark" style="margin: 0">관심 분야 (최대 3개)</p>
                                <ul class="grid category-wrap">
                                  <li :class="{'active': interest.on}" v-for="interest in data_interests" @click="onClickInterest(interest)">
                                    {{ interest.label }}
                                  </li>
                                </ul>
                                <p>
                                  <small style="color:#E71414;">{{ inputError.interests }}</small>
                                </p>
                              </div>

<!--                              <hr />-->

                            </template>

                            <!-- 대학/연구실 -->
                            <template v-if="afterUserGb === 'SCHOOL'">
                              <div class="text-box">
                                <p class="label mark">학력</p>
                                <KSASelect ref="eduHistGbRef" class="select" v-model="input.eduHistGb" :options="$gb.EDU_HIST_GB"/>
                                <div>
                                  <small style="color:#E71414;">{{ inputError.eduHistGb }}</small>
                                </div>
                              </div>
                              <KSAInputSchoolSelect
                                  ref="schoolRef"
                                  label="학교"
                                  :class="schoolInputClass"
                                  v-model="input.school"
                                  placeholder="학교를 입력하세요."
                                  :msg="inputError.school"
                                  msg-color="#E71414"
                                  :disabled="isSchoolSelectDisabled"
                              />
                              <KSAInput ref="professorNameRef" label="교수님 이름" class="text-box mark" v-model="input.professorName" placeholder="교수님 이름을 입력하세요." max-length="30" :msg="inputError.professorName"/>
                              <KSAInput ref="labNameRef" label="연구실 이름" class="text-box mark" v-model="input.labName" placeholder="연구실 이름을 입력하세요." max-length="30" :msg="inputError.labName"/>
                            </template>

                            <!-- 강사/전문가 -->
                            <template v-if="afterUserGb === 'TEACHER'">
                              <div class="text-box">
                                <p class="label mark" style="margin: 0">교육 가능 과목 (최대 3개)</p>
                                <ul class="grid category-wrap">
                                  <li :class="{'active': interest.on}" v-for="interest in data_interests" @click="onClickInterest(interest)">
                                    {{ interest.label }}
                                  </li>
                                </ul>
                                <p>
                                  <small style="color:#E71414;">{{ inputError.interests }}</small>
                                </p>
                              </div>
                              <div class="text-box">
                                <p class="label mark" style="margin: 0">전문 분야 (최대 3개)</p>
                                <ul class="grid category-wrap">
                                  <li :class="{'active': desiredJob.on}" v-for="desiredJob in data_desiredJob" @click="onClickDesiredJob(desiredJob)">
                                    {{ desiredJob.label }}
                                  </li>
                                </ul>
                                <p>
                                  <small style="color:#E71414;">{{ inputError.desiredJob }}</small>
                                </p>
                              </div>
                            </template>
                          </div>
                          <div class="btn-wrap">
                            <KSASecondaryButton class="full-btn" text="이전" @click="next1" />
                            <KSAPrimaryButton class="full-btn" text="다음" @click="next3"/>
                          </div>
                        </div>
                        <div v-show="wizardStep[2] === '1'">
                            <div style="text-align: center; margin-top: 50px;">
                              <h2>회원 유형 <span style="color: #3344D9;">변경 신청</span>이 완료 되었습니다.</h2><br>
                              <div class="status-container-user-gb">
                                <div class="status">
                                  <div class="left">
                                    <h4 style="color: #4F4F56">현재 유형</h4><br>
                                    <p style="color: #4F4F56">{{ $userGbKR(user.userGb) }}</p>
                                  </div>
                                  <div class="center">
                                    <span class="status-arrow"></span>
                                  </div>
                                  <div class="right">
                                    <h4>변경 유형</h4><br>
                                    <p style="font-weight: bold; color: #3344D9;">{{ $userGbKR(afterUserGb) }}</p>
                                  </div>
                                </div>
                              </div>

                              회원 유형 변경 신청 결과는 영업일 기준 1~2일이 소요되며<br>
                              재 로그인시 팝업으로 안내해 드립니다.<br>
                              <br>
                              신청이 승인될 경우 변경된 회원 유형으로<br>
                              서비스 이용이 가능합니다.<br>
                              <br>
                              회원 유형 변경 신청에 대한 궁금사항은<br>
                              한국반도체아카데미로 문의 바랍니다.
                              <br>
                              <KSAPrimaryButton class="full-btn" text="확인" @click="router.push({name: 'MyPageUserGbHistory'})" />
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <ConfirmModal ref="confirmModal" :content="modalContent" okButtonText="나가기" />
</template>

<style lang="scss" scoped>
  @import "../../../../../assets/scss/theme";
    .proposal-wizard {
        float: left;
        position: absolute;
        right: 0;
    }
    .proposal-steps {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        overflow-x: auto;
        li {
            display: list-item;
            text-align: -webkit-match-parent;
        }
        .step {
            margin-left: auto;
            .step-content {
                box-sizing: content-box;
                display: flex;
                align-items: center;
                flex-direction: row;
                padding-right: 1rem;
                line-height: 32px;
                height: 32px;
                .step-circle {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 1.5rem;
                    height: 1.5rem;
                    color: #adb5bd;
                    border: 2px solid #adb5bd;
                    border-radius: 100%;
                    background-color: #fff;
                }
                .step-text {
                    display: none;
                }

            }
            &:first-child {
              margin-left: auto;
            }
            &.step-success {
                color: #28a745;
                background-color: #fff;
                border-color: #28a745;
                .step-circle {
                    color: #28a745;
                    background-color: #fff;
                    border-color: #28a745;
                }
                .step-text {
                  display: none;
                  color: #28a745;
                }
            }
            &.step-active {
                .step-circle {
                    color: #fff;
                    background-color: #007bff;
                    border-color: #007bff;
                }
                .step-text {
                  display: inline;
                  padding-left: 5px;
                  color: #007bff;
                  position: relative;
                  bottom: 1px;
                }
            }
        }

    }
    .title {
        hr {
            clear: both;
        }
    }
    .member-type {
      overflow: auto;
      .type {
        display: inline-block;
        width: calc(50% - 10px);
        text-align: center;
        background: #fff;
        border-radius: 4px;
        border: $border_line;
        cursor: pointer;
        padding: 20px;
        margin: 0 0 20px 20px;
        &:first-child, &:nth-child(3) {
          margin-left: 0;
        }
        &:nth-child(3), &:nth-child(4) {
          margin-bottom: 0;
        }
        &.on {
          background: $blue-light_color;
          border: 1px solid $blue_color;
        }
        &.on-usergb {
          background: #F8F8FA;
          border: 1px solid #F8F8FA;
        }
        .img {
          width: 100px;
          height: 100px;
          background: $gray-thin_color;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto auto 20px;
        }
        b {
          display: block;
          margin-bottom: 6px;
        }
      }
    }
    .user-gb {
      //position: relative;
      border-radius: 2px;
      border: 1px solid #2A82FF;
      color: #2A82FF;
      display: inline-block;
      padding: 0px 2px 0px 2px;
      margin-left: 4px;
      font-size: 14px;
    }
    .status-container-user-gb{
      border:1px solid #3843AF;
      padding : 20px 0px;
      border-radius: 4px;
      //box-shadow : 0px 5px 5px rgb(0,0,0,0.05);
      margin-bottom:30px;

      .status{
        //background: #F5F6FD;
        padding:16px 30px;
        border-radius: 4px;
        margin-bottom:6px;
        h4{ display: inline-block; color: #3843AF; margin:3px 0px; font-size:17px;}

        p{ margin:3px 0px; font-size: 18px;}
        .left{
          width: 124px;
          display: inline-block;
          text-align: center;
        }
        .center{
          width: 42px;
          display: inline-block;
          text-align: center;
          span.status-arrow{
            width:34px;
            height:34px;
            display:inline-block;
            background-image:url(/src/assets/images/status-arrow-light.svg);
          }
        }
        .center-red{
          width: 42px;
          display: inline-block;
          text-align: center;
          span.status-arrow{
            width:34px;
            height:34px;
            display:inline-block;
            background-image:url(/src/assets/images/status-arrow-red.svg);
          }
        }
        .right{
          width: 124px;
          display: inline-block;
          text-align: center;
        }
      }
    }
</style>