<script setup>
    import { ref, computed, onMounted, getCurrentInstance } from 'vue'
    import { useRouter, RouterLink } from 'vue-router'
    import {usePageLoadingStore} from "../../../../stores/pageLoading";
    import SNBAdmin from '../../../../layouts/nav/SNBAdmin.vue'
    import MailSMSHistoryCreateContactsModal from "../../components/modal/mail/MailSMSHistoryCreateContactsModal.vue";
    import mailMsgServcie from "../../services/mail/mailMsgServcie";
    import _ from "lodash";

    const router = useRouter()
    const loadingStore = usePageLoadingStore()
    const { $gb, $toast } = getCurrentInstance().appContext.config.globalProperties

    onMounted(()=>{
        retrieveList()
    })

    const modal = ref(null)
    const mailContactsModal = ref(null)
    const modalContent = ref([])

    const list = ref([])
    const currentPage = ref(1);
    const pageSize = ref(30);
    const totalPages = ref(1);
    const totalCount = ref(0);

    const searchOpen = ref(true)

    const tabOptions = ref([
        { label: '메일', value: '01' },
        { label: '메시지', value: '02' },
    ])

    const searchInputs = ref({
        msgGb: '01',
        tempateTypeMailList: [],
        tempateTypeSMSList: [],
        sendStatus: ['S','F'],
        searchType: 'name',
        searchInput: '',
        searchDurationType: 'all',
        startYmd: '',
        endYmd: '',
    })

    const tempateTypeMailListRef = ref(null)
    const sendStatusRef = ref(null)
    const searchTypeRef = ref(null)

    const sendStatusGroup = [
        { label: '성공', value: 'S' },
        { label: '실패', value: 'F' },
    ]

    const searchTypeGroup = ref([
        { label:"이름", value: "name", placeholder: "이름을 입력해주세요."  },
        { label:"회원코드", value: "code", placeholder: "회원코드를 입력해주세요." },
        { label:'핸드폰번호', value: 'phone', placeholder: '"-"를 제외한 핸드폰번호 뒷4자리를 입력해 주세요.' },
        { label:"아이디", value: "id", placeholder: "아이디를 입력해주세요."  },
        // { label:"이메일", value: "email", placeholder: "이메일을 입력해주세요."  },
    ]);

    const searchInputPlaceholder = computed(()=>{
        const option = _.find(searchTypeGroup.value, { value: searchInputs.value.searchType });
        if(option) {
            return option.placeholder;
        } else {
            return '';
        }
    })

    const searchDurationTypeGroup = ref([
        { label:"모든 기간", value: "all" },
        { label:"기간 검색", value: "term" },
    ])

    const pageSizeGroup = ref([
        { label:"30개씩 보기", value: "30" },
        { label:"50개씩 보기", value: "50" },
        { label:"100개씩 보기", value: "100" },
        { label:"500개씩 보기", value: "500" },
    ])

    const getParamObj = () => {
        return {
            pageNo: currentPage.value || 0,
            size: pageSize.value || 30,
            msgGb: searchInputs.value.msgGb,
            tempateTypeList: searchInputs.value.msgGb === '01' ? searchInputs.value.tempateTypeMailList : searchInputs.value.tempateTypeSMSList,
            sendStatus: searchInputs.value.sendStatus.length === sendStatusGroup.length ? '' : searchInputs.value.sendStatus.join(''),
            searchType: searchInputs.value.searchType,
            searchInput: searchInputs.value.searchInput,
            startYmd: searchInputs.value.searchDurationType === 'all' ? '' : searchInputs.value.startYmd,
            endYmd: searchInputs.value.searchDurationType === 'all' ? '' : searchInputs.value.endYmd,
        };
    }

    const retrieveList = () => {
        let paramObj = getParamObj();
        loadingStore.loading = true;
        mailMsgServcie.retrieveAllMsgHistoryList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    list.value = result.content;
                    totalPages.value = result.totalPages;
                    totalCount.value = result.totalElements;
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }


    const onButtonClick = () => {
        modal.value.show();
    }

    const onChangeTab = (option) => {
        searchInputs.value.msgGb = option.value;
        currentPage.value = 1;
        retrieveList();
    }

    const onSelectSearchType = (option) => {
        searchInputs.value.searchType = option.value;
    }

    const onClickClearSearchInput = () => {
        searchInputs.value = {
            msgGb: '01',
            tempateTypeMailList: [],
            sendStatus: ['S','F'],
            searchType: 'code',
            searchInput: '',
            searchDurationType: 'all',
            startYmd: '',
            endYmd: '',
        };

        tempateTypeMailListRef.value.initOptions()
        sendStatusRef.value.initOptions()
        searchTypeRef.value.initSelectedOption(searchInputs.value.searchType)
    }

    const onClickSearch = () => {
        currentPage.value = 1;
        retrieveList();
    }

    const onSelectPageSize = (option) => {
        pageSize.value = option.value;
        currentPage.value = 1;
        retrieveList();
    }

    const onSelectPage = (pageNo) => {
        currentPage.value = pageNo;
        retrieveList();
    }

    const onClickDownload = () => {
        let paramObj = getParamObj();
        loadingStore.loading = true;
        mailMsgServcie.downloadMsgHistoryList(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                // console.log(res);
                try {
                    const href = URL.createObjectURL(res.data);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;

                    const fileName =  $dateFormamtter(new Date(), 'YYYYMMDD') + '_전송내역_메일.xlsx';
                    link.setAttribute('download', fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                } catch(e) {
                    console.log(e);
                    modalContent.value = ["다운로드를 실패하였습니다."];
                    modal.value.show();
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }

    const onClickAllReSend = () => {
        const targetList = _.filter(list.value, {'sendStatus': 'Failed'});
        if(targetList.length > 0) {
            // resend
            let paramObj = getParamObj();
            loadingStore.loading = true;
            mailMsgServcie.requestAllMsgReSend(paramObj)
                .then( async (res) => {
                    loadingStore.loading = false;
                    // console.log(res);
                    if(res.data.code.toLowerCase() === 'success') {
                        const result = res.data.result;
                        // console.log(result);
                        modalContent.value = [`${searchInputs.value.msgGb === '01' ? '메일을' : '메시지를'} 재전송하였습니다.`];
                        modal.value.show();
                    } else {
                    }
                }).catch( (error) =>  {
                // console.log(error);
                loadingStore.loading = false;
            });
        } else {
            modalContent.value = [`재전송할 ${searchInputs.value.msgGb === '01' ? '메일이' : '메시지가'} 없습니다.`];
            modal.value.show();
        }
    }

    const onClickMailWrite = () => {
        const { msgGb } = searchInputs.value;
        router.push({name: msgGb === '01' ? 'MailSMSHistoryMailCreate' : 'MailSMSHistorySMSCreate'})
    }

    const onClickMsgHistReSend = (msgHist) => {
        let paramObj = {
            date: msgHist.date,
            userId: msgHist.userId,
        }

        loadingStore.loading = true;
        mailMsgServcie.requestMsgHistoryIdForReSend(paramObj)
            .then( async (res) => {
                loadingStore.loading = false;
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    // console.log(result);
                    $toast('', '메일 전송 배치에 등록되었습니다.');
                    retrieveList();
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
            loadingStore.loading = false;
        });
    }
</script>

<template>
    <div class="container">
        <div class="flex-wrap admin">
            <SNBAdmin class="snb" />
            <div class="content">
                <div class="sub-title">
                    <h3>전송 내역</h3>
                </div>
                <KSATab class="tab mb-lg" :options="tabOptions" @change="onChangeTab"/>
                <div class="filter-wrap" :class="{'open': searchOpen}">
                    <p @click="searchOpen=!searchOpen">검색</p>
                    <div class="filter">
                        <dl>
                            <dt>타입</dt>
                            <dd>
                                <KSACheckBoxGroup
                                    ref="tempateTypeMailListRef"
                                    v-if="searchInputs.msgGb === '01'"
                                    className="mr-md"
                                    name="tempateTypeList1"
                                    v-model="searchInputs.tempateTypeMailList"
                                    :options="$gb.TEMPATE_TYPE_EMAIL" />
                                <KSACheckBoxGroup
                                    ref="tempateTypeMailListRef"
                                    v-if="searchInputs.msgGb === '02'"
                                    className="mr-md"
                                    name="tempateTypeList2"
                                    v-model="searchInputs.tempateTypeSMSList"
                                    :options="$gb.TEMPATE_TYPE_SMS" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>전송 결과</dt>
                            <dd>
                                <KSACheckBoxGroup
                                    ref="sendStatusRef"
                                    className="mr-md"
                                    name="sendStatus"
                                    v-model="searchInputs.sendStatus"
                                    :options="sendStatusGroup" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <KSASelect ref="searchTypeRef" :options="searchTypeGroup" class="select" @selectOption="onSelectSearchType"/>
                            </dt>
                            <dd>
                                <KSAInput class="text-box" v-model="searchInputs.searchInput" :placeholder="searchInputPlaceholder" />
                            </dd>
                        </dl>
                        <dl>
                            <dt>전송일</dt>
                            <dd class="text-box">
                                <div class="grid">
                                    <template v-for="group in searchDurationTypeGroup">
                                        <KSARadioButton class="mr-md" name="date" :label="group.label" v-model="searchInputs.searchDurationType" :value="group.value" />
                                    </template>
                                    <div style="display: inline-block;" v-show="searchInputs.searchDurationType !== 'all'">
                                        <KSADatepickerTerms
                                                :startDate="searchInputs.startYmd"
                                                :endDate="searchInputs.endYmd"
                                                @update:startDate="(value)=> searchInputs.startYmd = value"
                                                @update:endDate="(value)=> searchInputs.endYmd = value"
                                        />
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <div class="btn-wrap">
                            <KSASecondaryButton class="gray_button" text="초기화" @click="onClickClearSearchInput" />
                            <KSAPrimaryButton text="검색" @click="onClickSearch" />
                        </div>
                    </div>
                </div>
                <div class="list-top">
                    <div class="grid">
                        <KSASecondaryButton class="gray_button" :text="searchInputs.msgGb === '01' ? '메일 쓰기' : '메시지 쓰기'" @click="onClickMailWrite" />
                        <KSASecondaryButton class="gray_button ml-xs" text="전체 재전송" @click="onClickAllReSend" />
                        <KSASecondaryButton class="gray_button ml-xs" text="엑셀 다운로드" @click="onClickDownload" />
                    </div>
                </div>
                <div class="list-top">
                    <p>총 게시물 <span>{{ $number(totalCount) }}</span>개</p>
                    <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
                </div>
                <div class="table-form">
                    <table class="horizontal">
                        <tr>
                            <th>타입</th>
                            <th>회원코드</th>
                            <th>이름</th>
                            <th>아이디</th>
                            <th>전송일시</th>
                            <th>전송 결과</th>
                            <th>재전송</th>
                        </tr>
                        <tr v-for="msgHist in list">
                            <td>
                                <span v-if="searchInputs.msgGb === '01'">{{ $gbNameKR('TEMPATE_TYPE_EMAIL', msgHist.tempateType)}}</span>
                                <span v-else>{{ $gbNameKR('TEMPATE_TYPE_SMS', msgHist.tempateType)}}</span>
                            </td>
                            <td>{{ msgHist.userCd }}</td>
                            <td>{{ msgHist.userName }}</td>
                            <td>{{ msgHist.userLoginId }}</td>
                            <td>{{ $yyyymmddHHmm(msgHist.date) }} </td>
                            <td>
                                <span v-if="msgHist.sendStatus === 'Running'">전송중</span>
                                <span v-else-if="msgHist.sendStatus === 'Failed'">실패</span>
                                <span v-else-if="msgHist.sendStatus === 'Canceled'">취소</span>
                                <span v-else-if="['Succeeded', 'Delivered', 'Expanded', 'view', 'click', 'View', 'Click'].indexOf(msgHist.sendStatus) >-1">성공</span>
                                <span v-else>전송대기</span> <!-- NotStarted or empty -->
                            </td>
                            <td><KSATextButton class="underline" text="재전송" @click="onClickMsgHistReSend(msgHist)" :disabled="msgHist.sendStatus !== 'Failed' || msgHist.reSendYn === 'Y'" /></td>
                        </tr>
                        <tr v-if="list.length === 0">
                            <td colspan="7">검색 결과가 없습니다.</td>
                        </tr>
                    </table>
                </div>
                <Paginate
                    v-if="list.length > 0"
                    v-model="currentPage"
                    :page-count="totalPages"
                    :click-handler="onSelectPage"
                />
            </div>
        </div>
    </div>
    <AlertModal ref="modal" :content="modalContent" />
    <MailSMSHistoryCreateContactsModal ref="mailContactsModal" />
</template>

<style lang="scss" scoped>
    @import "../../../../assets/scss/theme";

    .tab {
        grid-template-columns: repeat(2, 1fr);
    }
</style>