<script setup>
  import SNB from "../../../../../layouts/nav/SNB.vue";
  import { ref, onMounted, getCurrentInstance, inject } from 'vue'
  import { useRouter } from 'vue-router'
  import {usePageLoadingStore} from "../../../../../stores/pageLoading";
  import mypageUserResumeService from "../../../services/mypage/mypageUserResumeService";
  import mypageProposalService from "../../../services/mypage/mypageProposalService";
  import MypageProposalContentModal from "../../../views/mypage/proposal/MypageProposalContentModal.vue";
  import KSASecondaryButton from "../../../../../components/ui-components/button/KSASecondaryButton.vue";
  import KSATextButton  from '../../../../../components/ui-components/button/KSATextButton.vue'
  import _ from 'lodash'
  import mypageMatchingService from "../../../services/mypage/mypageMatchingService";
  import {useMatchingUpdateStore} from "../../../stores/matching/matchingUpdateStore";

  const globals = getCurrentInstance().appContext.config.globalProperties;
  const { $gbNameKR, $gb, $pageListBoardNo, $interests, $userState } = globals;
  const interests = ref($interests)
  const userState = ref($userState)
  const getSystemCodeValues = inject('systemCodeValues');

  const router = useRouter()
  const loadingStore = usePageLoadingStore()
  const matchingUpdateStore = useMatchingUpdateStore()

  onMounted(()=>{
    retrieveList()
  })

  const snbKey = ref(0);

  const contentModal = ref(null)

  const modal = ref(null);
  const confirmModal = ref(null);
  const modalContent = ref([]);
  const okButtonText = ref("확인");

  const propStateGbListRef = ref(null)
  const list = ref([])
  const currentPage = ref(1);
  const pageSize = ref(10);
  const totalPages = ref(1);
  const totalCount = ref(0);
  const searchOpen = ref(true)

  const searchInputs = ref({
    interestsLists: [],
    searchPropStateGbs: [],
  })
  const interestsListRef = ref(null)

  const pageSizeGroup = ref([
    { label:"10개씩 보기", value: "10" },
    { label:"30개씩 보기", value: "30" },
    { label:"50개씩 보기", value: "50" },
    { label:"100개씩 보기", value: "100" },
  ])

  const getParamObj = () => {
    return {
      pageNumber: currentPage.value || 0,
      size: pageSize.value || 10,
      searchInterests: searchInputs.value.interestsList.toString(),
      searchPropStateGbs: _.map(searchInputs.value.searchPropStateGbs).join(','),
    };
  }

  // 조건에 맞는 데이터 불러오는 함수
  const retrieveList = async () => {
    let paramObj = getParamObj();
    loadingStore.loading = true;
    await getSystemCodeValues();
    mypageUserResumeService.retrieveUserProposalUserList(paramObj)
        .then( async (res) => {
          loadingStore.loading = false;
          if(res.data.code.toLowerCase() === 'success') {
            const result = res.data.result;
            // console.log(result);
            totalPages.value = result.totalPages;
            totalCount.value = result.totalElements;
            list.value = $pageListBoardNo(result.content, totalCount.value, pageSize.value, currentPage.value);
            // 매칭 상태값 업데이트
            await retrieveMatchingUpdate();
          } else {
          }
        }).catch( (error) =>  {
        console.log(error);
        loadingStore.loading = false;
    });
  }

  // 페이지당 개수 변경
  const onSelectPageSize = (option) => {
    pageSize.value = option.value;
    currentPage.value = 1;
    retrieveList();
  }

  // 페이지 클릭
  const onSelectPage = (pageNo) => {
    currentPage.value = pageNo;
    retrieveList();
  }

  // 검색 버튼
  const onClickSearch = () => {
    currentPage.value = 1;
    retrieveList();
  }

  // 초기화 버튼
  const onClickClearSearchInput = () => {
    searchInputs.value = {
      interestsList: ['설계','장비','소재','제조공정','패키지','테스트','SW','기타'],
      searchPropStateGbs: ['01', '03', '04', '05', '06', '07', '08'],
    };
    interestsListRef.value.initOptions()
  }

  // 매칭 상태값 업데이트 (matchingUpdateStore 업데이트)
  const retrieveMatchingUpdate = async () => {
    await mypageMatchingService.retrieveMatchingUpdate()
        .then( async (res) => {
          loadingStore.loading = false;
          if(res.data.code.toLowerCase() === 'success') {
            const result = res.data.result;
            matchingUpdateStore.matchingUpdate = result;
            await matchingUpdateStore.setMatchingUpdate( result );
            matchingUpdateStore.value = await useMatchingUpdateStore();
            snbKey.value += 1;
          }
        }).catch( (error) => {
          loadingStore.loading = false;
          console.log(error);
        });
  }

  // 제안 수락, 제안 거절
  const onClickChangeState = async (propId, userId, stateGb) => {
    if( stateGb === '03' ) {
      modalContent.value = ["채용 제안을 수락하시겠습니까?", "'매칭완료'로 상태가 변경됩니다."];
      okButtonText.value = "제안 수락";
    } else if ( stateGb === '04' ) {
      modalContent.value = ["채용 제안을 거절하시겠습니까?", "'매칭중단'으로 상태가 변경됩니다."];
      okButtonText.value = "제안 거절";
    }
    const ok = await confirmModal.value.show();
    if(!ok) {
      return -1;
    } else {
      let paramObj2 = {
        propId: propId,
        userId: userId,
        propStateGb: stateGb,
      }
      // console.log(paramObj2)
      loadingStore.loading = true;
      mypageProposalService.changeProposalUserState(paramObj2)
          .then( (res) => {
              loadingStore.loading = false;
              if(res.data.code.toLowerCase() === 'success') {
                // const result = res.data.result;
                modalContent.value = ["변경하였습니다."];
                modal.value.show();
                retrieveList();
              } else {
                console.log(res);
              }
            }).catch( (res) =>  {
              loadingStore.loading = false;
              console.log(res);
            });
    }
  }

  // 채용 제안서 보기
  const onClickViewProposal = async (propId) => {
    contentModal.value.retrieveProposalContent(propId);
    await contentModal.value.show();
  }

</script>
<template>
  <div class="container">
    <div class="inner">
      <div class="flex-wrap admin">
        <SNB :key="snbKey" class="snb" />
        <div class="content">
          <div class="sub-title">
            <h3>받은 채용 제안</h3>
          </div>
          <div class="filter-wrap" :class="{'open': searchOpen}">
            <p @click="searchOpen = !searchOpen">검색</p>
            <div class="filter">
              <dl>
                <dt>관심 카테고리</dt>
                <dd>
                  <KSACheckBoxGroup
                      ref="interestsListRef"
                      className="mr-md"
                      name="interestsList"
                      v-model="searchInputs.interestsList"
                      :options="interests" />
                </dd>
              </dl>
              <dl>
                <dt>상태</dt>
                <dd>
                  <KSACheckBoxGroup
                      ref="propStateGbListRef"
                      className="mr-md"
                      name="searchPropStateGbs"
                      v-model="searchInputs.searchPropStateGbs"
                      :options="userState" />
                </dd>
              </dl>
              <div class="btn-wrap">
                <KSASecondaryButton class="gray_button" text="초기화" @click="onClickClearSearchInput" />
                <KSAPrimaryButton text="검색" @click="onClickSearch" />
              </div>
            </div>
          </div>
          <div class="list-top">
            <p>총 <span>{{ $number(totalCount) }}</span>개</p>
            <KSASelect :options="pageSizeGroup" class="select" @selectOption="onSelectPageSize"/>
          </div>
          <div class="table-form board-mobile-vertical">
            <table>
              <tr>
                <th>번호</th>
                <th>기업명</th>
                <th>관심 카테고리</th>
                <th>담당자</th>
                <th>채용 제안서</th>
                <th>제안서 전송일</th>
                <th>상태</th>
                <th>제안 수락/거절</th>
              </tr>
              <tr v-for="proposalList in list">
                <td>{{ proposalList.boardNo }}</td>
                <td>{{ proposalList.corpName }}</td>
                <td>{{ proposalList.interests }}</td>
                <td>{{ proposalList.userName }}</td>
                <td>
                  <KSATextButton class="clickable proposal" text="채용 제안서 보기" @click="onClickViewProposal(proposalList.propId)"/>
                </td>
                <td>{{ $yyyymmddOrHHmm(proposalList.createDate) }}</td>
                <td>
                  <span>
                    <span>
                      <span v-if="proposalList.propStateGb === '01'" class="red-dot"></span>
                      {{ $gbNameKR('MATCHING_JOB_STATE_USER_GB', proposalList.propStateGb) }}
                    </span>
                    <br>
                    <small style="color: #86889A">{{ $gbNameKR('MATCHING_JOB_STATE_USER_GB2', proposalList.propStateGb) }}</small>
                  </span>
                </td>

                <!-- 01 매칭중 02 제안취소 03 매칭완료 04 매칭중단 05 전형진행중 06 전형중단 07 합격 08 불합격 -->
                <td v-if="proposalList.propStateGb === '01'" data-title="제안 수락/거절 : ">
                  <KSASecondaryButton text="제안수락" @click="onClickChangeState(proposalList.propId, proposalList.userId, '03')" class="blue_button md_btn"/>
                  <KSASecondaryButton text="제안거절" @click="onClickChangeState(proposalList.propId, proposalList.userId, '04')" class="red_button md_btn"/>
                </td>
                <td v-else-if="proposalList.propStateGb === '03'" data-title="제안 수락/거절 : ">
                  <KSASecondaryButton text="제안거절" @click="onClickChangeState(proposalList.propId, proposalList.userId, '04')" class="red_button md_btn"/>
                </td>
                <td v-else-if="proposalList.propStateGb === '04'" data-title="제안 수락/거절 : ">
                  <KSASecondaryButton text="제안수락" @click="onClickChangeState(proposalList.propId, proposalList.userId, '03')" class="blue_button md_btn"/>
                </td>
              </tr>
            </table>
            <div class="result-none" v-if="list.length === 0">
              검색 결과가 없습니다.
            </div>
          </div>
          <Paginate
              v-if="list.length > 0"
              v-model="currentPage"
              :page-count="totalPages"
              :click-handler="onSelectPage"
          />
        </div>
      </div>
    </div>
  </div>
  <AlertModal ref="modal" :content="modalContent" />
  <ConfirmModal ref="confirmModal" :content="modalContent" :okButtonText="okButtonText"/>
  <MypageProposalContentModal ref="contentModal"/>
</template>

<style scoped lang="scss">
  @import "../../../../../assets/scss/theme";

  .tab {
    grid-template-columns: repeat(2, 1fr);
  }

  .clickable {
    text-decoration: underline;
    cursor: pointer;
    &.proposal {
      color: #3C72FF;
      &:hover {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
</style>

